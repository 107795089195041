import { useEffect, useRef, useState } from 'react';

import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { useCheckReportSignature } from '@/entities/reports';

import {
  ActiveControlType,
  ViewOptionsInterface,
} from '../config/reports.type';
import * as toothModel from '../../tooth/model';
import {
  assetsModel,
  croppedImageAnnotationConvertor,
  formatAnnotations,
} from '../../assets';
import * as reportsModel from '../model';

// TODO: [4|h] refactor component, rewrite from local state to redux
export const useMedicalImageControls = (
  src: string,
  assetID: string,
  viewOptions?: ViewOptionsInterface,
  imageAnnotations?: Annotation[],
  toothID?: string,
) => {
  const { checkReportSignature } = useCheckReportSignature();

  const dispatch = useAppDispatch();

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID ?? ''),
  );
  const isReportSigned = useAppSelector(
    reportsModel.selectors.selectCurrentReportSigned,
  );

  const [activeControl, setActiveControl] = useState<ActiveControlType>('');
  const [mode, setMode] = useState<UltimateMedicalImageMode>('view');
  const [annotations, setAnnotations] = useState<Annotation[]>(
    () => imageAnnotations || [],
  );
  const [isInverted, setIsInverted] = useState(
    () => viewOptions?.invert || false,
  );
  const [sharpness, setSharpness] = useState(() =>
    viewOptions?.sharpness ? viewOptions?.sharpness : 0,
  );
  const [viewAnnotations, setViewAnnotations] = useState(true);
  const [currentAnnotation, setCurrentAnnotation] =
    useState<AnnotationOrEraser | null>(null);
  const [wwwc, setWWWC] = useState<{ ww: number; wc: number }>(
    () => viewOptions?.wwwc || { ww: 0, wc: 0 },
  );
  const [sourceWWWC, setSourceWWWC] = useState<{ ww: number; wc: number }>({
    ww: 0,
    wc: 0,
  });
  const [expandedImage, setExpandedImage] = useState('');
  const [isSplit, setIsSplit] = useState(false);

  const initialViewOptions = useRef<ViewOptionsInterface>();

  const handleSetAnnotations = async (changedAnnotations: Annotation[]) => {
    setAnnotations(changedAnnotations);

    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        // NOTE: In the checkReportSignature function, tooth disapproval only occurs when the report is signed.
        // However, in handleSetAnnotations, the tooth gets disapproved regardless of report signature status.
        // This, combined with the closure of the tooth?.IsApproved check in the case of a signed report,
        // leads to the tooth being disapproved twice.
        // Therefore, we need to add a !isReportSigned check to prevent this double disapproval.
        if (tooth?.IsApproved && !isReportSigned) {
          const { Tooth } = await dispatch(
            reportsModel.thunks.setReportToothDisapproved({
              ToothID: tooth.ID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }
        }

        const { Asset } = await dispatch(
          assetsModel.thunks.setTopLayerAnnotations({
            AssetID: assetID,
            Annotations: formatAnnotations(changedAnnotations),
          }),
        ).unwrap();

        if (Asset) {
          dispatch(assetsModel.actions.setNewestOne(Asset));
        }
      },
      onCancel: () => {
        setAnnotations(annotations);
      },
    });
  };

  const handleSetCroppedImageAnnotations = async (
    localizationID: string,
    changedAnnotations: Annotation[],
  ) => {
    setAnnotations(changedAnnotations);

    const croppedAnnotations =
      croppedImageAnnotationConvertor(changedAnnotations);

    const { Tooth } = await dispatch(
      reportsModel.thunks.setToothLocalizationCropTopLayerAnnotations({
        LocalizationID: localizationID,
        Annotations: croppedAnnotations,
      }),
    ).unwrap();

    if (Tooth) {
      dispatch(toothModel.actions.setNewestOne(Tooth));
    }
  };

  const handleSetMedicalImageViewOptions = async (
    viewOption: Partial<MedicalImageViewOptions>,
  ) => {
    const { Asset } = await dispatch(
      assetsModel.thunks.setMedicalImageViewOptions({
        AssetID: assetID,
        MedicalImageViewOptions: viewOption as MedicalImageViewOptions, // TODO: disappear after migration on connect lib
      }),
    ).unwrap();

    if (Asset) {
      dispatch(assetsModel.actions.setNewestOne(Asset));
    }
  };

  const handleSetReportMedicalImageViewOptions = async (
    viewOption: Partial<MedicalImageViewOptions>,
    reportID: string,
  ) => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: reportID,
        MedicalImageViewOptions: viewOption as MedicalImageViewOptions,
      }),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  const handleSetCroppedMedicalImageViewOptions = async (
    localizationID: string,
    viewOption: MedicalImageViewOptions,
  ) => {
    const { Tooth } = await dispatch(
      reportsModel.thunks.setToothLocalizationCropMedicalImageViewOptions({
        LocalizationID: localizationID,
        MedicalImageViewOptions: viewOption,
      }),
    ).unwrap();

    if (Tooth) {
      dispatch(toothModel.actions.setNewestOne(Tooth));
    }
  };

  const handleReset = async () => {
    setActiveControl('');
    setMode('view');
    setIsInverted(initialViewOptions?.current?.invert || false);
    setSharpness(initialViewOptions?.current?.sharpness || 0);
    setWWWC(sourceWWWC);
    setCurrentAnnotation(null);
    handleSetMedicalImageViewOptions({});
    handleSetAnnotations([]);
  };

  const toggleAnnotationsVisibility = () => {
    setViewAnnotations(!viewAnnotations);
  };

  const handleSetSharpness = (newSharpness: number) => {
    setSharpness(newSharpness);
  };

  const handleSetExpandedImage = (src: string) => {
    setExpandedImage(src);
  };

  const handleSetWWWC = (newWWWC: { ww: number; wc: number }) => {
    setWWWC(newWWWC);
  };

  const handleSetActiveControl = (newControl: ActiveControlType) => {
    setActiveControl(newControl);
  };

  const handleSetMode = (newMode: UltimateMedicalImageMode) => {
    setMode(newMode);
  };

  const toggleIsInverted = () => {
    setIsInverted((prevState) => !prevState);
  };

  const toggleIsSplit = () => {
    setIsSplit((prevState) => !prevState);
  };

  useEffect(() => {
    if (viewOptions?.wwwc) {
      setWWWC(viewOptions.wwwc);
    }

    if (!initialViewOptions.current) {
      if (viewOptions?.sharpness) {
        setSharpness(viewOptions.sharpness);
      }

      if (viewOptions?.invert) {
        setIsInverted(viewOptions.invert);
      }
    }
  }, [
    viewOptions?.invert,
    viewOptions?.sharpness,
    viewOptions?.wwwc,
    toothID,
    initialViewOptions.current,
  ]);

  useEffect(() => {
    if (imageAnnotations?.length) {
      setAnnotations(imageAnnotations);
    }
  }, [imageAnnotations]);

  useEffect(() => {
    if (viewOptions) {
      initialViewOptions.current = viewOptions;
    }
  }, []);

  useEffect(() => {
    switch (activeControl) {
      case 'ruler':
      case 'arrow':
      case 'angle':
      case 'eraser':
        setMode('annotate');
        setCurrentAnnotation(activeControl);
        setViewAnnotations(true);
        break;
      case 'view':
        setMode('view');
        setViewAnnotations(false);
        break;
      case 'brightness':
        setMode('windowing');
        setViewAnnotations(true);
        break;
      case 'sharpness':
        setMode('sharpening');
        setViewAnnotations(true);
        break;
      case 'expand':
        setExpandedImage(src);
        break;
      case 'reset':
        handleReset();
        break;
      default:
        setMode('view');
        setCurrentAnnotation(null);
        setViewAnnotations(true);
    }
  }, [activeControl]);

  return {
    activeControl,
    handleSetActiveControl,
    mode,
    handleSetMode,
    annotations,
    handleSetAnnotations,
    isInverted,
    toggleIsInverted,
    isSplit,
    toggleIsSplit,
    sharpness,
    handleSetSharpness,
    viewAnnotations,
    currentAnnotation,
    wwwc,
    handleSetWWWC,
    expandedImage,
    setSourceWWWC,
    handleSetExpandedImage,
    toggleAnnotationsVisibility,
    handleSetCroppedImageAnnotations,
    handleSetMedicalImageViewOptions,
    handleSetReportMedicalImageViewOptions,
    handleSetCroppedMedicalImageViewOptions,
  };
};
