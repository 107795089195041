import { useEffect, useRef, useState } from 'react';
import { MPR2Api } from 'MPR2';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { Icon, Switch, Tooltip } from '@/shared/ui';

import { selectMPR2LauncherRequest } from '@/features/MPR/model/mpr.selectors';

import { mprControlsDefaultMessages } from '../config/i18n.ts';
import {
  ModeType,
  MPR_INPUT_MODES,
  MPR_TOOLS_BY_MODE_MAP,
} from '../config/constants.ts';

import styles from './MPR2Widget.module.scss';

const mpr2Api = new MPR2Api();

export const MPR2Widget = (props: { toothID: string }) => {
  const { toothID } = props;

  const { formatMessage } = useIntl();

  const [axesVisible, setAxesVisible] = useState(true);
  const [MPRMode, setMPRMode] = useState<ModeType>('mainMode');

  const mprLauncherRequest = useAppSelector(selectMPR2LauncherRequest(toothID));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mprLauncherRequest) {
      console.error(
        '[MPR2] MPR Launcher Request is undefined. Cannot start MPR2',
      );
      return () => {};
    }

    const element = mpr2Api.getElement();
    if (!ref.current) {
      console.error('[MPR2] ref.current is null. Cannot start MPR2');
      return () => {};
    }
    ref.current.appendChild(element);

    // NOTE: Is something is wrong, check is application is ready
    // api.applicationReady.on(() => {
    //   console.log('App is ready');
    // });

    mpr2Api.start(mprLauncherRequest);
  }, []);

  useEffect(() => {
    if (axesVisible) {
      mpr2Api.showCrossLines();
    } else {
      mpr2Api.hideCrossLines();
    }
  }, [axesVisible]);

  return (
    <>
      <div className={styles.mprContainer}>
        {MPR_INPUT_MODES.map((mode) => (
          <Tooltip.Primary
            key={mode}
            content={formatMessage(mprControlsDefaultMessages[mode])}
          >
            <Icon
              className={cn(styles.icon, MPRMode === mode && styles.active)}
              size={25}
              name={MPR_TOOLS_BY_MODE_MAP[mode]}
              onClick={() => {
                if (mode === 'reset') {
                  mpr2Api.reset();
                  mpr2Api.activateMode('mainMode');
                  setMPRMode('mainMode');
                } else {
                  mpr2Api.activateMode(mode);
                  setMPRMode(mode);
                }
              }}
            />
          </Tooltip.Primary>
        ))}

        <Switch
          onChange={() => setAxesVisible(!axesVisible)}
          checked={axesVisible}
        >
          {formatMessage({
            id: 'mpr.tools.axesVisible',
            defaultMessage: 'Axes',
          })}
        </Switch>
      </div>

      <div ref={ref} className={styles.mprBase}></div>
    </>
  );
};
