import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import styles from './BillingPeriod.module.scss';

type BillingPeriodProps = {
  className?: string;
  testID?: string;
  startDate: Date;
  endDate: Date;
};

export const BillingPeriod: FC<BillingPeriodProps> = (props) => {
  const { className, testID, startDate, endDate } = props;

  const { formatDate, formatMessage } = useIntl();

  return (
    <div data-testid={testID} className={cn(styles.container, className, 'p2')}>
      <div className={styles.date}>
        {formatMessage({
          id: 'global.startDate',
          defaultMessage: 'Start date',
        })}
        {': '}
        <span className={styles.innerDate}>
          {formatDate(startDate, {
            dateStyle: 'short',
          })}
        </span>
      </div>

      <div className={styles.date}>
        {formatMessage({
          id: 'global.endDate',
          defaultMessage: 'End date',
        })}
        {': '}
        <span className={styles.innerDate}>
          {formatDate(endDate, {
            dateStyle: 'short',
          })}
        </span>
      </div>
    </div>
  );
};
