// import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { object, string, InferType } from '@/shared/config/yup';

export const uploadStudySchema = object({
  mainStudyID: string(),
  IOSMeshesStudyID: string(),
  DentalPhotoStudyID: string(),
  SecondaryCBCTStudyID: string(),
});

export type UploadStudyPayload = InferType<typeof uploadStudySchema>;
