import { FC, ReactNode, useEffect } from 'react';

import { useAppDispatch } from '@/shared/hooks';
import { LocalStorageKey } from '@/shared/config';
import { ENVIRONMENT } from '@/shared/config';

import { userModel, UserTheme } from '@/entities/user';

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
  const { children } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ENVIRONMENT === 'development') {
      const theme = localStorage.getItem(LocalStorageKey.theme);

      if (!theme) {
        if (
          typeof window?.matchMedia === 'function' &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          document.documentElement.classList.add('dark-theme');

          localStorage.setItem(LocalStorageKey.theme, 'dark-theme');

          dispatch(
            userModel.thunks.setSettings({
              theme: 'dark-theme',
            }),
          );
        } else {
          document.documentElement.classList.add('light-theme');

          dispatch(
            userModel.thunks.setSettings({
              theme: 'light-theme',
            }),
          );
        }
      } else {
        document.documentElement.classList.add(theme);

        dispatch(
          userModel.thunks.setSettings({
            theme: theme as UserTheme,
          }),
        );
      }
    }
  }, []);

  return children;
};
