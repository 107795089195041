import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';

import { CheckReportSignatureModalText, reportsModel } from '../index';

export const useCheckReportSignature = (): {
  checkReportSignature: (options: {
    toothID?: string;
    onSignatureChecked: () => Promise<void>;
    onCancel?: () => void;
  }) => void;
} => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const modalText: CheckReportSignatureModalText = {
    title: formatMessage({
      id: 'dismissSignature.modalTitle',
      defaultMessage: 'Remove the signature from the report?',
    }),
    description: formatMessage({
      id: 'dismissSignature.modalDescription',
      defaultMessage:
        'The report has already been signed. If you choose to edit it, the signature will be removed and you will need to sign it again.',
    }),
    confirm: formatMessage({
      id: 'dismissSignature.yesAndRemove',
      defaultMessage: 'Yes, remove',
    }),
  };

  const checkReportSignature = (options: {
    toothID?: string;
    onSignatureChecked: () => Promise<void>;
    onCancel?: () => void;
  }) => {
    const { onSignatureChecked, toothID, onCancel } = options;

    dispatch(
      reportsModel.thunks.checkReportSignatureThunk({
        onSignatureChecked,
        toothID,
        modalText,
        onCancel,
      }),
    );
  };

  return { checkReportSignature };
};
