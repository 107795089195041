import { defineMessages } from 'react-intl';

import { ModeType } from './constants.ts';

export const mprControlsDefaultMessages = defineMessages<ModeType>({
  mainMode: {
    id: 'mprControlTools.axes',
    defaultMessage: 'Axes',
  },
  contrastBrightnessMode: {
    id: 'mprControlTools.brightness',
    defaultMessage: 'Brightness / Contrast',
  },
  zoomMode: {
    id: 'mprControlTools.zoom',
    defaultMessage: 'Zoom',
  },
  panMode: {
    id: 'mprControlTools.pan',
    defaultMessage: 'Pan',
  },
  scrollThroughVolumeMode: {
    id: 'mpr2ControlTools.scrollThroughVolumeMode',
    defaultMessage: 'Scroll through volume',
  },
  reset: {
    id: 'mprControlTools.reset',
    defaultMessage: 'Reset',
  },
});
