import { mprAppViewModes } from 'MPR2';

import { IconNames } from '@/shared/ui';

export type ModeType = keyof typeof mprAppViewModes | 'reset';

export const MPR_INPUT_MODES: ModeType[] = [
  'mainMode',
  'contrastBrightnessMode',
  'panMode',
  'zoomMode',
  'scrollThroughVolumeMode',
  'reset',
];

export const MPR_TOOLS_BY_MODE_MAP: Record<string, IconNames> = {
  mainMode: 'move2',
  contrastBrightnessMode: 'brightness',
  panMode: 'movepawFilled',
  zoomMode: 'zoom',
  scrollThroughVolumeMode: 'split',
  reset: 'rotate',
};
