import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { Code, ConnectError } from '@bufbuild/connect';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';
import { PATHS, StreamDataAccumulatorKey } from '@/shared/config';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { ToothLandmark } from '@/shared/api/protocol-ts/model/dto_report_landmark_pb';
import { toastCaller } from '@/shared/ui';
import { safeJSONParse } from '@/shared/lib';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { conditionModel } from '@/entities/condition';
import { assetsModel } from '@/entities/assets';
import { toothLandmarksModel } from '@/entities/toothLandmarks';
import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';
import { ConditionIDGroup } from '@/entities/condition/model/conditionSlice';

type ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
  [StreamDataAccumulatorKey.toothLandmarks]: ToothLandmark[];
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: number[];
  [StreamDataAccumulatorKey.initialROITeethIDs]: string[];
  [StreamDataAccumulatorKey.conditionsTeethGroup]: ConditionIDGroup;
  [StreamDataAccumulatorKey.conditionsMaxFaxGroup]: string[];
};

const dataAccumulators: ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: [],
  [StreamDataAccumulatorKey.initialROITeethIDs]: [],
  [StreamDataAccumulatorKey.toothLandmarks]: [],
  [StreamDataAccumulatorKey.conditionsTeethGroup]: {},
  [StreamDataAccumulatorKey.conditionsMaxFaxGroup]: [],
};

// NOTE: This is necessary to drastically improve performance when stream send many messages in a row.
// Instead of call dispatch on every message, we accumulate the data and use debounce to update many items one time.
// Without it browser just freeze because of spamming dispatch.
let conditionAccumulatedUpdates: Condition[] = [];
let conditionDebounceTimer: NodeJS.Timeout | null = null;

let abortController: AbortController;

export const useReportDataStream = (reportID: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const openReportDataStream = async () => {
    performance.mark('reportDataStream:start');

    abortController = new AbortController();

    dispatch(assetsModel.actions.setLoading('pending'));
    dispatch(toothModel.actions.setLoading('pending'));
    dispatch(conditionModel.actions.setLoading('pending'));
    dispatch(toothLandmarksModel.actions.setLoading('pending'));

    try {
      const reportDataStream = api.report.reportDataStream(
        { ReportID: reportID },
        { signal: abortController.signal },
      );

      for await (const { Update } of reportDataStream) {
        switch (Update.case) {
          case 'HistoricalReport': {
            dispatch(reportsModel.actions.setNewestOne(Update.value));
            dispatch(reportsModel.actions.setCurrentReport(Update.value));
            dispatch(reportsModel.actions.setLoading('succeeded'));
            break;
          }
          case 'UpdatedReport': {
            dispatch(reportsModel.actions.setNewestOne(Update.value));
            dispatch(reportsModel.actions.updateCurrentReport(Update.value));
            break;
          }
          case 'HistoricalTooth': {
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(Update.value);

            // Collect initial local ROI teeth IDs
            if (Update.value.IsInROI) {
              if (Update.value.Numeration?.ISO) {
                dataAccumulators[
                  StreamDataAccumulatorKey.initialROITeethISONumbers
                ].push(Update.value.Numeration.ISO);
              }

              dataAccumulators[
                StreamDataAccumulatorKey.initialROITeethIDs
              ].push(Update.value.ID);
            }
            break;
          }
          case 'UpdatedTooth': {
            dispatch(toothModel.actions.setNewestOne(Update.value));

            if (Update.value.IsInROI) {
              dispatch(toothModel.actions.addLocalROIToothID(Update.value.ID));
            } else {
              dispatch(
                toothModel.actions.removeLocalROIToothID(Update.value.ID),
              );
            }
            break;
          }
          case 'CreatedTooth': {
            dispatch(toothModel.actions.addOne(Update.value));

            if (Update.value.IsInROI) {
              dispatch(
                toothModel.actions.toggleLocalROITeethIDs([Update.value.ID]),
              );
            }
            break;
          }
          case 'ToothConditionsHint': {
            dispatch(allowedToothConditionsModel.actions.setOne(Update.value));
            break;
          }
          case 'EndOfHistoricalTeeth': {
            dispatch(
              toothModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );

            // TODO: Deprecated. Get rif of.
            dispatch(
              toothModel.actions.setLocalROITeethISONumbers_DEPRECATED(
                dataAccumulators[
                  StreamDataAccumulatorKey.initialROITeethISONumbers
                ],
              ),
            );

            dispatch(
              toothModel.actions.initLocalROITeethIDs(
                dataAccumulators[StreamDataAccumulatorKey.initialROITeethIDs],
              ),
            );

            dispatch(toothModel.actions.setLoading('succeeded'));

            dataAccumulators[
              StreamDataAccumulatorKey.initialROITeethISONumbers
            ] = [];
            dataAccumulators[StreamDataAccumulatorKey.initialROITeethIDs] = [];
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];

            break;
          }
          case 'HistoricalCondition': {
            const conditionsTeethGroup =
              dataAccumulators[StreamDataAccumulatorKey.conditionsTeethGroup];
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              Update.value,
            );

            const condition = Update.value;
            const toothID =
              condition.Attribution.case === 'Tooth'
                ? condition.Attribution.value.ToothID
                : '';

            if (condition.Attribution.case === 'Tooth') {
              if (toothID in conditionsTeethGroup) {
                conditionsTeethGroup[toothID].push(condition.ID);
              } else {
                conditionsTeethGroup[toothID] = [condition.ID];
              }
            } else {
              dataAccumulators[
                StreamDataAccumulatorKey.conditionsMaxFaxGroup
              ].push(condition.ID);
            }

            break;
          }
          case 'CreatedCondition': {
            dispatch(conditionModel.actions.addOne(Update.value));
            dispatch(conditionModel.actions.addTeethGroup([Update.value]));
            break;
          }
          case 'UpdatedCondition': {
            conditionAccumulatedUpdates.push(Update.value);

            if (conditionDebounceTimer) {
              clearTimeout(conditionDebounceTimer);
            }

            conditionDebounceTimer = setTimeout(() => {
              dispatch(
                conditionModel.actions.setNewestMany(
                  conditionAccumulatedUpdates,
                ),
              );
              conditionAccumulatedUpdates = []; // Clear the array after dispatching
            }, 100); // Debounce delay
            break;
          }
          case 'EndOfHistoricalConditions': {
            dispatch(
              conditionModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.conditions],
              ),
            );
            dispatch(
              conditionModel.actions.initializeTeethGroup(
                dataAccumulators[StreamDataAccumulatorKey.conditionsTeethGroup],
              ),
            );
            dispatch(
              conditionModel.actions.initializeMaxFaxGroup(
                dataAccumulators[
                  StreamDataAccumulatorKey.conditionsMaxFaxGroup
                ],
              ),
            );

            dispatch(conditionModel.actions.setLoading('succeeded'));

            // Clear accumulators
            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];
            dataAccumulators[StreamDataAccumulatorKey.conditionsTeethGroup] =
              {};
            dataAccumulators[StreamDataAccumulatorKey.conditionsMaxFaxGroup] =
              [];

            break;
          }
          case 'HistoricalAsset': {
            // NOTE: We don't need deleted assets in store
            if (!Update.value.Deleted?.Deleted) {
              dataAccumulators[StreamDataAccumulatorKey.assets].push(
                Update.value,
              );
            }
            break;
          }
          case 'EndOfHistoricalAssets': {
            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
            break;
          }
          case 'CreatedAsset': {
            dispatch(assetsModel.actions.addOne(Update.value));
            break;
          }
          case 'UpdatedAsset': {
            if (Update.value.Deleted?.Deleted) {
              dispatch(assetsModel.actions.removeOne(Update.value.ID));
            } else {
              dispatch(assetsModel.actions.setNewestOne(Update.value));
            }
            break;
          }
          case 'HistoricalToothLandmark': {
            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks].push(
              Update.value,
            );
            break;
          }
          case 'EndOfHistoricalTeethLandmarks': {
            dispatch(
              toothLandmarksModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.toothLandmarks],
              ),
            );
            dispatch(toothLandmarksModel.actions.setLoading('succeeded'));

            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks] = [];
            break;
          }
          default: {
            performance.mark('reportDataStream:end');
            performance.measure(
              'reportDataStream',
              'reportDataStream:start',
              'reportDataStream:end',
            );
          }
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);
      if (connectError.code === Code.Unauthenticated) {
        navigate(PATHS.signIn, { state: { from: location?.pathname } });
      }
      const entity = safeJSONParse(connectError.rawMessage)?.entity;

      // NOTE: Redirect only if error entity is report or the user doesn't have access to the report. In other cases it's not about report itself.
      if (
        (connectError.code === Code.NotFound && entity === 'report') ||
        connectError.code === Code.PermissionDenied
      ) {
        toastCaller({
          type: 'error',
          message: formatMessage({
            id: 'reportStream.notExistOrNoAccess',
            defaultMessage:
              'The page does not exist, or you do not have permission to access this page',
          }),
          autoClose: 10000,
        });

        navigate(PATHS.patients);
      }
    }
  };

  const closeReportDataStream = () => {
    if (abortController) {
      abortController.abort();
    }

    dispatch(reportsModel.actions.resetReportViewSettings());
    dispatch(assetsModel.actions.removeAllButNoGuarded());
    dispatch(toothModel.actions.initLocalROITeethIDs([]));
    dispatch(conditionModel.actions.reset());
  };

  useEffect(() => {
    openReportDataStream();

    return () => {
      closeReportDataStream();
    };
  }, []);
};
