import { FC, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { useAppSelector, useWindowSize } from '@/shared/hooks';
import {
  MedicalImageRender,
  MIRenderImageInterface,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { reportsModel } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';

import { maskFiltersModel } from '@/features/renderMasks';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';

import {
  CBCT_FOOTER_HEIGHT,
  CBCT_HEADER_HEIGHT,
  CBCT_PANO_MAX_HEIGHT,
  CBCT_PANO_MIN_HEIGHT,
  CBCT_PANO_PREVIEW_HEIGHT,
  CBCT_TOOTH_CHART_HEIGHT,
} from '../../config/constants';

import styles from './PanoReformatRender.module.scss';

type PanoReformatRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReformatRender: FC<PanoReformatRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const { reportID } = useParams<{ reportID: string }>();

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const isSplit = currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle;

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );

  const reformatAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID as string),
  );

  const splitAsset = useAppSelector(
    assetsModel.selectors.selectCBCTPanoReformatSplitAsset(
      currentReport?.ID as string,
    ),
  );

  const CBCTGPPanoramaReformatGeneral =
    reformatAsset?.Kind.case === 'GeneratedReport' &&
    reformatAsset.Kind.value.Content.case === 'CBCTGPPanoramaReformatGeneral'
      ? reformatAsset.Kind.value.Content.value
      : null;
  const CBCTGPPanoramaReformatGeneralImage =
    CBCTGPPanoramaReformatGeneral?.PanoramaSingle?.Metadata.case === 'Image'
      ? CBCTGPPanoramaReformatGeneral?.PanoramaSingle?.Metadata.value
      : null;

  const CBCTGPPanoramaReformatSplit =
    splitAsset?.Kind.case === 'GeneratedReport' &&
    splitAsset.Kind.value.Content.case === 'CBCTGPPanoramaReformatSplit'
      ? splitAsset.Kind.value.Content.value
      : null;
  const CBCTGPPanoramaReformatSplitImage =
    CBCTGPPanoramaReformatSplit?.PanoramaSplit?.Metadata.case === 'Image'
      ? CBCTGPPanoramaReformatSplit?.PanoramaSplit?.Metadata.value
      : null;

  const reformatPanoMIRenderImageInterface: MIRenderImageInterface = {
    ImageID: reformatAsset?.ID as string,
    OrientationAngle: 0,
    originalSize: {
      width: Number(CBCTGPPanoramaReformatGeneralImage?.Width ?? 0),
      height: Number(CBCTGPPanoramaReformatGeneralImage?.Height ?? 0),
    },
    MedicalImageFeatures: currentReport?.MedicalImageFeatures,
  };

  const splitPanoMIRenderImageInterface: MIRenderImageInterface = {
    ImageID: splitAsset?.ID as string,
    OrientationAngle: 0,
    originalSize: {
      width: Number(CBCTGPPanoramaReformatSplitImage?.Width ?? 0),
      height: Number(CBCTGPPanoramaReformatSplitImage?.Height ?? 0),
    },
    MedicalImageFeatures: currentReport?.MedicalImageFeatures,
  };

  // Masks
  const masksRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );

  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectHoveredBBoxList,
  );

  useLayoutEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef);
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  useEffect(() => {
    const shouldRunRender = !!reportID && currentReport?.DisplayAssetID;
    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        [
          [[reformatPanoMIRenderImageInterface]],
          [[splitPanoMIRenderImageInterface]],
        ],
        currentReport?.MedicalImageFeatures
          ?.ViewOptions as MedicalImageViewOptions,
        {
          [reformatPanoMIRenderImageInterface.ImageID]: {
            viewportType: 'pano',
          },
        },
      );

      if (!isRenderStartCorrect) {
        return;
      }

      MedicalImageRender.layoutModes.focus(
        isSplit
          ? splitPanoMIRenderImageInterface?.ImageID
          : reformatPanoMIRenderImageInterface.ImageID,
      );
    }
  }, [
    reportID,
    reformatPanoMIRenderImageInterface,
    splitPanoMIRenderImageInterface,
    readyForRender,
    isSplit,
  ]);

  useEffect(() => {
    if (isSplit) {
      MedicalImageRender.layoutModes.focus(
        splitPanoMIRenderImageInterface.ImageID,
      );
    } else {
      MedicalImageRender.layoutModes.focus(
        reformatPanoMIRenderImageInterface.ImageID,
      );
    }
  }, [isSplit]);

  // Preview settings
  useEffect(() => {
    if (
      readyForRender &&
      MedicalImageRender.isRunning() &&
      previewSettings?.isPreview
    ) {
      MedicalImageRender.activateMode('printMode');

      if (previewSettings?.isInverted) {
        MedicalImageRender.invertColors();
      } else if (!previewSettings?.isInverted) {
        MedicalImageRender.straightColors();
      }
    }
  }, [readyForRender, previewSettings?.isPreview, previewSettings?.isInverted]);

  // Render masks
  useEffect(() => {
    if (previewSettings?.isPreview) {
      if (MedicalImageRender.isRunning() && readyForRender) {
        MedicalImageRender.deleteMasks();
        if (masksRenderData.length > 0 && previewSettings.showMasks) {
          MedicalImageRender.addMasks(masksRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    } else {
      if (MedicalImageRender.isRunning() && readyForRender) {
        MedicalImageRender.deleteMasks();
        if (masksRenderData.length > 0) {
          MedicalImageRender.addMasks(masksRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    }
  }, [
    masksRenderData,
    readyForRender,

    activeMaskFilters,
    previewSettings?.showMasks,
    previewSettings?.isPreview,
  ]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();

        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  const { height: windowHeight } = useWindowSize();

  const dynamicContainerHeight =
    windowHeight -
    CBCT_TOOTH_CHART_HEIGHT -
    CBCT_FOOTER_HEIGHT -
    CBCT_HEADER_HEIGHT -
    80; // manual value

  const scaledContainerHeight =
    dynamicContainerHeight > CBCT_PANO_MIN_HEIGHT
      ? dynamicContainerHeight < CBCT_PANO_MAX_HEIGHT
        ? dynamicContainerHeight
        : CBCT_PANO_MAX_HEIGHT
      : CBCT_PANO_MIN_HEIGHT;

  const containerHeight = previewSettings?.isPreview
    ? CBCT_PANO_PREVIEW_HEIGHT
    : scaledContainerHeight;

  return (
    <div
      ref={mainViewRef}
      id="report_render"
      style={{
        height: containerHeight,
        display: 'flex',
      }}
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
      <canvas
        ref={canvasRef}
        className={styles.canvas}
        style={{ position: 'absolute', borderRadius: '16px' }}
      />
    </div>
  );
};
