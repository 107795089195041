// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_report.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { Report, ReportPrintSettings, ReportSettings } from "../../model/dto_report_pb.js";
import { Patient } from "../../model/dto_patient_pb.js";
import { Organization } from "../../model/dto_organization_pb.js";
import { Study } from "../../model/dto_study_pb.js";
import { Tooth } from "../../model/dto_report_tooth_pb.js";
import { Condition } from "../../model/dto_report_condition_pb.js";
import { ToothLandmark } from "../../model/dto_report_landmark_pb.js";
import { Asset, AssetType } from "../../model/dto_asset_pb.js";
import { User } from "../../model/dto_user_pb.js";
import { ConditionCode } from "../../model/dto_report_condition_codes_pb.js";
import { ToothNumeration } from "../../model/dto_report_common_pb.js";
import { ReportRequest } from "../../model/dto_report_request_pb.js";
import { Attribute } from "../../model/dto_base_pb.js";
import { MedicalImageViewOptions } from "../../model/dto_common_image_view_options_pb.js";
import { Annotation } from "../../model/dto_common_image_annotations_pb.js";

/**
 * @generated from message com.diagnocat.core.ReportDataStreamReq
 */
export const ReportDataStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.ReportDataStreamReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "SkipStudies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "SkipTeeth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "SkipConditions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "SkipTeethLandmarks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "SkipUsers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "SkipAssets", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.ReportDataStreamResp
 */
export const ReportDataStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.ReportDataStreamResp",
  () => [
    { no: 1, name: "HistoricalReport", kind: "message", T: Report, oneof: "Update" },
    { no: 2, name: "HistoricalPatient", kind: "message", T: Patient, oneof: "Update" },
    { no: 15, name: "HistoricalOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 3, name: "HistoricalStudy", kind: "message", T: Study, oneof: "Update" },
    { no: 4, name: "EndOfHistoricalStudies", kind: "message", T: Empty, oneof: "Update" },
    { no: 5, name: "HistoricalTooth", kind: "message", T: Tooth, oneof: "Update" },
    { no: 6, name: "EndOfHistoricalTeeth", kind: "message", T: Empty, oneof: "Update" },
    { no: 7, name: "HistoricalCondition", kind: "message", T: Condition, oneof: "Update" },
    { no: 8, name: "EndOfHistoricalConditions", kind: "message", T: Empty, oneof: "Update" },
    { no: 9, name: "HistoricalToothLandmark", kind: "message", T: ToothLandmark, oneof: "Update" },
    { no: 10, name: "EndOfHistoricalTeethLandmarks", kind: "message", T: Empty, oneof: "Update" },
    { no: 11, name: "HistoricalAsset", kind: "message", T: Asset, oneof: "Update" },
    { no: 61, name: "EndOfHistoricalAssetsType", kind: "enum", T: proto3.getEnumType(AssetType), oneof: "Update" },
    { no: 12, name: "EndOfHistoricalAssets", kind: "message", T: Empty, oneof: "Update" },
    { no: 13, name: "HistoricalUser", kind: "message", T: User, oneof: "Update" },
    { no: 14, name: "EndOfHistoricalUsers", kind: "message", T: Empty, oneof: "Update" },
    { no: 16, name: "HistoricalCurrentUser", kind: "message", T: User, oneof: "Update" },
    { no: 21, name: "UpdatedReport", kind: "message", T: Report, oneof: "Update" },
    { no: 22, name: "UpdatedPatient", kind: "message", T: Patient, oneof: "Update" },
    { no: 23, name: "UpdatedStudy", kind: "message", T: Study, oneof: "Update" },
    { no: 24, name: "UpdatedTooth", kind: "message", T: Tooth, oneof: "Update" },
    { no: 25, name: "UpdatedCondition", kind: "message", T: Condition, oneof: "Update" },
    { no: 26, name: "UpdatedToothLandmark", kind: "message", T: ToothLandmark, oneof: "Update" },
    { no: 27, name: "UpdatedAsset", kind: "message", T: Asset, oneof: "Update" },
    { no: 28, name: "UpdatedUser", kind: "message", T: User, oneof: "Update" },
    { no: 29, name: "UpdatedOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 30, name: "UpdatedCurrentUser", kind: "message", T: User, oneof: "Update" },
    { no: 31, name: "CreatedTooth", kind: "message", T: Tooth, oneof: "Update" },
    { no: 32, name: "CreatedCondition", kind: "message", T: Condition, oneof: "Update" },
    { no: 33, name: "CreatedToothLandmark", kind: "message", T: ToothLandmark, oneof: "Update" },
    { no: 34, name: "CreatedAsset", kind: "message", T: Asset, oneof: "Update" },
    { no: 51, name: "ToothConditionsHint", kind: "message", T: ReportDataStreamResp_AllowedToothConditionsHint, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.ReportDataStreamResp.AllowedToothConditionsHint
 */
export const ReportDataStreamResp_AllowedToothConditionsHint = proto3.makeMessageType(
  "com.diagnocat.core.ReportDataStreamResp.AllowedToothConditionsHint",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ConditionCodes", kind: "enum", T: proto3.getEnumType(ConditionCode), repeated: true },
  ],
  {localName: "ReportDataStreamResp_AllowedToothConditionsHint"},
);

/**
 * @generated from message com.diagnocat.core.ReportDataStreamResp.TeethNumerationsByConditionCode
 */
export const ReportDataStreamResp_TeethNumerationsByConditionCode = proto3.makeMessageType(
  "com.diagnocat.core.ReportDataStreamResp.TeethNumerationsByConditionCode",
  () => [
    { no: 1, name: "Code", kind: "enum", T: proto3.getEnumType(ConditionCode) },
    { no: 2, name: "TeethNumerations", kind: "message", T: ToothNumeration, repeated: true },
  ],
  {localName: "ReportDataStreamResp_TeethNumerationsByConditionCode"},
);

/**
 * @generated from message com.diagnocat.core.RequestReportReq
 */
export const RequestReportReq = proto3.makeMessageType(
  "com.diagnocat.core.RequestReportReq",
  () => [
    { no: 1, name: "Request", kind: "message", T: ReportRequest },
  ],
);

/**
 * @generated from message com.diagnocat.core.RequestReportResp
 */
export const RequestReportResp = proto3.makeMessageType(
  "com.diagnocat.core.RequestReportResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.GetReportsByStudyAssetAttributesReq
 */
export const GetReportsByStudyAssetAttributesReq = proto3.makeMessageType(
  "com.diagnocat.core.GetReportsByStudyAssetAttributesReq",
  () => [
    { no: 1, name: "AssetAttributes", kind: "message", T: Attribute, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.GetReportsByStudyAssetAttributesResp
 */
export const GetReportsByStudyAssetAttributesResp = proto3.makeMessageType(
  "com.diagnocat.core.GetReportsByStudyAssetAttributesResp",
  () => [
    { no: 1, name: "Reports", kind: "message", T: Report, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteReportReq
 */
export const DeleteReportReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteReportReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteReportResp
 */
export const DeleteReportResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteReportResp",
  () => [
    { no: 1, name: "DeletedReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeletedToothIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "DeletedConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "DeletedAssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "DeletedTeethLandmarkIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "Patient", kind: "message", T: Patient },
    { no: 6, name: "Study", kind: "message", T: Study },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetROIReq
 */
export const SetROIReq = proto3.makeMessageType(
  "com.diagnocat.core.SetROIReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ROIToothIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetROIResp
 */
export const SetROIResp = proto3.makeMessageType(
  "com.diagnocat.core.SetROIResp",
  () => [
    { no: 1, name: "AllReportTeeth", kind: "message", T: Tooth, repeated: true },
    { no: 2, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportConclusionReq
 */
export const SetReportConclusionReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportConclusionReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "LegacyConclusion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportConclusionResp
 */
export const SetReportConclusionResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportConclusionResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportSettingsReq
 */
export const SetReportSettingsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportSettingsReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Settings", kind: "message", T: ReportSettings },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportSettingsResp
 */
export const SetReportSettingsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportSettingsResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
    { no: 2, name: "TeethConditions", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportPrintSettingsReq
 */
export const SetReportPrintSettingsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportPrintSettingsReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PrintSettings", kind: "message", T: ReportPrintSettings },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportPrintSettingsResp
 */
export const SetReportPrintSettingsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportPrintSettingsResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SignReportReq
 */
export const SignReportReq = proto3.makeMessageType(
  "com.diagnocat.core.SignReportReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SignReportResp
 */
export const SignReportResp = proto3.makeMessageType(
  "com.diagnocat.core.SignReportResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportMedicalImageViewOptionsReq
 */
export const SetReportMedicalImageViewOptionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportMedicalImageViewOptionsReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "MedicalImageViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportMedicalImageViewOptionsResp
 */
export const SetReportMedicalImageViewOptionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportMedicalImageViewOptionsResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetImageMetaViewOptionsReq
 */
export const SetImageMetaViewOptionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetImageMetaViewOptionsReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ImageMetaID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "MedicalImageViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetImageMetaViewOptionsResp
 */
export const SetImageMetaViewOptionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetImageMetaViewOptionsResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * //////////////////////////////////////////////////////////////
 * //////// @exclude TOOTH
 * //////////////////////////////////////////////////////////////
 *
 * @generated from message com.diagnocat.core.SetReportToothCommentReq
 */
export const SetReportToothCommentReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothCommentReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportToothCommentResp
 */
export const SetReportToothCommentResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothCommentResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothNumerationAndReviveReq
 */
export const SetToothNumerationAndReviveReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothNumerationAndReviveReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothNumerationAndReviveResp
 */
export const SetToothNumerationAndReviveResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothNumerationAndReviveResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionNumerationAndReviveReq
 */
export const SetToothDetectionNumerationAndReviveReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionNumerationAndReviveReq",
  () => [
    { no: 1, name: "ToothDetectionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionNumerationAndReviveResp
 */
export const SetToothDetectionNumerationAndReviveResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionNumerationAndReviveResp",
  () => [
    { no: 1, name: "Teeth", kind: "message", T: Tooth, repeated: true },
    { no: 2, name: "Conditions", kind: "message", T: Condition, repeated: true },
    { no: 3, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 4, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkToothAsDeletedReq
 */
export const MarkToothAsDeletedReq = proto3.makeMessageType(
  "com.diagnocat.core.MarkToothAsDeletedReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkToothAsDeletedResp
 */
export const MarkToothAsDeletedResp = proto3.makeMessageType(
  "com.diagnocat.core.MarkToothAsDeletedResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkToothDetectionAsDeletedReq
 */
export const MarkToothDetectionAsDeletedReq = proto3.makeMessageType(
  "com.diagnocat.core.MarkToothDetectionAsDeletedReq",
  () => [
    { no: 1, name: "ToothDetectionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkToothDetectionAsDeletedResp
 */
export const MarkToothDetectionAsDeletedResp = proto3.makeMessageType(
  "com.diagnocat.core.MarkToothDetectionAsDeletedResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.ApproveReportToothReq
 */
export const ApproveReportToothReq = proto3.makeMessageType(
  "com.diagnocat.core.ApproveReportToothReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToothConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.ApproveReportToothResp
 */
export const ApproveReportToothResp = proto3.makeMessageType(
  "com.diagnocat.core.ApproveReportToothResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Conditions", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.DisapproveReportToothReq
 */
export const DisapproveReportToothReq = proto3.makeMessageType(
  "com.diagnocat.core.DisapproveReportToothReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DisapproveReportToothResp
 */
export const DisapproveReportToothResp = proto3.makeMessageType(
  "com.diagnocat.core.DisapproveReportToothResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.ApproveAllTeethAndSignReportReq
 */
export const ApproveAllTeethAndSignReportReq = proto3.makeMessageType(
  "com.diagnocat.core.ApproveAllTeethAndSignReportReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "TeethConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.ApproveAllTeethAndSignReportResp
 */
export const ApproveAllTeethAndSignReportResp = proto3.makeMessageType(
  "com.diagnocat.core.ApproveAllTeethAndSignReportResp",
  () => [
    { no: 1, name: "Report", kind: "message", T: Report },
    { no: 2, name: "Teeth", kind: "message", T: Tooth, repeated: true },
    { no: 3, name: "Conditions", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.ResetReportToothReq
 */
export const ResetReportToothReq = proto3.makeMessageType(
  "com.diagnocat.core.ResetReportToothReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.ResetReportToothResp
 */
export const ResetReportToothResp = proto3.makeMessageType(
  "com.diagnocat.core.ResetReportToothResp",
  () => [
    { no: 1, name: "Teeth", kind: "message", T: Tooth, repeated: true },
    { no: 2, name: "Conditions", kind: "message", T: Condition, repeated: true },
    { no: 3, name: "ToothLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 4, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.AddToothDisplaySliceReq
 */
export const AddToothDisplaySliceReq = proto3.makeMessageType(
  "com.diagnocat.core.AddToothDisplaySliceReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AddToothDisplaySliceResp
 */
export const AddToothDisplaySliceResp = proto3.makeMessageType(
  "com.diagnocat.core.AddToothDisplaySliceResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.RemoveToothDisplaySliceReq
 */
export const RemoveToothDisplaySliceReq = proto3.makeMessageType(
  "com.diagnocat.core.RemoveToothDisplaySliceReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.RemoveToothDisplaySliceResp
 */
export const RemoveToothDisplaySliceResp = proto3.makeMessageType(
  "com.diagnocat.core.RemoveToothDisplaySliceResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
    { no: 2, name: "Report", kind: "message", T: Report, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothLocalizationCropTopLayerAnnotationsReq
 */
export const SetToothLocalizationCropTopLayerAnnotationsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothLocalizationCropTopLayerAnnotationsReq",
  () => [
    { no: 1, name: "LocalizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Annotations", kind: "message", T: Annotation, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothLocalizationCropTopLayerAnnotationsResp
 */
export const SetToothLocalizationCropTopLayerAnnotationsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothLocalizationCropTopLayerAnnotationsResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothLocalizationCropMedicalImageViewOptionsReq
 */
export const SetToothLocalizationCropMedicalImageViewOptionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothLocalizationCropMedicalImageViewOptionsReq",
  () => [
    { no: 1, name: "LocalizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "MedicalImageViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothLocalizationCropMedicalImageViewOptionsResp
 */
export const SetToothLocalizationCropMedicalImageViewOptionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothLocalizationCropMedicalImageViewOptionsResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionCropTopLayerAnnotationsReq
 */
export const SetToothDetectionCropTopLayerAnnotationsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionCropTopLayerAnnotationsReq",
  () => [
    { no: 1, name: "ToothDetectionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Annotations", kind: "message", T: Annotation, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionCropTopLayerAnnotationsResp
 */
export const SetToothDetectionCropTopLayerAnnotationsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionCropTopLayerAnnotationsResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionCropMedicalImageViewOptionsReq
 */
export const SetToothDetectionCropMedicalImageViewOptionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionCropMedicalImageViewOptionsReq",
  () => [
    { no: 1, name: "ToothDetectionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "MedicalImageViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetToothDetectionCropMedicalImageViewOptionsResp
 */
export const SetToothDetectionCropMedicalImageViewOptionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetToothDetectionCropMedicalImageViewOptionsResp",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: Tooth },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreateReportToothConditionsReq
 */
export const CreateReportToothConditionsReq = proto3.makeMessageType(
  "com.diagnocat.core.CreateReportToothConditionsReq",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Conditions", kind: "message", T: CreateReportToothConditionsReq_Condition, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreateReportToothConditionsReq.Condition
 */
export const CreateReportToothConditionsReq_Condition = proto3.makeMessageType(
  "com.diagnocat.core.CreateReportToothConditionsReq.Condition",
  () => [
    { no: 1, name: "Code", kind: "enum", T: proto3.getEnumType(ConditionCode) },
    { no: 2, name: "ParentConditionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "CreateReportToothConditionsReq_Condition"},
);

/**
 * Condition with UserDecision == PositiveDecision
 *
 * @generated from message com.diagnocat.core.CreateReportToothConditionsResp
 */
export const CreateReportToothConditionsResp = proto3.makeMessageType(
  "com.diagnocat.core.CreateReportToothConditionsResp",
  () => [
    { no: 1, name: "Conditions", kind: "message", T: Condition, repeated: true },
    { no: 2, name: "Tooth", kind: "message", T: Tooth },
    { no: 3, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportToothConditionsUserDecisionReq
 */
export const SetReportToothConditionsUserDecisionReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothConditionsUserDecisionReq",
  () => [
    { no: 1, name: "PositiveDecisionConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "NegativeDecisionConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportToothConditionsUserDecisionResp
 */
export const SetReportToothConditionsUserDecisionResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothConditionsUserDecisionResp",
  () => [
    { no: 1, name: "Conditions", kind: "message", T: Condition, repeated: true },
    { no: 2, name: "Tooth", kind: "message", T: Tooth },
    { no: 3, name: "Report", kind: "message", T: Report },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportToothConditionUserScoreAndApproveToothReq
 */
export const SetReportToothConditionUserScoreAndApproveToothReq = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothConditionUserScoreAndApproveToothReq",
  () => [
    { no: 1, name: "ConditionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserScore", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetReportToothConditionUserScoreAndApproveToothResp
 */
export const SetReportToothConditionUserScoreAndApproveToothResp = proto3.makeMessageType(
  "com.diagnocat.core.SetReportToothConditionUserScoreAndApproveToothResp",
  () => [
    { no: 1, name: "Condition", kind: "message", T: Condition },
    { no: 2, name: "Tooth", kind: "message", T: Tooth },
    { no: 3, name: "Report", kind: "message", T: Report },
  ],
);

