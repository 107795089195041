import { Certainty } from '../../api/protocol-ts/model/dto_report_certainty_pb';
import { FeatureFlag } from '../../api/protocol-ts/model/dto_organization_pb';
import { MaxFaxAnatomyLocalization } from '../../api/protocol-ts/model/dto_report_maxfax_anatomy_localization_pb';
import { Report, ReportType } from '../../api/protocol-ts/model/dto_report_pb';
import { ConditionCode } from '../../api/protocol-ts/model/dto_report_condition_codes_pb';
import { Tooth } from '../../api/protocol-ts/model/dto_report_tooth_pb';
import { Localization } from '../../api/protocol-ts/model/dto_report_localization_pb';
import {
  IOXRay_Anatomy,
  IOXRay_Tooth_Anatomy,
  Pano_Anatomy,
  Pano_Tooth_Anatomy,
} from '../../api/protocol-ts/model/dto_report_type_2D_common_pb';
import {
  CBCT_Anatomy,
  CBCT_Tooth_Anatomy,
} from '../../api/protocol-ts/model/dto_common_anatomy_pb';
import { ToothAnatomyLocalization } from '../../api/protocol-ts/model/dto_report_tooth_anatomy_localization_pb';
import { Decision } from '../../api/protocol-ts/model/dto_report_common_pb';
import { Condition } from '../../api/protocol-ts/model/dto_report_condition_pb';
import {
  CONDITIONS_BY_MASK_GROUP,
  LOW_PROBABILITY_CONDITION_CODES,
  WA_CLIENT_MASK_RENDER_CONFIG,
  MASK_FILTERS_CBCT_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_CBCT_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_ENDO_CODES,
  MASK_FILTERS_IOXRAY_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_IOXRAY_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_PANO_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_PANO_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_PERIO_CODES,
  MASK_FILTERS_PREVIOUS_TREATMENT,
  MASK_FILTERS_RESTORATIVE_CODES,
  RenderOptions,
  MASK_CONDITIONS_TO_SHOW,
  MaskRenderConfig,
} from '../../config/constants';
import {
  MaskData,
  MaskGroupID,
} from '../../graphics/medicalImageRender/MedicalImageRender';
import { Asset } from '../../api/protocol-ts/model/dto_asset_pb';
import {
  Mask2DConfigCodes,
  MaskFilterParams,
  MaskGroup,
} from '../../config/types';

export { type MaskData } from '../../graphics/medicalImageRender/MedicalImageRender';
export { type RenderOptions } from '../../config/constants';

export const getMaxFaxLocalizations = (
  report: Report | undefined,
): MaxFaxAnatomyLocalization[] => {
  let reportLocalizationData;

  switch (report?.Specific.case) {
    case 'DataCBCTGP':
      reportLocalizationData = report.Specific.value;
      break;
    case 'DataIOXRayGP':
      reportLocalizationData = report.Specific.value;
      break;
    case 'DataPanoGP':
      reportLocalizationData = report.Specific.value;
      break;
    case 'DataPanoBitewings':
      reportLocalizationData = report.Specific.value;
      break;
    default:
      reportLocalizationData = undefined;
  }

  return reportLocalizationData?.MaxFaxAnatomyLocalizations || [];
};

/**
 * @deprecated
 */
export const isConditionUncertain = (
  { Certainty }: Condition = {} as Condition,
) =>
  Certainty?.EngineDecision === Decision.PositiveDecision &&
  Certainty?.UserDecision === Decision.NoDecision &&
  Certainty?.ModelScore >= 0.3 &&
  Certainty?.ModelScore < 0.5;

export const conditionIsUncertain = (certainty?: Certainty) =>
  certainty?.EngineDecision === Decision.PositiveDecision &&
  certainty?.UserDecision === Decision.NoDecision &&
  certainty?.ModelScore >= 0.3 &&
  certainty?.ModelScore < 0.5;

export const shouldConditionItemBeShown =
  (showLowProbability = false) =>
  (condition?: Condition): boolean => {
    if (!condition) {
      return false;
    }

    const { Certainty } = condition;

    if (
      isConditionUncertain(condition) &&
      LOW_PROBABILITY_CONDITION_CODES.includes(condition.Code)
    ) {
      return showLowProbability;
    }

    // EngineDecision
    if (Certainty?.EngineDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.EngineDecision === Decision.NegativeDecision) {
      return false;
    }

    // UserDecision
    if (Certainty?.UserDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.UserDecision === Decision.NegativeDecision) {
      return false;
    }

    // ModelDecision
    if (Certainty?.ModelDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.ModelDecision === Decision.NegativeDecision) {
      return false;
    }

    return false;
  };

export const convertConditionLocalizationsIntoMask = (
  condition: Condition,
  customMaskRenderConfig?: MaskRenderConfig,
) => {
  if (!condition.Localizations.length) {
    return [];
  }

  const masks = condition.Localizations.filter(
    (localization) => localization?.PolygonalMask?.length,
  ).flatMap((localization) => {
    return convertLocalizationToMaskData(
      localization,
      {
        conditionCode: condition.Code,
      },
      customMaskRenderConfig,
    );
  });

  return masks;
};

const getMaskColorConfig = (
  codes: Mask2DConfigCodes,
  customMaskRenderConfig?: MaskRenderConfig,
): RenderOptions => {
  const conditionCode = codes.conditionCode;
  const IOXRayToothAnatomyCode = codes.IOXRayToothAnatomy;
  const PanoToothAnatomyCode = codes.PanoToothAnatomy;
  const CBCTToothAnatomyCode = codes.CBCTToothAnatomy;
  const IOXRayMaxFaxAnatomyCode = codes.IOXRayMaxFaxAnatomy;
  const PanoMaxFaxAnatomyCode = codes.PanoMaxFaxAnatomy;
  const CBCTMaxFaxAnatomyCode = codes.CBCTMaxFaxAnatomy;

  const maskRenderConfig =
    customMaskRenderConfig || WA_CLIENT_MASK_RENDER_CONFIG;

  if (conditionCode) {
    if (conditionCode === ConditionCode.ToothGerm) {
      return maskRenderConfig.enamel;
    }
    if (MASK_FILTERS_PREVIOUS_TREATMENT.includes(conditionCode)) {
      return maskRenderConfig.previousTreatment;
    }
    if (MASK_FILTERS_ENDO_CODES.includes(conditionCode)) {
      return maskRenderConfig.endo;
    }
    if (MASK_FILTERS_PERIO_CODES.includes(conditionCode)) {
      return maskRenderConfig.perio;
    }
    if (MASK_FILTERS_RESTORATIVE_CODES.includes(conditionCode)) {
      return maskRenderConfig.restorative;
    }
  }

  if (
    IOXRayMaxFaxAnatomyCode ||
    PanoMaxFaxAnatomyCode ||
    CBCTMaxFaxAnatomyCode
  ) {
    // anatomy bone color
    if (
      IOXRayMaxFaxAnatomyCode === IOXRay_Anatomy.IOXRay_Anatomy_AlveolarBone ||
      PanoMaxFaxAnatomyCode === Pano_Anatomy.Pano_Anatomy_Maxilla ||
      PanoMaxFaxAnatomyCode === Pano_Anatomy.Pano_Anatomy_Mandible ||
      CBCTMaxFaxAnatomyCode === CBCT_Anatomy.CBCT_Anatomy_Cranium ||
      CBCTMaxFaxAnatomyCode === CBCT_Anatomy.CBCT_Anatomy_Mandible ||
      CBCTMaxFaxAnatomyCode === CBCT_Anatomy.CBCT_Anatomy_Maxilla
    ) {
      return maskRenderConfig.bone;
    }

    // anatomy canal color
    if (
      IOXRayMaxFaxAnatomyCode ===
        IOXRay_Anatomy.IOXRay_Anatomy_MandibularCanal ||
      PanoMaxFaxAnatomyCode === Pano_Anatomy.Pano_Anatomy_MandibularCanal ||
      CBCTMaxFaxAnatomyCode === CBCT_Anatomy.CBCT_Anatomy_MandibularCanal
    ) {
      return maskRenderConfig.canal;
    }
  }

  if (IOXRayToothAnatomyCode || PanoToothAnatomyCode || CBCTToothAnatomyCode) {
    // DENTIN TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Root ||
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Crown ||
      PanoToothAnatomyCode === Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Root ||
      PanoToothAnatomyCode === Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Crown ||
      CBCTToothAnatomyCode === CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Dentin ||
      CBCTToothAnatomyCode === CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Root
    ) {
      return maskRenderConfig.dentin;
    }

    // PULP TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_PulpChamber ||
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_RootCanal ||
      PanoToothAnatomyCode ===
        Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_RootCanal ||
      PanoToothAnatomyCode ===
        Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_PulpChamber ||
      CBCTToothAnatomyCode === CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Pulp
    ) {
      return maskRenderConfig.pulp;
    }

    // ENAMEL TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_IncisalEdge ||
      IOXRayToothAnatomyCode ===
        IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Enamel ||
      PanoToothAnatomyCode === Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Enamel ||
      CBCTToothAnatomyCode === CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Enamel
    ) {
      return maskRenderConfig.enamel;
    }

    // UNSORTED MASKS COLOR
    return maskRenderConfig.unsorted;
  }

  return maskRenderConfig.unsorted;
};

const getGroupID = (codes: Mask2DConfigCodes): MaskGroupID => {
  const conditionCode = codes.conditionCode;
  const IOXRayToothAnatomyCode = codes.IOXRayToothAnatomy;
  const PanoToothAnatomyCode = codes.PanoToothAnatomy;
  const CBCTToothAnatomyCode = codes.CBCTToothAnatomy;
  const IOXRayMaxFaxAnatomyCode = codes.IOXRayMaxFaxAnatomy;
  const PanoMaxFaxAnatomyCode = codes.PanoMaxFaxAnatomy;
  const CBCTMaxFaxAnatomyCode = codes.CBCTMaxFaxAnatomy;

  if (conditionCode) {
    if (MASK_FILTERS_PERIO_CODES.includes(conditionCode)) {
      return 'perio';
    }
    if (MASK_FILTERS_RESTORATIVE_CODES.includes(conditionCode)) {
      return 'restorative';
    }
    if (MASK_FILTERS_ENDO_CODES.includes(conditionCode)) {
      return 'endo';
    }
    if (MASK_FILTERS_PREVIOUS_TREATMENT.includes(conditionCode)) {
      return 'chart';
    }
  }
  if (
    IOXRayToothAnatomyCode ||
    PanoToothAnatomyCode ||
    CBCTToothAnatomyCode ||
    IOXRayMaxFaxAnatomyCode ||
    PanoMaxFaxAnatomyCode ||
    CBCTMaxFaxAnatomyCode
  ) {
    return 'chart';
  }

  return 'hidden';
};

export const convertLocalizationToMaskData = (
  localization: Localization,
  codes: Mask2DConfigCodes,
  customMaskRenderConfig?: MaskRenderConfig,
) => {
  const result = localization.PolygonalMask.flatMap((mask) => {
    const { color, opacity, renderOrder, outlineOpacity, blending } =
      getMaskColorConfig(codes, customMaskRenderConfig);

    const groupID = getGroupID(codes);

    const maskData: MaskData = {
      imageID: localization.TargetAssetID,
      localizationID: localization.ID,
      path: mask.Points.map((point) => ({
        x: point.X,
        y: point.Y,
      })),
      color,
      opacity,
      outlineColor: color,
      outlineWidth: 1,
      renderOrder,
      outlineOpacity,
      blending,
      groupID,
      hoverDisabled: groupID === 'chart',
      meta: {
        codes,
      },
    };

    return maskData;
  });

  return result;
};

export const getActiveConditionCodes = (
  filters?: [MaskGroup, MaskFilterParams][] | undefined,
) => {
  const defaultFilters = Object.keys(CONDITIONS_BY_MASK_GROUP) as MaskGroup[];
  const filtersToUse =
    filters ||
    defaultFilters.map((group) => [group, { isActive: true, disabled: false }]);

  return filtersToUse.reduce((conditionsAccumulator, [filterGroup]) => {
    return conditionsAccumulator.concat(CONDITIONS_BY_MASK_GROUP[filterGroup]);
  }, [] as ConditionCode[]);
};

export const getToothAnatomyLocalizations = (
  currentReportID: string | undefined,
  teeth: Tooth[],
) => {
  return teeth
    .filter((tooth) => tooth.ReportID === currentReportID)
    .flatMap((tooth) =>
      tooth.Detections.filter(
        (detection) => detection.Anatomies.length > 0,
      ).flatMap((detection) => detection.Anatomies),
    );
};

export const processActiveConditionsMasks = (
  conditionsWithMasks: Condition[],
  activeConditionCodes: ConditionCode[],
  customMaskRenderConfig?: MaskRenderConfig,
): MaskData[] => {
  return conditionsWithMasks
    .filter((condition) => activeConditionCodes.includes(condition.Code))
    .flatMap((condition) =>
      convertConditionLocalizationsIntoMask(condition, customMaskRenderConfig),
    );
};

export const processMaxFaxAnatomyLocalizations = (
  maxFaxAnatomyLocalizations: MaxFaxAnatomyLocalization[],
  customMaskRenderConfig?: MaskRenderConfig,
) => {
  return maxFaxAnatomyLocalizations
    .filter((maxFaxAnatomyLocalization) => {
      switch (maxFaxAnatomyLocalization.Type.case) {
        case 'IOXRay':
          return MASK_FILTERS_IOXRAY_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.Type.value,
          );
        case 'Pano':
          return MASK_FILTERS_PANO_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.Type.value,
          );
        case 'CBCT':
          return MASK_FILTERS_CBCT_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.Type.value,
          );
        default:
          return false;
      }
    })
    .flatMap((maxFaxAnatomyLocalization) =>
      convertLocalizationToMaskData(
        maxFaxAnatomyLocalization.Localization as Localization,
        {
          IOXRayMaxFaxAnatomy:
            maxFaxAnatomyLocalization.Type.case === 'IOXRay'
              ? maxFaxAnatomyLocalization.Type.value
              : undefined,
          PanoMaxFaxAnatomy:
            maxFaxAnatomyLocalization.Type.case === 'Pano'
              ? maxFaxAnatomyLocalization.Type.value
              : undefined,
          CBCTMaxFaxAnatomy:
            maxFaxAnatomyLocalization.Type.case === 'CBCT'
              ? maxFaxAnatomyLocalization.Type.value
              : undefined,
        },
        customMaskRenderConfig,
      ),
    );
};

export const processToothAnatomyLocalizations = (
  toothAnatomyLocalizations: ToothAnatomyLocalization[],
  customMaskRenderConfig?: MaskRenderConfig,
): MaskData[] => {
  return toothAnatomyLocalizations
    .filter((toothAnatomyLocalization) => {
      switch (toothAnatomyLocalization.Type.case) {
        case 'IOXRay':
          return MASK_FILTERS_IOXRAY_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.Type.value,
          );
        case 'Pano':
          return MASK_FILTERS_PANO_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.Type.value,
          );
        case 'CBCT':
          return MASK_FILTERS_CBCT_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.Type.value,
          );
        default:
          return false;
      }
    })
    .flatMap((toothAnatomyLocalization) =>
      convertLocalizationToMaskData(
        toothAnatomyLocalization.Localization as Localization,
        {
          IOXRayToothAnatomy:
            toothAnatomyLocalization.Type.case === 'IOXRay'
              ? toothAnatomyLocalization.Type.value
              : undefined,
          PanoToothAnatomy:
            toothAnatomyLocalization.Type.case === 'Pano'
              ? toothAnatomyLocalization.Type.value
              : undefined,
          CBCTToothAnatomy:
            toothAnatomyLocalization.Type.case === 'CBCT'
              ? toothAnatomyLocalization.Type.value
              : undefined,
        },
        customMaskRenderConfig,
      ),
    );
};

export const filterConditionsWithLocalizations = (
  conditions: Condition[],
  report: Report,
  assets: Asset[],
) => {
  const isCBCT = report?.Type === ReportType.ReportType_CBCT_GP;

  const reportReformatAsset = assets.find(
    (asset) =>
      asset.Kind.case === 'GeneratedReport' &&
      asset.Kind.value?.Belonging?.ReportID === report?.ID &&
      asset.Kind.value?.Content.case === 'CBCTGPPanoramaReformatGeneral' &&
      asset.Kind.value.Content.value,
  );

  const result = conditions.filter((condition) => {
    const isCondition =
      condition.Localizations.length &&
      MASK_CONDITIONS_TO_SHOW.includes(condition.Code) &&
      condition.Certainty?.EngineDecision === Decision.PositiveDecision;

    if (isCBCT) {
      return (
        isCondition &&
        condition.Localizations.at(0)?.TargetAssetID === reportReformatAsset?.ID
      );
    }

    return isCondition;
  });

  return result;
};

export const getMasksData = ({
  conditions,
  report,
  teeth,
  assets,
  customMaskRenderConfig,
  featureFlags,
}: {
  conditions: Condition[];
  report: Report;
  teeth: Tooth[];
  assets: Asset[];
  customMaskRenderConfig?: MaskRenderConfig;
  featureFlags: Partial<Record<FeatureFlag, boolean>>;
}): MaskData[] => {
  const isCBCTMasksHidden =
    report?.Type === ReportType.ReportType_CBCT_GP &&
    featureFlags[FeatureFlag.FeatureFlag_Hide_AllCBCTMasks];

  const isAnatomyMasksHidden =
    featureFlags[FeatureFlag.FeatureFlag_Hide_AnatomyMasks];

  const isConditionsMasksHidden =
    featureFlags[FeatureFlag.FeatureFlag_Hide_ConditionsMasks];

  const conditionMasksData =
    isConditionsMasksHidden || isCBCTMasksHidden
      ? []
      : processActiveConditionsMasks(
          filterConditionsWithLocalizations(conditions, report, assets),
          getActiveConditionCodes(),
          customMaskRenderConfig,
        );

  const maxFaxMasksData =
    isAnatomyMasksHidden || isCBCTMasksHidden
      ? []
      : processMaxFaxAnatomyLocalizations(
          getMaxFaxLocalizations(report),
          customMaskRenderConfig,
        );

  const teethAnatomyMasksData =
    isAnatomyMasksHidden || isCBCTMasksHidden
      ? []
      : processToothAnatomyLocalizations(
          getToothAnatomyLocalizations(report.ID, teeth),
          customMaskRenderConfig,
        );

  const resultMasks = [
    ...conditionMasksData,
    ...maxFaxMasksData,
    ...teethAnatomyMasksData,
  ];

  return resultMasks;
};
