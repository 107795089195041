import { FC, useCallback } from 'react';

import { MedicalImage } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { localizationToPerioMasks } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { MedicalImagesGroups } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';
import { conditionModel } from '@/entities/condition';

import { maskFiltersModel } from '@/features/renderMasks';

type SliceListProps = {
  toothID: string;
  medicalImages?: MedicalImageInterface[];
};

export const GPMedicalImageList: FC<SliceListProps> = (props) => {
  const { toothID, medicalImages } = props;

  const dispatch = useAppDispatch();

  const hidePerioMasksAndSlices = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );

  const { isConditionsMasksHidden, isCBCTMasksHidden } = useAppSelector(
    maskFiltersModel.selectors.selectMasksFlags,
  );

  const allSlicesMasks = useAppSelector(
    conditionModel.selectors.selectSlicesMasks(toothID, [
      ConditionCode.PeriapicalRadiolucency,
    ]),
  );

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: { medicalImage, toothID },
        }),
      );
    },
    [toothID],
  );

  if (!medicalImages) {
    return null;
  }

  return (
    <>
      {medicalImages
        ?.filter((medicalImage) => {
          if (medicalImage.groupName === MedicalImagesGroups.PeriapicalLesion) {
            return (
              !hidePerioMasksAndSlices &&
              !isConditionsMasksHidden &&
              !isCBCTMasksHidden
            );
          }

          return true;
        })
        .map((medicalImage) => {
          const { viewOptions, assetID } = medicalImage;

          return (
            <MedicalImage
              key={medicalImage.src}
              medicalImage={medicalImage}
              onClick={() => handleOpenModal(medicalImage)}
              annotations={medicalImage.annotations}
              wc={viewOptions?.wwwc?.wc}
              ww={viewOptions?.wwwc?.ww}
              sharpness={viewOptions?.sharpness}
              inverted={viewOptions?.invert}
              masks={allSlicesMasks
                .filter(
                  (localization) => localization.TargetAssetID === assetID,
                )
                .flatMap(localizationToPerioMasks)}
            />
          );
        })}
    </>
  );
};
