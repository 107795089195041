import { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Checkbox, RadioGroup, WidgetCard } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import {
  ReportPrintSettings,
  ReportPrintSettingsPrintType,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { PrintType as PrintColorType } from '@/shared/config';
import printTypeBW from '@/shared/assets/images/printTypeButtonBW.png';
import printTypeColored from '@/shared/assets/images/printTypeButtonColored.png';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/renderMasks';

import { useDownloadPDF_DEPRECATED } from '../../hooks/useDownloadPDF';

import styles from './SettingsBar.module.scss';
type SettingsBarProps = {
  className?: string;
  settings: ReportPrintSettings;
  reportID: string;
  onChange: (newPrintSettings: ReportPrintSettings) => void;
  isLoading?: boolean;
  patientID?: string;
};

export const SettingsBar: FC<SettingsBarProps> = (props) => {
  const { className, settings, reportID, onChange, isLoading, patientID } =
    props;

  const { formatMessage } = useIntl();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));
  const isReportSigned = report?.Signature?.Signed;
  const reportType = report?.Type;

  const hideProbability = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );
  const showNonDentalFindings = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_NonDentalFindings,
    ),
  );

  const { isConditionsMasksHidden, isAnatomyMasksHidden, isCBCTMasksHidden } =
    useAppSelector(maskFiltersModel.selectors.selectMasksFlags);

  const hideMasksAndBBoxes =
    (isConditionsMasksHidden && isAnatomyMasksHidden) ||
    (reportType === ReportType.ReportType_CBCT_GP && isCBCTMasksHidden);

  const { isLoadingPDF, downloadPDF } = useDownloadPDF_DEPRECATED(
    patientID as string,
    reportID,
  );

  const {
    IsInverted,
    BlackAndWhiteMode,
    ShowBottomJaw,
    ShowTopJaw,
    ShowSlices,
    ShowMasks,
    ShowDentalChart,
    ShowDiagnosesProbabilities,
    ShowDiagnoses,
    PrintType,
    ShowBBoxes,
    ShowStudyMetadata,
  } = settings;

  const showDentalChartHandle = () => {
    // Checking if all elements (ShowDentalChart, ShowTopJaw, ShowBottomJaw) are disabled
    const isAllDisabled = !ShowDentalChart && !ShowTopJaw && !ShowBottomJaw;

    onChange({
      ...settings,
      ShowDentalChart: !ShowDentalChart,
      // If all elements are disabled, then set ShowTopJaw and ShowBottomJaw to the opposite value ShowDentalChart,
      // otherwise use the current value of ShowTopJaw
      ShowTopJaw: isAllDisabled ? !ShowDentalChart : ShowTopJaw,
      ShowBottomJaw: isAllDisabled ? !ShowDentalChart : ShowBottomJaw,
    } as ReportPrintSettings);
  };

  const showUpperJawHandle = () => {
    onChange({
      ...settings,
      ShowTopJaw: !ShowTopJaw,
    } as ReportPrintSettings);
  };

  const showLowerJawHandle = () => {
    onChange({
      ...settings,
      ShowBottomJaw: !ShowBottomJaw,
    } as ReportPrintSettings);
  };

  useEffect(() => {
    if (
      settings.ShowDentalChart &&
      !settings.ShowBottomJaw &&
      !settings.ShowTopJaw
    ) {
      onChange({
        ...settings,
        ShowDentalChart: false,
      } as ReportPrintSettings);
    }
  }, [settings, onChange]);

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <div className={styles.content}>
        <h4 className="h4">
          <FormattedMessage
            id="reportPreview.settings.label"
            defaultMessage="Settings"
          />
        </h4>

        <h3>
          <FormattedMessage
            id="reportPreview.settings.printType"
            defaultMessage="Print type"
          />
        </h3>

        <RadioGroup
          name="print-type"
          innerClassName={styles.printTypeSwitch}
          value={
            BlackAndWhiteMode
              ? PrintColorType.blackAndWhite
              : PrintColorType.colored
          }
          onChange={() =>
            onChange({
              ...settings,
              BlackAndWhiteMode: !BlackAndWhiteMode,
            } as ReportPrintSettings)
          }
          items={
            [
              {
                value: PrintColorType.blackAndWhite,
                label: (
                  <img
                    className={styles.printTypeButton}
                    src={printTypeBW}
                    alt={formatMessage({
                      id: 'printTypeButton.blackAndWhite',
                      defaultMessage: 'Black And White',
                    })}
                  />
                ),
                disabled: isLoading,
              },
              {
                value: PrintColorType.colored,
                label: (
                  <img
                    className={styles.printTypeButton}
                    src={printTypeColored}
                    alt={formatMessage({
                      id: 'printTypeButton.colored',
                      defaultMessage: 'Colored',
                    })}
                  />
                ),
                disabled: isLoading,
              },
            ] as {
              value: PrintColorType;
              label: ReactNode;
              disabled: boolean;
            }[]
          }
        />

        <div className={styles.checkboxContainer}>
          <Checkbox
            disabled={isLoading}
            checked={IsInverted}
            onChange={() =>
              onChange({
                ...settings,
                IsInverted: !IsInverted,
              } as ReportPrintSettings)
            }
          >
            <FormattedMessage
              id="reportPreview.settings.inverted"
              defaultMessage="Inverted"
            />
          </Checkbox>

          {PrintType ===
            ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full &&
            !hideProbability && (
              <Checkbox
                disabled={isLoading}
                checked={ShowDiagnosesProbabilities}
                onChange={() =>
                  onChange({
                    ...settings,
                    ShowDiagnosesProbabilities: !ShowDiagnosesProbabilities,
                  } as ReportPrintSettings)
                }
              >
                <FormattedMessage
                  id="reportPreview.settings.probability"
                  defaultMessage="Probability"
                />
              </Checkbox>
            )}

          <Checkbox
            disabled={isLoading}
            checked={ShowDentalChart}
            onChange={showDentalChartHandle}
          >
            <FormattedMessage
              id="reportPreview.settings.dentalChart"
              defaultMessage="Tooth chart"
            />
          </Checkbox>

          <Checkbox
            disabled={isLoading}
            className={styles.nestedCheckbox}
            checked={ShowTopJaw}
            onChange={showUpperJawHandle}
          >
            <FormattedMessage
              id="reportPreview.settings.upperJaw"
              defaultMessage="Upper jaw"
            />
          </Checkbox>

          <Checkbox
            disabled={isLoading}
            className={styles.nestedCheckbox}
            checked={ShowBottomJaw}
            onChange={showLowerJawHandle}
          >
            <FormattedMessage
              id="reportPreview.settings.lowerJaw"
              defaultMessage="Lower jaw"
            />
          </Checkbox>

          <Checkbox
            disabled={isLoading}
            checked={ShowDiagnoses}
            onChange={() =>
              onChange({
                ...settings,
                ShowDiagnoses: !ShowDiagnoses,
              } as ReportPrintSettings)
            }
          >
            <FormattedMessage
              id="reportPreview.settings.conditions"
              defaultMessage="Conditions"
            />
          </Checkbox>

          {PrintType ===
            ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full && (
            <Checkbox
              disabled={isLoading}
              checked={ShowSlices}
              onChange={() =>
                onChange({
                  ...settings,
                  ShowSlices: !ShowSlices,
                } as ReportPrintSettings)
              }
            >
              <FormattedMessage
                id="reportPreview.settings.slices"
                defaultMessage="Slices"
              />
            </Checkbox>
          )}

          {!hideMasksAndBBoxes && (
            <Checkbox
              disabled={isLoading}
              checked={ShowMasks}
              onChange={() =>
                onChange({
                  ...settings,
                  ShowMasks: !ShowMasks,
                } as ReportPrintSettings)
              }
            >
              <FormattedMessage
                id="reportPreview.settings.masks"
                defaultMessage="Show masks"
              />
            </Checkbox>
          )}

          {showNonDentalFindings &&
            reportType !== ReportType.ReportType_IOXRay_GP && (
              <Checkbox
                disabled={isLoading}
                checked={ShowBBoxes}
                onChange={() =>
                  onChange({
                    ...settings,
                    ShowBBoxes: !ShowBBoxes,
                  } as ReportPrintSettings)
                }
              >
                <FormattedMessage
                  id="reportPreview.settings.nonDentalFindings"
                  defaultMessage="Non-dental findings"
                />
              </Checkbox>
            )}

          <Checkbox
            disabled={isLoading}
            checked={ShowStudyMetadata}
            onChange={() => {
              onChange({
                ...settings,
                ShowStudyMetadata: !ShowStudyMetadata,
              } as ReportPrintSettings);
            }}
          >
            <FormattedMessage
              id="reportPreview.settings.studyInfo"
              defaultMessage="Study meta info"
            />
          </Checkbox>

          {/* TODO: Make them real
        <Checkbox
        disabled={isLoading} checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.recommendations"
            defaultMessage="Referral recommendations"
          />
        </Checkbox>

        <Checkbox
        disabled={isLoading} checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.orthoScreening"
            defaultMessage="Orthodontic screening"
          />
        </Checkbox>

        <Checkbox
        disabled={isLoading} checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.studyInfo"
            defaultMessage="Study meta info"
          />
        </Checkbox>
      */}
        </div>

        <div className={styles.status}>
          <p className={isReportSigned ? styles.signed : styles.notSigned}>
            {isReportSigned ? (
              <FormattedMessage
                id="reportPreview.settings.signed"
                defaultMessage="Signed by doctor"
              />
            ) : (
              <FormattedMessage
                id="reportPreview.settings.notSigned"
                defaultMessage="Not signed by doctor"
              />
            )}
          </p>

          {!isReportSigned && (
            <p>
              <FormattedMessage
                id="reportPreview.settings.disclaimer"
                defaultMessage="If you want the Conclusion to be displayed in printed version or PDF, it is necessary to sign the report at the previous step"
              />
            </p>
          )}
        </div>

        <div className={styles.printAndDownload}>
          <Button
            onClick={() => {
              window?.print();
            }}
            block
          >
            <FormattedMessage id="global.print" defaultMessage="Print" />
          </Button>

          <Button
            size="medium"
            variant="tertiary"
            onClick={downloadPDF}
            loading={isLoadingPDF}
          >
            <FormattedMessage
              id="global.reportPreview.settings.downloadPDF"
              defaultMessage="Download PDF"
            />
          </Button>
        </div>
        {/* 
      <Button variant="tertiary" onClick={() => {}}>
        <FormattedMessage
          id="reportPreview.settings.downloadReport"
          defaultMessage="Download report"
        />
      </Button> */}
      </div>
    </WidgetCard>
  );
};
