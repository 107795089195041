// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_mesh_model.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { File } from "./dto_asset_common_pb.js";
import { CBCT_Tooth_RootType, IOS_Anatomy, ToothNumeration } from "./dto_report_common_pb.js";
import { CBCT_Anatomy, CBCT_Tooth_Anatomy } from "./dto_common_anatomy_pb.js";

/**
 * @generated from enum com.diagnocat.model.CBCT_Segmented_Tooth_Condition
 */
export const CBCT_Segmented_Tooth_Condition = proto3.makeEnum(
  "com.diagnocat.model.CBCT_Segmented_Tooth_Condition",
  [
    {no: 0, name: "CBCT_Tooth_Condition_InvalidValue"},
    {no: 1, name: "CBCT_Tooth_Condition_CanalFilling"},
    {no: 2, name: "CBCT_Tooth_Condition_ArtificialCrown"},
    {no: 3, name: "CBCT_Tooth_Condition_Filling"},
    {no: 4, name: "CBCT_Tooth_Condition_IndirectRestoration"},
    {no: 5, name: "CBCT_Tooth_Condition_Abfraction"},
    {no: 6, name: "CBCT_Tooth_Condition_CariesSigns"},
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Mesh_Model
 */
export const AssetContent_Generated_Mesh_Model = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Mesh_Model",
  () => [
    { no: 1, name: "SegmentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Mesh", kind: "message", T: File },
    { no: 3, name: "CBCTTargetAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "CBCTTooth", kind: "message", T: ToothNumeration, oneof: "Structure" },
    { no: 12, name: "IOSTooth", kind: "message", T: ToothNumeration, oneof: "Structure" },
    { no: 13, name: "FusionTooth", kind: "message", T: ToothNumeration, oneof: "Structure" },
    { no: 14, name: "IOSAnatomy", kind: "enum", T: proto3.getEnumType(IOS_Anatomy), oneof: "Structure" },
    { no: 15, name: "CBCTAnatomy", kind: "enum", T: proto3.getEnumType(CBCT_Anatomy), oneof: "Structure" },
    { no: 16, name: "CBCTToothPeriapicalRadiolucency", kind: "message", T: AssetContent_Generated_Mesh_Model_CBCT_Tooth_PeriapicalRadiolucency, oneof: "Structure" },
    { no: 17, name: "ArtificialCrown", kind: "message", T: ToothNumeration, oneof: "Structure" },
    { no: 21, name: "CBCTToothAnatomy", kind: "message", T: AssetContent_Generated_Mesh_Model_CBCT_Tooth_Anatomy, oneof: "Structure" },
    { no: 22, name: "CBCTToothCondition", kind: "message", T: AssetContent_Generated_Mesh_Model_CBCT_Tooth_Condition, oneof: "Structure" },
    { no: 10000, name: "ToothNumerationWithSurfaceAttributes", kind: "message", T: File },
    { no: 10001, name: "FusedModalityAttributes", kind: "message", T: File },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_PeriapicalRadiolucency
 */
export const AssetContent_Generated_Mesh_Model_CBCT_Tooth_PeriapicalRadiolucency = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_PeriapicalRadiolucency",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 2, name: "LesionIndex", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "AssetContent_Generated_Mesh_Model_CBCT_Tooth_PeriapicalRadiolucency"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_Anatomy
 */
export const AssetContent_Generated_Mesh_Model_CBCT_Tooth_Anatomy = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_Anatomy",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 2, name: "Anatomy", kind: "enum", T: proto3.getEnumType(CBCT_Tooth_Anatomy) },
    { no: 3, name: "RootType", kind: "enum", T: proto3.getEnumType(CBCT_Tooth_RootType), opt: true },
  ],
  {localName: "AssetContent_Generated_Mesh_Model_CBCT_Tooth_Anatomy"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_Condition
 */
export const AssetContent_Generated_Mesh_Model_CBCT_Tooth_Condition = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Mesh_Model.CBCT_Tooth_Condition",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 2, name: "Condition", kind: "enum", T: proto3.getEnumType(CBCT_Segmented_Tooth_Condition) },
  ],
  {localName: "AssetContent_Generated_Mesh_Model_CBCT_Tooth_Condition"},
);

