import { createSelector } from '@reduxjs/toolkit';

import { Study, StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import {
  ReportStatus,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import {
  Asset,
  AssetContent_Study_CBCT_CBCT_Metadata,
} from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import { assetsModel } from '@/entities/assets';

import { ReportMetaInfo, StudyMetaInfo } from '../config/types';

const getCBCTMetaData = (studyID: string) => (assets: Asset[]) => {
  const targetAsset = assets.find(
    (asset) =>
      asset.Kind.case === 'Study' && asset.Kind.value.StudyID === studyID,
  );

  const studyAsset =
    targetAsset?.Kind.case === 'Study' ? targetAsset.Kind.value : undefined;

  const CBCTMetaData =
    studyAsset?.Content.case === 'CBCT'
      ? studyAsset.Content.value.Metadata
      : undefined;

  return CBCTMetaData ? CBCTMetaData : undefined;
};

export const selectReportInfo = (
  sourceStudyIDs: string[],
  reportType: ReportType,
) =>
  createSelector(
    reportsModel.selectors.selectActiveReportsByStudyIDsAndType(
      sourceStudyIDs,
      reportType,
    ),
    studyModel.selectors.selectEntities,
    assetsModel.selectors.selectAll,
    (relevantReports, studyEntities, assets) => {
      const studies: Study[] = [];

      sourceStudyIDs.forEach((studyID) => {
        if (studyID in studyEntities) {
          studies.push(studyEntities[studyID] as Study);
        }
      });

      const studiesMetaInfo = studies.map((study): StudyMetaInfo => {
        const CBCTMetaData =
          study.Type === StudyType.StudyType_CBCT
            ? getCBCTMetaData(study.ID)(assets)
            : ({} as AssetContent_Study_CBCT_CBCT_Metadata);

        return {
          ID: study.ID,
          studyType: study.Type,
          scanUID: CBCTMetaData?.ScanUID,
          manufacturer: CBCTMetaData?.Manufacturer,
          model: CBCTMetaData?.ManufacturerModelName,
          exposure: CBCTMetaData?.Exposure,
          fov: CBCTMetaData?.FOV,
          voxel: CBCTMetaData?.VoxelSize?.join(','),
          uploadDate: study.Created?.At?.toDate(),
          studyTime: study.Time?.toDate(),
        };
      });

      const reportsInfo = relevantReports.map(
        (report): ReportMetaInfo => ({
          reportID: report.ID,
          reportType: report.Type,
          patientID: report.PatientID,
          date: report.Created?.At?.toDate() as Date,
          status: report.Status as ReportStatus,
        }),
      );

      return {
        reportsInfo,
        studiesMetaInfo,
      };
    },
  );
