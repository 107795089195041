import { FC, useCallback } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MaskGroup } from '@/shared/config';

import { MaskFilterButton } from '../MaskFilterButton/MaskFilterButton';
import { maskFiltersModel } from '../..';

import styles from './MaskFilters.module.scss';

type MaskFiltersProps = {
  className?: string;
  testID?: string;
};

export const MaskFilters: FC<MaskFiltersProps> = (props) => {
  const { className, testID } = props;

  const dispatch = useAppDispatch();

  const filterGroups = useAppSelector(maskFiltersModel.selectors.selectFilters);

  const handleClick = useCallback(
    (filterGroup: MaskGroup) => {
      dispatch(maskFiltersModel.actions.toggleFilter(filterGroup));
    },
    [dispatch],
  );

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      {filterGroups.map(([filterGroup, { isActive, hide }]) =>
        hide ? null : (
          <MaskFilterButton
            key={filterGroup}
            filterGroup={filterGroup}
            active={isActive}
            onClick={handleClick}
          />
        ),
      )}
    </div>
  );
};
