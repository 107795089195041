import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertToMIRenderInterface } from '@/features/MIRenderInterface';

import {
  MedicalImageRender,
  PBLProps,
} from '../../../../shared/graphics/medicalImageRender/MedicalImageRender';

import styles from './PanowingsReportRender.module.scss';

type PanowingsReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanowingsReportRender: FC<PanowingsReportRenderProps> = (
  props,
) => {
  const { className, children, previewSettings } = props;

  const dispatch = useAppDispatch();

  const isPreviewMode = previewSettings?.isPreview;

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID as string;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mainViewRef = useRef<HTMLDivElement>(null);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportMasksReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportMasksReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  useEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef, 'main');
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  ) as IOXrayImageInterface[];

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectHoveredBBoxList,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );
  const hideConditionsMasksAndBBoxes = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  // PBL
  const showPBL = useAppSelector(
    maskFiltersModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );

  const panoMIRenderItem = convertToMIRenderInterface(panoImageInterface);

  // TODO: [16:h] need to refactor and try to avoid dependencies for MIRender
  const bitewingsMIRenderItems = useMemo(
    () => [
      ...groupedIOXRayImages.MiddleLeft.map((imageItem) =>
        convertToMIRenderInterface(imageItem),
      ),
      ...groupedIOXRayImages.MiddleRight.map((imageItem) =>
        convertToMIRenderInterface(imageItem),
      ),
    ],
    [groupedIOXRayImages.MiddleLeft, groupedIOXRayImages.MiddleRight],
  );

  // We need filter masks and bboxes data because in panowing we use only bitewings imagese from all ioxray images
  const masksFilteredByShownImages = useMemo(
    () =>
      masks2DRenderData.filter(
        (mask) =>
          bitewingsMIRenderItems.find(
            (MIRenderItem) => MIRenderItem.ImageID === mask.imageID,
          ) || panoMIRenderItem.ImageID === mask.imageID,
      ),
    [bitewingsMIRenderItems, masks2DRenderData, panoMIRenderItem.ImageID],
  );

  const filteredHoveredBBoxes = hoveredBBoxes?.filter(
    (bboxes) =>
      bitewingsMIRenderItems.find(
        (MIRenderItem) => MIRenderItem.ImageID === bboxes.imageID,
      ) || panoMIRenderItem.ImageID === bboxes.imageID,
  );

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  //render initialization
  useEffect(() => {
    const shouldRunRender =
      !!IOXRayImagesInterface.length &&
      reportReadyForRender &&
      panoImageInterface?.imageMeta &&
      teeth.length;

    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      let matrix;
      let scale;

      let gapBetweenBitewings = 60;

      if (bitewingsImagesInterface.length) {
        matrix = [[[panoMIRenderItem]], [bitewingsMIRenderItems]];

        const width = bitewingsImagesInterface
          .map((item) => item.originalSize.width)
          .reduce((a, b) => a + b);

        gapBetweenBitewings = Math.min(width * 0.02, gapBetweenBitewings);

        scale =
          (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) +
            width) /
          panoImageInterface.originalSize.width;
      } else {
        matrix = [[[panoMIRenderItem]]];
      }

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        matrix,
        reportViewOptions as MedicalImageViewOptions,
        {
          [panoImageInterface.imageMeta?.GeneratedAssetID ||
          panoImageInterface.imageMeta?.StudyAssetID]: {
            viewportType: 'pano',
            scale,
          },
        },
        { contentGap: gapBetweenBitewings },
      );

      if (!isRenderStartCorrect) {
        return;
      }
      if (isPreviewMode) {
        // WARN: this is temporary solution to show masks in preview mode. It will be fixed later.
        // MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);

        MedicalImageRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }
    }
  }, [
    bitewingsImagesInterface,
    panoImageInterface,
    reportReadyForRender,
    isPreviewMode,
    teeth,
  ]);

  // Why is this needed?
  useEffect(() => {
    if ((MedicalImageRender.isRunning(), bitewingsImagesInterface.length)) {
      const matrix = [[[panoMIRenderItem]], [[...bitewingsMIRenderItems]]];

      const width = bitewingsImagesInterface
        .map((item) => item.originalSize.width)
        .reduce((a, b) => a + b);

      const gapBetweenBitewings = Math.min(width * 0.02, 60);

      const scale =
        (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) + width) /
        panoImageInterface.originalSize.width;

      MedicalImageRender.updateLayout(
        matrix,
        { [panoImageInterface.asset.ID]: { scale } },
        { contentGap: gapBetweenBitewings },
      );
    }
  }, [groupedIOXRayImages, bitewingsImagesInterface, bitewingsImagesInterface]);

  // NOTE: PBL should be set up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender) {
      if (!isPreviewMode) {
        const PBLsData = bitewingsImagesInterface
          .map((data) => {
            const initialPBLList = getPBLsForImage(data.asset.ID);

            if (!initialPBLList) {
              return undefined;
            }

            const getPBL = (landmark: Landmark) => ({
              start: {
                x: landmark.lowPoint?.ModelPosition?.X || 0,
                y: landmark.lowPoint?.ModelPosition?.Y || 0,
              },
              end: {
                x: landmark.upPoint?.ModelPosition?.X || 0,
                y: landmark.upPoint?.ModelPosition?.Y || 0,
              },
              color: landmark.color,
              textProps: {
                color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
              },
            });

            return {
              imageID:
                data.imageMeta?.GeneratedAssetID ||
                data.imageMeta?.StudyAssetID,
              PBLs: initialPBLList.map((pbl) => {
                return getPBL(pbl);
              }),
              scale: data.imageMeta.Scale?.X || 1,
            };
          })
          .filter((data) => data) as PBLProps[];

        if (PBLsData) {
          MedicalImageRender.setPBLs(PBLsData);
          if (showPBL && !hideConditionsMasksAndBBoxes && !hidePBLFeatureFlag) {
            MedicalImageRender.showPBLs();
          }
        }
      }
    }
  }, [reportPBLReadyForRender]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportPBLReadyForRender && MedicalImageRender.isRunning()) {
      if (hidePBLFeatureFlag) {
        MedicalImageRender.hidePBLs();
      } else if (!hideConditionsMasksAndBBoxes && showPBL) {
        MedicalImageRender.showPBLs();
      } else {
        MedicalImageRender.hidePBLs();
      }
    }
  }, [
    reportPBLReadyForRender,
    showPBL,
    hideConditionsMasksAndBBoxes,
    hidePBLFeatureFlag,
  ]);

  // Render masks
  useEffect(() => {
    if (isPreviewMode) {
      if (MedicalImageRender.isRunning() && reportMasksReadyForRender) {
        MedicalImageRender.deleteMasks();
        if (
          masksFilteredByShownImages.length > 0 &&
          previewSettings.showMasks
        ) {
          MedicalImageRender.addMasks(masksFilteredByShownImages);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    } else {
      if (MedicalImageRender.isRunning() && reportMasksReadyForRender) {
        MedicalImageRender.deleteMasks();
        if (masksFilteredByShownImages.length > 0) {
          MedicalImageRender.addMasks(masksFilteredByShownImages);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    }
  }, [
    masksFilteredByShownImages.length, // WARN: do not add array of object as dependency in useEffect, because it adds dramatic perfomace issue.

    isPreviewMode,
    reportMasksReadyForRender,
    previewSettings?.showMasks,
    activeMaskFilters,
  ]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      if (filteredHoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();
        MedicalImageRender.addConditionBoxes(filteredHoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [filteredHoveredBBoxes, reportReadyForRender]);

  // Preview settings
  useEffect(() => {
    if (MedicalImageRender.isRunning() && previewSettings?.isPreview) {
      MedicalImageRender.activateMode('printMode');

      if (previewSettings?.isInverted) {
        MedicalImageRender.invertColors();
      } else if (!previewSettings?.isInverted) {
        MedicalImageRender.straightColors();
      }
    }
  }, [previewSettings?.isPreview, previewSettings?.isInverted]);

  return (
    <div
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      ref={mainViewRef}
      id="report_render"
    >
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};
