import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { ServiceType } from '@/shared/api/protocol-ts/api/billing/dto_services_pb';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

export enum BillingZones {
  'EU_UK' = 'EU_UK',
  'EU_SW' = 'EU_SW',
  'EU_MAIN' = 'EU_MAIN',
  'CIS' = 'CIS',
  'US-CA' = 'US-CA',
  'US-IRB-1' = 'US-IRB-1',
  'US-IRB-2' = 'US-IRB-2',
  'US-IRB-3' = 'US-IRB-3',
  'US-TIER-1' = 'US-TIER-1',
  'US-TIER-2' = 'US-TIER-2',
}

export const GERMANY_COUNTRY_EXCEPTION = ['DE'];

export const EUROPEAN_BILLING_ZONES = [
  BillingZones['EU_UK'],
  BillingZones['EU_SW'],
  BillingZones['EU_MAIN'],
];

export const AMERICA_BILLING_ZONES = [
  BillingZones['US-CA'],
  BillingZones['US-IRB-1'],
  BillingZones['US-IRB-2'],
  BillingZones['US-IRB-3'],
  BillingZones['US-TIER-1'],
  BillingZones['US-TIER-2'],
];

export const CIS_BILLING_ZONES = [BillingZones['CIS']];

export const EU_UK_COUNTRIES = ['GB'];
export const EU_SW_COUNTRIES = ['CH'];
export const EU_MAIN_COUNTRIES = [
  'DE',
  'AT',
  'BE',
  'DK',
  'FI',
  'IE',
  'LI',
  'LU',
  'NL',
  'NO',
  'SE',
  'FR',
  'GR',
  'IT',
  'ES',
  'BG',
  'HR',
  'CY',
  'CZ',
  'EE',
  'HU',
  'LV',
  'LT',
  'MT',
  'PL',
  'PT',
  'RO',
  'RS',
  'SK',
  'SI',
  'UA',
  'AL',
  'IL',
  'PS',
];

export const CIS_COUNTRIES = [
  'RU',
  'AM',
  'AZ',
  'BY',
  'KZ',
  'KG',
  'MD',
  'TJ',
  'UZ',
  'GE',
];

export const US_CA_COUNTRIES = ['CA'];

export const US_TIER_1_IRB_COUNTRIES = ['US'];

export const US_TIER_2_COUNTRIES = ['MX', 'CR', 'CO', 'VI', 'PR', 'GU', 'AS'];

export const USER_AGREEMENTS_LINKS = {
  termsAndConditions: 'https://diagnocat.com/legal-info/terms-conditions/',
  dataProcessingAddendumInGermany:
    'https://legal.diagnocat.com/wp-content/uploads/2023/06/Data-Processing-Addendum-Diagnocat-for-Germany-GE.pdf',
  privacyPolicyInGermany: 'https://legal.diagnocat.com/privacy-policy/de/',
  dataProcessingAddendumInEnglish:
    'https://legal.diagnocat.com/wp-content/uploads/2023/06/Data-Processing-Addendum-Diagnocat.pdf',
  privacyPolicyInEnglish: 'https://legal.diagnocat.com/privacy-policy/en/',
  agreementOfferInRussian:
    'https://diagnocat.ru/wp-content/uploads/2023/05/diagnocat-dogovor-oferta-02.05.2023.pdf',
  personalDataProcessingInRussian:
    'https://diagnocat.ru/wp-content/uploads/2023/02/politika-obrabotki-pd.pdf',
};

export type StudyToServiceMap = {
  [StudyType.StudyType_CBCT]: ServiceType.ServiceType_Order_CBCT_GP_Analysis;
  [StudyType.StudyType_IntraoralXRay]: ServiceType.ServiceType_Order_IOXRay_GP_Analysis;
  [StudyType.StudyType_PanoramicXRay]: ServiceType.ServiceType_Order_Panoramic_GP_Analysis;
  [StudyType.StudyType_IOS_Meshes]: ServiceType.ServiceType_Order_CBCT_STL_Analysis;
  [StudyType.StudyType_DentalPhoto]: ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis;
};

export const STUDY_TO_SERVICES_MAP: StudyToServiceMap = {
  [StudyType.StudyType_CBCT]: ServiceType.ServiceType_Order_CBCT_GP_Analysis,
  [StudyType.StudyType_IntraoralXRay]:
    ServiceType.ServiceType_Order_IOXRay_GP_Analysis,
  [StudyType.StudyType_PanoramicXRay]:
    ServiceType.ServiceType_Order_Panoramic_GP_Analysis,
  [StudyType.StudyType_IOS_Meshes]:
    ServiceType.ServiceType_Order_CBCT_STL_Analysis,
  [StudyType.StudyType_DentalPhoto]:
    ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis,
};

export type AssetToServiceUploadMap = {
  [AssetType.AssetType_Study_CBCT]: ServiceType.ServiceType_Upload_CBCT;
  [AssetType.AssetType_Study_IntraoralXRay]: ServiceType.ServiceType_Upload_IOXRay;
  [AssetType.AssetType_Study_PanoramicXRay]: ServiceType.ServiceType_Upload_Panorama;
  [AssetType.AssetType_Document]: ServiceType.ServiceType_Upload_Documents;
  [AssetType.AssetType_Study_DentalPhoto]: ServiceType.ServiceType_Upload_DentalPhoto;
  [AssetType.AssetType_Study_IOS_Meshes]: ServiceType.ServiceType_Upload_STL;
};

export const ASSET_TO_SERVICE_UPLOAD_MAP: AssetToServiceUploadMap = {
  [AssetType.AssetType_Study_CBCT]: ServiceType.ServiceType_Upload_CBCT,
  [AssetType.AssetType_Study_IntraoralXRay]:
    ServiceType.ServiceType_Upload_IOXRay,
  [AssetType.AssetType_Study_PanoramicXRay]:
    ServiceType.ServiceType_Upload_Panorama,
  [AssetType.AssetType_Document]: ServiceType.ServiceType_Upload_Documents,
  [AssetType.AssetType_Study_DentalPhoto]:
    ServiceType.ServiceType_Upload_DentalPhoto,
  [AssetType.AssetType_Study_IOS_Meshes]: ServiceType.ServiceType_Upload_STL,
};

export type AssetToServiceOrderReportMap = {
  [AssetType.AssetType_Study_CBCT]: ServiceType.ServiceType_Order_CBCT_GP_Analysis;
  [AssetType.AssetType_Study_IntraoralXRay]: ServiceType.ServiceType_Order_IOXRay_GP_Analysis;
  [AssetType.AssetType_Study_PanoramicXRay]: ServiceType.ServiceType_Order_Panoramic_GP_Analysis;
};

export const ASSET_TO_SERVICE_ORDER_REPORT_MAP: AssetToServiceOrderReportMap = {
  [AssetType.AssetType_Study_CBCT]:
    ServiceType.ServiceType_Order_CBCT_GP_Analysis,
  [AssetType.AssetType_Study_IntraoralXRay]:
    ServiceType.ServiceType_Order_IOXRay_GP_Analysis,
  [AssetType.AssetType_Study_PanoramicXRay]:
    ServiceType.ServiceType_Order_Panoramic_GP_Analysis,
};
