// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Attribute, Created, Deleted, Revision } from "./dto_base_pb.js";
import { AssetContent_Document } from "./dto_asset_document_pb.js";
import { MedicalImageFeatures } from "./dto_common_image_medical_features_pb.js";
import { EmptyPayload, ReportLegacyPayload } from "./dto_legacy_pb.js";
import { AssetContent_Generated_CBCT_GP_MPR_SubVolume, AssetContent_Generated_CBCT_GP_PanoramaReformatGeneral, AssetContent_Generated_CBCT_GP_PanoramaReformatSplit, AssetContent_Generated_CBCT_GP_PeriodontiumImage, AssetContent_Generated_CBCT_GP_ToothSlice } from "./dto_asset_cbct_gp_pb.js";
import { AssetContent_Generated_CBCT_Endo_CrossSection, AssetContent_Generated_CBCT_Endo_Guide, AssetContent_Generated_CBCT_Endo_PanoramaSplit } from "./dto_asset_cbct_endo_pb.js";
import { AssetContent_Generated_CBCT_Implant_Panorama, AssetContent_Generated_CBCT_Implant_Slice } from "./dto_asset_cbct_implant_pb.js";
import { AssetContent_Generated_CBCT_Molar_CrossSection, AssetContent_Generated_CBCT_Molar_Guide, AssetContent_Generated_CBCT_Molar_Panorama } from "./dto_asset_cbct_molar_pb.js";
import { AssetContent_Generated_Mesh_Model } from "./dto_asset_mesh_model_pb.js";
import { AssetContent_Generated_Mesh_FaceSegmentation } from "./dto_asset_mesh_face_segmentation_pb.js";
import { AssetContent_Generated_CBCTTile, AssetContent_Report_Studio_ApplicationStateSnapshot, AssetContent_Report_Studio_Geometry } from "./dto_asset_segmentron_common_pb.js";
import { AssetContent_Generated_Studio_CBCTDownscaled, AssetContent_Generated_Studio_PanoramaReformatGeneral } from "./dto_asset_studio_common_pb.js";
import { AssetContent_Report_UserUploaded_MPRPlane, AssetContent_Report_UserUploaded_ToothSlice } from "./dto_asset_report_uploaded_pb.js";
import { CBCT_SectionOrientation, DerivedData_3DModel, DerivedData_Image, File } from "./dto_asset_common_pb.js";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { ClosedPolygon } from "./dto_common_geometry_pb.js";

/**
 * @generated from enum com.diagnocat.model.AssetType
 */
export const AssetType = proto3.makeEnum(
  "com.diagnocat.model.AssetType",
  [
    {no: 0, name: "AssetType_InvalidValue"},
    {no: 1, name: "AssetType_User_Avatar"},
    {no: 2, name: "AssetType_User_Signature"},
    {no: 11, name: "AssetType_Organization_Logo"},
    {no: 12, name: "AssetType_Organization_Stamp"},
    {no: 13, name: "AssetType_Organization_EmployeeStamp"},
    {no: 21, name: "AssetType_Document"},
    {no: 31, name: "AssetType_Study_CBCT"},
    {no: 32, name: "AssetType_Study_IntraoralXRay"},
    {no: 33, name: "AssetType_Study_PanoramicXRay"},
    {no: 34, name: "AssetType_Study_IOS_Meshes"},
    {no: 35, name: "AssetType_Study_DentalPhoto"},
    {no: 40, name: "AssetType_Report_Generated_IOXRayImage"},
    {no: 41, name: "AssetType_Report_Generated_CBCT_ToothPathologySlice"},
    {no: 51, name: "AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit"},
    {no: 52, name: "AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral"},
    {no: 53, name: "AssetType_Report_Generated_CBCT_GP_ToothSlice"},
    {no: 54, name: "AssetType_Report_Generated_CBCT_GP_MPRSubVolume"},
    {no: 55, name: "AssetType_Report_Generated_CBCT_GP_PeriodontiumImage"},
    {no: 61, name: "AssetType_Report_Generated_CBCT_Endo_PanoramaSplit"},
    {no: 62, name: "AssetType_Report_Generated_CBCT_Endo_CrossSection"},
    {no: 63, name: "AssetType_Report_Generated_CBCT_Endo_Guide"},
    {no: 71, name: "AssetType_Report_Generated_CBCT_Implant_Panorama"},
    {no: 72, name: "AssetType_Report_Generated_CBCT_Implant_Slice"},
    {no: 81, name: "AssetType_Report_Generated_CBCT_Molar_Panorama"},
    {no: 82, name: "AssetType_Report_Generated_CBCT_Molar_Guide"},
    {no: 83, name: "AssetType_Report_Generated_CBCT_Molar_CrossSection"},
    {no: 91, name: "AssetType_Report_Generated_CBCT_Ortho_PDF"},
    {no: 111, name: "AssetType_Report_Generated_Mesh_Model"},
    {no: 112, name: "AssetType_Report_Generated_CBCTTile"},
    {no: 113, name: "AssetType_Report_Generated_Studio_CBCTDownscaled"},
    {no: 114, name: "AssetType_Report_Generated_Studio_PanoramaReformatGeneral"},
    {no: 115, name: "AssetType_Report_Generated_Mesh_FaceSegmentation"},
    {no: 121, name: "AssetType_Report_Uploaded_ToothSlice"},
    {no: 122, name: "AssetType_Report_Uploaded_MPRPlane"},
    {no: 131, name: "AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot"},
    {no: 132, name: "AssetType_Report_Uploaded_Studio_Geometry"},
    {no: 133, name: "AssetType_Report_Uploaded_DisplayAsset"},
    {no: 10000, name: "AssetType_Report_Archive"},
  ],
);

/**
 * Asset is a group of logically connected `Files`
 * no `File` could exists without `Asset`
 *
 * @generated from message com.diagnocat.model.Asset
 */
export const Asset = proto3.makeMessageType(
  "com.diagnocat.model.Asset",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UploadSessionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "Type", kind: "enum", T: proto3.getEnumType(AssetType) },
    { no: 101, name: "User", kind: "message", T: UserAsset, oneof: "Kind" },
    { no: 102, name: "Organization", kind: "message", T: OrganizationAsset, oneof: "Kind" },
    { no: 103, name: "Patient", kind: "message", T: PatientAsset, oneof: "Kind" },
    { no: 104, name: "Study", kind: "message", T: StudyAsset, oneof: "Kind" },
    { no: 105, name: "GeneratedReport", kind: "message", T: GeneratedReportAsset, oneof: "Kind" },
    { no: 106, name: "Report", kind: "message", T: ReportAsset, oneof: "Kind" },
    { no: 1000, name: "Attributes", kind: "message", T: Attribute, repeated: true },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * @generated from message com.diagnocat.model.UserAsset
 */
export const UserAsset = proto3.makeMessageType(
  "com.diagnocat.model.UserAsset",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Avatar", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
    { no: 102, name: "Signature", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrganizationAsset
 */
export const OrganizationAsset = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationAsset",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Logo", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
    { no: 102, name: "Stamp", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
    { no: 103, name: "EmployeeStamp", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.model.PatientAsset
 */
export const PatientAsset = proto3.makeMessageType(
  "com.diagnocat.model.PatientAsset",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Document", kind: "message", T: AssetContent_Document, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.model.StudyAsset
 */
export const StudyAsset = proto3.makeMessageType(
  "com.diagnocat.model.StudyAsset",
  () => [
    { no: 1, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "CBCT", kind: "message", T: AssetContent_Study_CBCT, oneof: "Content" },
    { no: 102, name: "Panoramic_XRay", kind: "message", T: AssetContent_Study_Panoramic_XRay, oneof: "Content" },
    { no: 103, name: "Intraoral_XRay", kind: "message", T: AssetContent_Study_Intraoral_XRay, oneof: "Content" },
    { no: 104, name: "IOS_Meshes", kind: "message", T: AssetContent_Study_IOS_Meshes, oneof: "Content" },
    { no: 105, name: "Dental_Photo", kind: "message", T: AssetContent_Study_Dental_Photo, oneof: "Content" },
    { no: 106, name: "Mesh_Model", kind: "message", T: AssetContent_Study_MeshModel, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.model.GeneratedReportAsset
 */
export const GeneratedReportAsset = proto3.makeMessageType(
  "com.diagnocat.model.GeneratedReportAsset",
  () => [
    { no: 1, name: "Belonging", kind: "message", T: ReportAssetBelonging },
    { no: 11, name: "MedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
    { no: 50, name: "LegacyPayloadOnly", kind: "message", T: EmptyPayload, oneof: "Content" },
    { no: 51, name: "IOXRay_Image", kind: "message", T: AssetContent_Generated_IOXRay_Image, oneof: "Content" },
    { no: 101, name: "CBCT_GP_PanoramaReformatSplit", kind: "message", T: AssetContent_Generated_CBCT_GP_PanoramaReformatSplit, oneof: "Content" },
    { no: 102, name: "CBCT_GP_PanoramaReformatGeneral", kind: "message", T: AssetContent_Generated_CBCT_GP_PanoramaReformatGeneral, oneof: "Content" },
    { no: 103, name: "CBCT_GP_ToothSlice", kind: "message", T: AssetContent_Generated_CBCT_GP_ToothSlice, oneof: "Content" },
    { no: 104, name: "CBCT_GP_MPR_SubVolume", kind: "message", T: AssetContent_Generated_CBCT_GP_MPR_SubVolume, oneof: "Content" },
    { no: 105, name: "CBCT_GP_PeriodontiumImage", kind: "message", T: AssetContent_Generated_CBCT_GP_PeriodontiumImage, oneof: "Content" },
    { no: 111, name: "CBCT_Endo_PanoramaSplit", kind: "message", T: AssetContent_Generated_CBCT_Endo_PanoramaSplit, oneof: "Content" },
    { no: 112, name: "CBCT_Endo_CrossSection", kind: "message", T: AssetContent_Generated_CBCT_Endo_CrossSection, oneof: "Content" },
    { no: 113, name: "CBCT_Endo_Guide", kind: "message", T: AssetContent_Generated_CBCT_Endo_Guide, oneof: "Content" },
    { no: 121, name: "CBCT_Implant_Panorama", kind: "message", T: AssetContent_Generated_CBCT_Implant_Panorama, oneof: "Content" },
    { no: 122, name: "CBCT_Implant_Slice", kind: "message", T: AssetContent_Generated_CBCT_Implant_Slice, oneof: "Content" },
    { no: 131, name: "CBCT_Molar_Panorama", kind: "message", T: AssetContent_Generated_CBCT_Molar_Panorama, oneof: "Content" },
    { no: 132, name: "CBCT_Molar_Guide", kind: "message", T: AssetContent_Generated_CBCT_Molar_Guide, oneof: "Content" },
    { no: 133, name: "CBCT_Molar_CrossSection", kind: "message", T: AssetContent_Generated_CBCT_Molar_CrossSection, oneof: "Content" },
    { no: 141, name: "CBCT_Ortho_PDF", kind: "message", T: AssetContent_Generated_CBCT_Ortho_PDF, oneof: "Content" },
    { no: 161, name: "Mesh_Model", kind: "message", T: AssetContent_Generated_Mesh_Model, oneof: "Content" },
    { no: 162, name: "Mesh_FaceSegmentation", kind: "message", T: AssetContent_Generated_Mesh_FaceSegmentation, oneof: "Content" },
    { no: 171, name: "CBCT_ToothPathologySlice", kind: "message", T: AssetContent_Generated_CBCT_ToothPathologySlice, oneof: "Content" },
    { no: 181, name: "CBCTTile", kind: "message", T: AssetContent_Generated_CBCTTile, oneof: "Content" },
    { no: 182, name: "Studio_CBCTDownscaled", kind: "message", T: AssetContent_Generated_Studio_CBCTDownscaled, oneof: "Content" },
    { no: 183, name: "Studio_PanoramaReformatGeneral", kind: "message", T: AssetContent_Generated_Studio_PanoramaReformatGeneral, oneof: "Content" },
    { no: 1000, name: "LegacyPayloads", kind: "message", T: ReportLegacyPayload, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportAsset
 */
export const ReportAsset = proto3.makeMessageType(
  "com.diagnocat.model.ReportAsset",
  () => [
    { no: 1, name: "Belonging", kind: "message", T: ReportAssetBelonging },
    { no: 11, name: "MedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
    { no: 101, name: "UserUploadedToothSlice", kind: "message", T: AssetContent_Report_UserUploaded_ToothSlice, oneof: "Content" },
    { no: 102, name: "UserUploadedMPRPlane", kind: "message", T: AssetContent_Report_UserUploaded_MPRPlane, oneof: "Content" },
    { no: 111, name: "Studio_ApplicationStateSnapshot", kind: "message", T: AssetContent_Report_Studio_ApplicationStateSnapshot, oneof: "Content" },
    { no: 112, name: "Studio_Geometry", kind: "message", T: AssetContent_Report_Studio_Geometry, oneof: "Content" },
    { no: 113, name: "DisplayAsset", kind: "message", T: AssetContent_SimpleImage, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportAssetBelonging
 */
export const ReportAssetBelonging = proto3.makeMessageType(
  "com.diagnocat.model.ReportAssetBelonging",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SourceStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_CBCT
 */
export const AssetContent_Study_CBCT = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_CBCT",
  () => [
    { no: 1, name: "DICOMFiles", kind: "message", T: File, repeated: true },
    { no: 2, name: "CBCTArchive", kind: "message", T: File },
    { no: 3, name: "Metadata", kind: "message", T: AssetContent_Study_CBCT_CBCT_Metadata },
    { no: 4, name: "SeriesUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_CBCT.CBCT_Metadata
 */
export const AssetContent_Study_CBCT_CBCT_Metadata = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_CBCT.CBCT_Metadata",
  () => [
    { no: 12, name: "StudyTime", kind: "message", T: Timestamp },
    { no: 2, name: "InternalStudyUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "InternalSeriesUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "InternalInstanceUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ScanUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Manufacturer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "ManufacturerModelName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "SliceThickness", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "FOV", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "VoxelSize", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 11, name: "Exposure", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AssetContent_Study_CBCT_CBCT_Metadata"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_IOS_Meshes
 */
export const AssetContent_Study_IOS_Meshes = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_IOS_Meshes",
  () => [
    { no: 1, name: "Jaws", kind: "message", T: File, repeated: true },
    { no: 100, name: "Derived", kind: "message", T: DerivedData_3DModel },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_MeshModel
 */
export const AssetContent_Study_MeshModel = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_MeshModel",
  () => [
    { no: 1, name: "Geometry", kind: "message", T: File },
    { no: 2, name: "MTL", kind: "message", T: File, opt: true },
    { no: 3, name: "Textures", kind: "message", T: File, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_Panoramic_XRay
 */
export const AssetContent_Study_Panoramic_XRay = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_Panoramic_XRay",
  () => [
    { no: 1, name: "PanoramaImage", kind: "message", T: File },
    { no: 100, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_Intraoral_XRay
 */
export const AssetContent_Study_Intraoral_XRay = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_Intraoral_XRay",
  () => [
    { no: 1, name: "Image", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Study_Dental_Photo
 */
export const AssetContent_Study_Dental_Photo = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Study_Dental_Photo",
  () => [
    { no: 1, name: "Image", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * //////////////////////////////////////////////////////////////
 * // ToothPathologySliceImage
 * //
 *
 * Caries or Perio sections
 * From GP:
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-axial", "filename": "/tmp/tmpghcgox0u", "content_type": "application/dicom", "size": 16162, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-axial", "pathology_component": 0, "image_height": 86, "image_width": 88}, "type": "File"}, "event": "execution-event"}
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-axial-mask", "filename": "/tmp/tmp0o1l478s", "content_type": "application/dicom", "size": 16146, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-axial", "pathology_component": 0, "image_height": 86, "image_width": 88}, "type": "File"}, "event": "execution-event"}
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-coronal", "filename": "/tmp/tmpj_ol9kg5", "content_type": "application/dicom", "size": 30786, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-coronal", "pathology_component": 0, "image_height": 160, "image_width": 93}, "type": "File"}, "event": "execution-event"}
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-coronal-mask", "filename": "/tmp/tmpos5fd_ec", "content_type": "application/dicom", "size": 30770, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-coronal", "pathology_component": 0, "image_height": 160, "image_width": 93}, "type": "File"}, "event": "execution-event"}
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-sagittal", "filename": "/tmp/tmp_yuvqh6x", "content_type": "application/dicom", "size": 25026, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-sagittal", "pathology_component": 0, "image_height": 160, "image_width": 75}, "type": "File"}, "event": "execution-event"}
 * {"type": "File", "file": {"task_id": "c373c589-d2f7-0b06-b578-f866ab61819e", "key": "17/caries-0-slice-sagittal-mask", "filename": "/tmp/tmpq1nsi4xt", "content_type": "application/dicom", "size": 25010, "meta": {"section": "caries", "condition": "caries-signs", "image_type": "slice-sagittal", "pathology_component": 0, "image_height": 160, "image_width": 75}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_ToothPathologySlice
 */
export const AssetContent_Generated_CBCT_ToothPathologySlice = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_ToothPathologySlice",
  () => [
    { no: 1, name: "Slice", kind: "message", T: File },
    { no: 2, name: "Orientation", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
    { no: 3, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 4, name: "Pathology", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType) },
    { no: 5, name: "PathologyComponent", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "Mask", kind: "message", T: ClosedPolygon },
    { no: 7, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from enum com.diagnocat.model.AssetContent_Generated_CBCT_ToothPathologySlice.PathologyType
 */
export const AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType = proto3.makeEnum(
  "com.diagnocat.model.AssetContent_Generated_CBCT_ToothPathologySlice.PathologyType",
  [
    {no: 0, name: "Caries"},
    {no: 1, name: "PeriapicalLesion"},
  ],
);

/**
 * //////////////////////////////////////////////////////////////
 * // Primary Assets
 * //
 *
 * @generated from message com.diagnocat.model.AssetContent_SimpleImage
 */
export const AssetContent_SimpleImage = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_SimpleImage",
  () => [
    { no: 1, name: "Original", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Ortho_PDF
 */
export const AssetContent_Generated_CBCT_Ortho_PDF = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Ortho_PDF",
  () => [
    { no: 1, name: "PDF", kind: "message", T: File },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_IOXRay_Image
 */
export const AssetContent_Generated_IOXRay_Image = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_IOXRay_Image",
  () => [
    { no: 1, name: "Image", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

