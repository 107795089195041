import { CSSProperties, FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import {
  ErrorCode,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import {
  ToastErrors,
  toastErrors,
  UPLOADING_IMAGE_MAX_SIZE,
} from '@/shared/config';
import { toastCaller } from '@/shared/ui';

import { useCheckReportSignature } from '@/entities/reports';

import { useUploadAssetContext } from '@/features/uploadAsset';

import styles from './UploadSlicesDropzone.module.scss';

type UploadSlicesDropzoneProps = {
  toothID: string;
  className?: string;
  testID?: string;
};

export const UploadSlicesDropzone: FC<UploadSlicesDropzoneProps> = (props) => {
  const { className, testID, toothID } = props;

  const { formatMessage } = useIntl();
  const { startUploadAsset } = useUploadAssetContext();
  const { checkReportSignature } = useCheckReportSignature();

  const handleAcceptedFiles = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      checkReportSignature({
        toothID,
        onSignatureChecked: async () => {
          await startUploadAsset(
            {
              files: acceptedFiles,
              assetType: AssetType.AssetType_Report_Uploaded_ToothSlice,
              toothID,
            },
            true,
          );
        },
      });
    },
    [toothID, startUploadAsset],
  );

  const handleRejectFile = useCallback(
    (fileRejections: FileRejection[]) => {
      let errorHeading: string;
      let errorMessage: string;
      // TODO: [2|h] need to update some messages
      if (fileRejections.length === 1) {
        const errorCode = fileRejections?.at(0)?.errors?.at(0)?.code;

        if (errorCode === ErrorCode.FileInvalidType) {
          errorHeading = formatMessage(toastErrors[ToastErrors.wrongFormat]);
          errorMessage = formatMessage({
            id: 'uploadImage.wrongFileFormat',
            defaultMessage:
              'The selected file format is not supported. Please upload a file in JPEG, JPG, or PNG format.',
          });
        } else {
          errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);
          errorMessage = formatMessage({
            id: 'uploadImage.largeFile',
            defaultMessage:
              'The file size exceeds the limit. The maximum allowable size is 5 MB.',
          });
        }
      } else {
        errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);
        errorMessage = formatMessage({
          id: 'uploadImage.wrongFile',
          defaultMessage: 'Some files are too large or incorrect format',
        });
      }

      // NOTE: Special errors for "react-dropzone"
      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: errorMessage,
        autoClose: 10000,
      });
    },
    [formatMessage],
  );

  const { isFocused, isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      maxSize: UPLOADING_IMAGE_MAX_SIZE,
      maxFiles: 50,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg'],
        'image/jpg': ['.jpg'],
      },
      onDropAccepted: handleAcceptedFiles,
      onDropRejected: handleRejectFile,
    });

  const style = useMemo(
    () =>
      ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        padding: '24px',
        border: '1px dashed',
        borderColor: 'var(--purpleflat)',
        borderRadius: '12px',
        transition: 'all var(--transition-time) ease-in-out',
        cursor: 'pointer',
        willChange: 'color, border, background-color',
        ...(isFocused
          ? {
              outline: 'none',
              backgroundColor: 'var(--transparentlavand)',
            }
          : {}),
        ...(isDragAccept
          ? {
              borderColor: 'var(--purpleflat)',
              backgroundColor: 'rgba(var(--purpleflat-rgb), .1)',
            }
          : {}),
        ...(isDragReject
          ? {
              borderColor: 'var(--redrose)',
              backgroundColor: 'rgba(var(--redrose-rgb), .1)',
            }
          : {}),
      }) as CSSProperties,
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div
      {...getRootProps({ style })}
      data-testid={testID}
      className={className}
    >
      <input {...getInputProps()} />

      <p className={cn(styles.text, isDragReject && styles.dragReject, 'p2')}>
        {isDragReject
          ? formatMessage({
              id: 'tooth.uploadedSlice.notValidFileType',
              defaultMessage:
                'This file type is not allowed or size is too big',
            })
          : formatMessage({
              id: 'tooth.uploadedSlice.dropzoneText',
              defaultMessage: 'Upload new slices or photos',
            })}
      </p>
    </div>
  );
};
