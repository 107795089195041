import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { userModel } from '@/entities/user';

import styles from './ThemeSelector.module.scss';

type ThemeSelectorProps = {
  className?: string;
  testID?: string;
};

export const ThemeSelector: FC<ThemeSelectorProps> = (props) => {
  const { className, testID } = props;

  const dispatch = useAppDispatch();

  const { theme } = useAppSelector(userModel.selectors.selectSettings);

  const toggleTheme = () => {
    const isDarkTheme = theme === 'dark-theme';

    if (isDarkTheme) {
      document.documentElement.classList.remove('dark-theme');
      document.documentElement.classList.add('light-theme');
    } else {
      document.documentElement.classList.remove('light-theme');
      document.documentElement.classList.add('dark-theme');
    }

    dispatch(
      userModel.thunks.setSettings({
        theme: isDarkTheme ? 'light-theme' : 'dark-theme',
      }),
    );
  };

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      {theme === 'light-theme' ? (
        <Icon className={styles.icon} name="moon" onClick={toggleTheme} />
      ) : (
        <Icon className={styles.icon} name="sun" onClick={toggleTheme} />
      )}
    </div>
  );
};
