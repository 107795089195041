import { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './PanoImageSlider.module.scss';

type PanoImageSliderProps = {
  className?: string;
  reportID: string;
};

export const PanoImageSlider: FC<PanoImageSliderProps> = (props) => {
  const { className, reportID } = props;

  const dispatch = useAppDispatch();

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID as string,
    ),
  ) as IOXrayImageInterface;

  const panoImageMetaID =
    panoImageInterface?.imageMeta.GeneratedAssetID ||
    panoImageInterface?.imageMeta.StudyAssetID;

  const teethIDs = useAppSelector(
    toothModel.selectors.selectTeethIDsToShow(reportID as string),
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const updateActiveToothID = (newToothID: string) => {
    MedicalImageRender.activateMode('mainMode');

    if (newToothID) {
      MedicalImageRender.layoutModes.crop(newToothID);
    } else if (panoImageMetaID) {
      MedicalImageRender.layoutModes.focus(panoImageMetaID!);
    }

    dispatch(reportsModel.actions.setToolbarActiveControl('view'));
    dispatch(reportsModel.actions.setActiveToothID(newToothID));
  };

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={teethIDs}
      activeItemID={activeToothID}
      className={cn(styles.container, className)}
    />
  );
};
