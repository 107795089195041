// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_segmentron_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { BBox, ClosedPolygon, PlanePosition, ProjectionTransform } from "./dto_common_geometry_pb.js";
import { JawType } from "./dto_report_common_pb.js";

/**
 * note: this is emitted only if dental photo was passed as report study
 *
 * @generated from message com.diagnocat.model.Segmentron_PhotoIOSSuperimposition
 */
export const Segmentron_PhotoIOSSuperimposition = proto3.makeMessageType(
  "com.diagnocat.model.Segmentron_PhotoIOSSuperimposition",
  () => [
    { no: 1, name: "PhotoAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Mask", kind: "message", T: ClosedPolygon },
    { no: 3, name: "FocalLength", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "ViewToCbctTransform", kind: "message", T: ProjectionTransform },
    { no: 5, name: "BBox", kind: "message", T: BBox },
    { no: 6, name: "PlanePosition", kind: "message", T: PlanePosition },
  ],
);

/**
 * @generated from message com.diagnocat.model.IOSJawTypeClassification
 */
export const IOSJawTypeClassification = proto3.makeMessageType(
  "com.diagnocat.model.IOSJawTypeClassification",
  () => [
    { no: 1, name: "TargetAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Type", kind: "enum", T: proto3.getEnumType(JawType) },
  ],
);

