import {
  CIS_BILLING_ZONES,
  GERMANY_COUNTRY_EXCEPTION,
  USER_AGREEMENTS_LINKS,
} from '../config';
import { BillingZoneParams, LinksAgreementsType } from '../config/types';

export const getLinksAgreementsByBillingZone = ({
  billingZone,
  country,
}: BillingZoneParams): LinksAgreementsType => {
  if (GERMANY_COUNTRY_EXCEPTION.includes(country)) {
    return {
      termsAndConditions: USER_AGREEMENTS_LINKS.termsAndConditions,
      dataProcessingAddendum:
        USER_AGREEMENTS_LINKS.dataProcessingAddendumInGermany,
      privacyPolicy: USER_AGREEMENTS_LINKS.privacyPolicyInGermany,
      agreementOffer: '',
      personalDataProcessing: '',
    };
  }

  if (CIS_BILLING_ZONES.includes(billingZone)) {
    return {
      agreementOffer: USER_AGREEMENTS_LINKS.agreementOfferInRussian,
      personalDataProcessing:
        USER_AGREEMENTS_LINKS.personalDataProcessingInRussian,
      termsAndConditions: '',
      dataProcessingAddendum: '',
      privacyPolicy: '',
    };
  }

  // default
  return {
    termsAndConditions: USER_AGREEMENTS_LINKS.termsAndConditions,
    dataProcessingAddendum:
      USER_AGREEMENTS_LINKS.dataProcessingAddendumInEnglish,
    privacyPolicy: USER_AGREEMENTS_LINKS.privacyPolicyInEnglish,
    agreementOffer: '',
    personalDataProcessing: '',
  };
};
