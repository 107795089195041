import { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { scrollToElementByID } from '@/shared/lib';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './IOXRayImageSlider.module.scss';

type IOXRayImageSliderProps = {
  className?: string;
};

export const IOXRayImageSlider: FC<IOXRayImageSliderProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const reportID = currentReport?.ID;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID as string,
    ),
  );

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const imageMetaIDs = Object.values(groupedIOXRayImages).flatMap(
    (imageItems) =>
      imageItems.map(
        (imageItem) =>
          imageItem.imageMeta?.GeneratedAssetID ||
          imageItem.imageMeta?.StudyAssetID,
      ),
  );

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectTeethIDsToShow(reportID as string),
  );

  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  // select data by navigation mode
  const isMatrixMode = navigationMode === FMXNavigationMode.MatrixView;
  const itemIDs = isMatrixMode ? imageMetaIDs : teethIDs;
  const activeItemID = isMatrixMode ? focusedImageMetaID : activeToothID;

  const singleImage = IOXRayImagesInterface.length === 1;

  const updateActiveToothID = (newItemID: string) => {
    MedicalImageRender.activateMode('mainMode');

    if (newItemID) {
      MedicalImageRender.layoutModes.mode === 'crop'
        ? MedicalImageRender.layoutModes.crop(newItemID)
        : MedicalImageRender.layoutModes.focus(newItemID);
    } else {
      MedicalImageRender.layoutModes.matrix();
    }

    dispatch(reportsModel.actions.setToolbarActiveControl('view'));

    if (isMatrixMode) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));
    } else {
      dispatch(reportsModel.actions.setActiveToothID(newItemID));

      // NOTE: It needs to properly scroll to the tooth card which appears in the DOM later than this function executes if it's just a sync call.
      setTimeout(() => scrollToElementByID(newItemID, 'smooth', 'start'), 0);

      if (newItemID === '') {
        dispatch(
          reportsModel.actions.setNavigationMode(FMXNavigationMode.MatrixView),
        );

        dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));
      }
    }
  };

  if (singleImage && isMatrixMode) {
    return null;
  }

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={itemIDs as string[]}
      activeItemID={activeItemID}
      className={cn(styles.container, className)}
    />
  );
};
