import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Checkbox, Icon, Input, Modal, Textarea } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';

import { ProtectedSharingModal } from '../ProtectedSharingModal/ProtectedSharingModal';
import { sharePatientFormSchema } from '../../config/schema';
import { SharePatientFormPayloadType } from '../../config/types';
import { useInviteForSharingPatient } from '../../hooks/useInviteForSharingPatient';

import styles from './SharePatientModalForm.module.scss';

export const SharePatientModalForm: FC = () => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    visible,
    data: { patientID },
  } = useAppSelector((state) => state.modal.SharePatient);

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isSubmitting },
  } = useForm<SharePatientFormPayloadType>({
    resolver: yupResolver(sharePatientFormSchema),
    mode: 'all',
  });

  const inviteForSharingPatient = useInviteForSharingPatient();

  const handleClose = () => {
    reset();

    dispatch(modalModel.actions.closeModal(ModalID.SharePatient));
  };

  const onSubmit: SubmitHandler<SharePatientFormPayloadType> = async (data) => {
    try {
      await inviteForSharingPatient({
        patientID,
        sharedPatientDoctorEmail: data.email,
        comment: data?.comment ?? '',
      });
    } catch (error) {
      // TODO: [1/l]  Investigate reason of this behaviour
      // We need a catch for useForm's isSubmitting to work correctly
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        title={
          <FormattedMessage
            id="sharePatient.title"
            defaultMessage="Share patient case"
          />
        }
        isOpen={visible}
        onCancel={handleClose}
        className={styles.modal}
        bodyClassName={styles.modalBody}
        containerClassName={styles.modalContainer}
        okButtonText={formatMessage({
          id: 'sharePatient.shareCase',
          defaultMessage: 'Share case',
        })}
        okButtonProps={{
          type: 'submit',
          form: 'sharePatientForm',
          loading: isSubmitting,
        }}
      >
        <div className={styles.alertInfo}>
          <Icon name="alert" size={24} className={styles.alertIcon} />

          <span className="p2">
            <FormattedMessage
              id="sharePatient.alertInfo"
              defaultMessage="You can only share patient information with dental professionals,
          which will have access to the patient's studies and reports.Enter the
          doctor's email"
            />
          </span>
        </div>

        <form
          id="sharePatientForm"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.sharePatientForm}
        >
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error, isTouched },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                inputMode="email"
                type="email"
                onBlur={onBlur}
                onChange={(event) => {
                  if (!isTouched) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                label={formatMessage({
                  id: 'global.email',
                  defaultMessage: 'Email',
                })}
                error={error?.message}
                autoComplete="email"
              />
            )}
          />

          <Controller
            control={control}
            name="comment"
            render={({ field: { value, onBlur, onChange } }) => (
              <Textarea
                value={value ?? ''}
                placeholder={formatMessage({
                  id: 'sharePatient.leaveComment',
                  defaultMessage: 'Leave a comment for the doctor...',
                })}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />

          <Controller
            control={control}
            name="patientCaseSharingConfirmation"
            render={({
              field: { value = false, onChange, name },
              fieldState: { error },
            }) => (
              <Checkbox
                checked={value}
                onChange={onChange}
                name={name}
                error={error?.message}
                required
              >
                <span className={styles.text}>
                  <FormattedMessage
                    id="sharePatient.patientCaseSharingConfirmation"
                    defaultMessage="I confirm that I share patient case to a dental professional"
                  />
                </span>
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="patientDataSharingConsentConfirmation"
            render={({
              field: { value = false, onChange, name },
              fieldState: { error },
            }) => (
              <Checkbox
                checked={value}
                onChange={onChange}
                name={name}
                error={error?.message}
                required
              >
                <span className={styles.text}>
                  <FormattedMessage
                    id="sharePatient.patientDataSharingConsentConfirmation"
                    defaultMessage="I confirm that I received permission from the patient to share patient’s personal data in Diagnocat to the dental professional specified above"
                  />
                </span>
              </Checkbox>
            )}
          />
        </form>
      </Modal>

      <ProtectedSharingModal />
    </>
  );
};
