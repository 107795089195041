/**
 * MPR2 Library
 * Version: 1.9.0
 * Commit: 471cc3c6782a2c18819a745c007eb294cb76203b
 */

// studio/src/utils/QuaternionLookRotation.ts
import {Quaternion, Vector3} from "three";
Quaternion.prototype.setFromLookRotation = function(forward, up) {
  const zAxis = forward.clone().normalize();
  const xAxis = new Vector3().crossVectors(up, zAxis).normalize();
  const yAxis = new Vector3().crossVectors(zAxis, xAxis);
  const m00 = xAxis.x, m01 = yAxis.x, m02 = zAxis.x;
  const m10 = xAxis.y, m11 = yAxis.y, m12 = zAxis.y;
  const m20 = xAxis.z, m21 = yAxis.z, m22 = zAxis.z;
  const trace = m00 + m11 + m22;
  if (trace > 0) {
    const s = 0.5 / Math.sqrt(trace + 1);
    this.w = 0.25 / s;
    this.x = (m21 - m12) * s;
    this.y = (m02 - m20) * s;
    this.z = (m10 - m01) * s;
  } else if (m00 > m11 && m00 > m22) {
    const s = 2 * Math.sqrt(1 + m00 - m11 - m22);
    this.w = (m21 - m12) / s;
    this.x = 0.25 * s;
    this.y = (m01 + m10) / s;
    this.z = (m02 + m20) / s;
  } else if (m11 > m22) {
    const s = 2 * Math.sqrt(1 + m11 - m00 - m22);
    this.w = (m02 - m20) / s;
    this.x = (m01 + m10) / s;
    this.y = 0.25 * s;
    this.z = (m12 + m21) / s;
  } else {
    const s = 2 * Math.sqrt(1 + m22 - m00 - m11);
    this.w = (m10 - m01) / s;
    this.x = (m02 + m20) / s;
    this.y = (m12 + m21) / s;
    this.z = 0.25 * s;
  }
  return this.normalize();
};
// studio/src/utils/Slot.ts
function slot() {
  let subscriber = null;
  function slotFunction(args) {
    if (subscriber) {
      return subscriber(args);
    }
    return;
  }
  slotFunction.on = (callback) => {
    subscriber = callback;
    return function unsubscribe() {
      subscriber = null;
    };
  };
  return slotFunction;
}

// studio/src/core/controllers/ToolsController.ts
class ToolsController {
  hostObject;
  modes;
  serviceTools;
  tools;
  activeMode;
  enable;
  constructor(hostObject) {
    this.hostObject = hostObject;
    this.tools = new Map;
    this.enable = false;
  }
  init() {
    if (!this.enable) {
      this.enable = true;
      this.initServiceTools();
      this.activeMode?.init();
    }
  }
  dispose() {
    if (this.enable) {
      this.disposeServiceTools();
      this.tools?.forEach((tool) => tool.dispose());
      this.activeMode?.dispose();
      this.activeMode = undefined;
      this.enable = false;
    }
  }
  clear() {
    this.dispose();
    this.serviceTools = undefined;
    this.modes = undefined;
    this.tools.clear();
  }
  setServiceTools(toolTypes) {
    this.serviceTools = new Set;
    toolTypes.forEach((toolType) => this.serviceTools.add(new toolType(this.hostObject)));
  }
  initServiceTools() {
    this.serviceTools?.forEach((tool) => tool.init());
  }
  disposeServiceTools() {
    this.serviceTools?.forEach((tool) => tool.dispose());
  }
  setTools(tools) {
    let toolInstance;
    tools.forEach((tool) => {
      toolInstance = new tool(this.hostObject);
      if (!this.tools.has(tool)) {
        this.tools.set(tool, toolInstance);
      }
    });
  }
  getTools() {
    return this.tools;
  }
  getTool(name) {
    return this.tools?.get(name);
  }
  getToolByName(name) {
    for (const [key, value] of this.tools) {
      if (key.name === name)
        return value;
    }
  }
  setModes(modes) {
    if (!this.modes) {
      this.modes = modes;
      Object.keys(modes).forEach((modeName) => modes[modeName].setHostObject(this.hostObject, modeName));
    }
  }
  activateMode(name) {
    if (this.modes && this.activeMode !== this.modes[name]) {
      this.enable && this.modes[name].init();
      this.activeMode = this.modes[name];
    }
  }
  getModeName() {
    return this.activeMode?.getName();
  }
  getModes() {
    return this.modes;
  }
  getMode() {
    return this.activeMode;
  }
}

// studio/src/core/view/View.ts
import {Scene, WebGLRenderer} from "three";

// studio/src/core/Layer.ts
import {Group} from "three";

// studio/src/core/containers/Container.ts
class Container {
  objects;
  hostObject;
  constructor(hostObject, objects) {
    this.hostObject = hostObject;
    this.objects = objects;
  }
}

// studio/src/core/containers/LayerContainer.ts
class LayerContainer extends Container {
  objects;
  components;
  constructor(layer, objects) {
    super(layer, objects);
    this.objects = objects;
    this.components = new Map;
    this.deleteOne = this.deleteOne.bind(this);
    this.addOne = this.addOne.bind(this);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = entity.filter(this.addOne);
      if (added.length) {
        App2.Instance().dispatchEvent({ type: "add", entities: [...entity], container: this.hostObject });
      }
    } else {
      if (this.addOne(entity)) {
        App2.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter(this.deleteOne);
      if (deleted.length) {
        this.hostObject.getGroup().remove(...entity.map((item) => item.object3d));
        App2.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
      }
    } else {
      if (this.objects.includes(entity)) {
        if (this.deleteOne(entity)) {
          this.hostObject.getGroup().remove(entity.object3d);
          App2.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
        }
      }
    }
  }
  addOne(entity) {
    if (entity.getLayer() !== this.hostObject) {
      this.objects.push(entity);
      entity.setLayer(this.hostObject);
      this.hostObject.getGroup().add(entity.object3d);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.add(value);
        } else {
          this.components.set(key, new Set([value]));
        }
      });
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    const index = this.objects.indexOf(entity);
    if (index >= 0) {
      this.objects.splice(index, 1);
      entity.setLayer(undefined);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.delete(value);
        }
      });
      return true;
    }
    return false;
  }
}

// studio/src/core/Layer.ts
class Layer {
  name;
  objects;
  on;
  lock;
  view;
  group;
  container;
  constructor(view, name, objects = []) {
    this.objects = objects;
    this.on = true;
    this.lock = false;
    this.view = view;
    this.name = name;
    this.group = new Group;
    this.group.name = name;
    this.container = new LayerContainer(this, objects);
  }
  getGroup() {
    return this.group;
  }
  getObjects(force = false) {
    if (force || this.on && !this.lock) {
      return this.objects;
    }
    return [];
  }
  getObjectByName = (name, force = false) => {
    if (!(force || this.on && !this.lock))
      return;
    return this.container.objects.find((entity) => entity.object3d.name === name);
  };
  getComponent(type) {
    return this.container.components.get(type);
  }
  has(obj) {
    return this.objects.includes(obj);
  }
  add(object) {
    this.container.add(object);
  }
  delete(object) {
    this.container.delete(object);
  }
  clear() {
    this.objects.forEach((obj) => {
      obj.setLayer(undefined);
    });
    this.group.remove(...this.objects.map((item) => item.object3d));
    const tmpObjects = [...this.objects];
    this.objects.length = 0;
    this.container.components.clear();
    App2.Instance().dispatchEvent({ type: "delete", entities: tmpObjects, container: this });
  }
  hide() {
    this.group.visible = false;
  }
  show() {
    this.group.visible = true;
  }
  getName() {
    return this.name;
  }
  getView() {
    return this.view;
  }
}

// studio/src/core/view/Viewport.ts
import {Vector4} from "three";

// studio/src/core/controllers/CameraController.ts
import {OrthographicCamera, PerspectiveCamera, Vector3 as Vector33} from "three";

// studio/src/utils/cameraHelpers/CameraHelper.ts
class CameraHelper {
  cameraController;
  distanceFactor;
  constructor(cameraController) {
    this.cameraController = cameraController;
  }
  init() {
    this.handleResize();
    this.cameraController.viewport.addEventListener("resize", this.handleResize, 10);
  }
  dispose() {
    this.cameraController.viewport.removeEventListener("resize", this.handleResize);
  }
  getDistanceFactor() {
    return this.distanceFactor;
  }
  get viewport() {
    return this.cameraController.viewport;
  }
}

// studio/src/utils/cameraHelpers/OrthographicCameraHelper.ts
class OrthographicCameraHelper extends CameraHelper {
  frustumSize;
  sizeRatio;
  constructor(cameraController) {
    super(cameraController);
    this.frustumSize = 600;
    this.sizeRatio = 1;
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getOrthographicCamera();
  }
  getSizeRatio() {
    return this.sizeRatio;
  }
  handleResize = () => {
    const sizeRatio = OrthographicCameraHelper.setSize(this.camera, this.viewport.width, this.viewport.height, this.frustumSize);
    if (sizeRatio) {
      this.sizeRatio = sizeRatio;
    }
  };
  static setSize(camera, width, height, frustumSize) {
    if (width === 0 || height === 0) {
      return;
    }
    const sizeRatio = frustumSize / height;
    const aspect = width / height;
    camera.left = -0.5 * frustumSize * aspect;
    camera.right = 0.5 * frustumSize * aspect;
    camera.top = frustumSize / 2;
    camera.bottom = -frustumSize / 2;
    camera.updateProjectionMatrix();
    return sizeRatio;
  }
  updateDistanceFactor() {
    return this.distanceFactor = 1;
  }
}

// studio/src/utils/cameraHelpers/PerspectiveCameraHelper.ts
import {Vector3 as Vector32} from "three";
class PerspectiveCameraHelper extends CameraHelper {
  subCoefficient;
  offset = new Vector32;
  constructor(cameraController) {
    super(cameraController);
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getPerspectiveCamera();
  }
  handleResize = () => {
    this.camera.aspect = this.viewport.width / this.viewport.height;
    this.camera.updateProjectionMatrix();
    this.updateSubDistCoefficient();
    this.updateDistanceFactor();
  };
  updateSubDistCoefficient() {
    this.subCoefficient = 2 * Math.tan(this.camera.fov / 2 * Math.PI / 180) / this.viewport.height;
  }
  updateDistanceFactor() {
    return this.distanceFactor = this.offset.copy(this.cameraController.getPivotPoint()).sub(this.camera.position).length() * this.subCoefficient;
  }
}

// studio/src/core/controllers/CameraController.ts
var initialProps = {
  perspective: {},
  orthographic: { left: -1, right: 1, top: 1, bottom: -1, near: -1000, far: 1000 },
  zoom: 1,
  matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
  type: "camera-controller",
  key: "",
  up: [0, 1, 0],
  pivotPoint: [0, 0, 0]
};

class CameraController {
  viewport;
  orthographicCamera;
  perspectiveCamera;
  cameraX;
  cameraY;
  cameraZ;
  savedState;
  camera;
  cameraHelper;
  pivotPoint;
  perspectiveCameraHelper;
  orthographicCameraHelper;
  getDistanceFactor;
  constructor(viewport, props = initialProps) {
    this.viewport = viewport;
    var { fov, aspect, near, far } = props.perspective || {};
    this.perspectiveCamera = new PerspectiveCamera(fov, aspect, near, far);
    var { left, right, top, bottom, near, far } = props.orthographic || initialProps.orthographic;
    this.orthographicCamera = new OrthographicCamera(left, right, top, bottom, near, far);
    const matrix = props.matrix ? props.matrix : initialProps.matrix;
    this.perspectiveCamera.matrix.set(...matrix);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.matrix.set(...matrix);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    if (props.up) {
      this.perspectiveCamera.up.set(...props.up).normalize();
      this.orthographicCamera.up.set(...props.up).normalize();
    }
    this.pivotPoint = new Vector33;
    this.cameraX = new Vector33;
    this.cameraY = new Vector33;
    this.cameraZ = new Vector33;
    this.perspectiveCameraHelper = new PerspectiveCameraHelper(this);
    this.orthographicCameraHelper = new OrthographicCameraHelper(this);
    this.orthographic();
    this.onTransform = this.onTransform.bind(this);
  }
  init() {
    this.cameraHelper.init();
    App2.Instance().addEventListener("transformCamera", this.onTransform, 10);
  }
  onTransform(event) {
    if (event.viewport === this.viewport) {
      this.camera.updateMatrixWorld();
    }
  }
  updatePivotPoint(point, fireEvent = true) {
    this.pivotPoint.copy(point);
    this.viewport.dispatchEvent({ type: "updatePivotPoint", point: this.pivotPoint, fireEvent });
  }
  getPivotPoint() {
    return this.pivotPoint;
  }
  dispose() {
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.dispose();
  }
  saveState() {
    this.savedState = this.toJson();
  }
  setPosition(v, y, z) {
    this.camera.matrix.setPosition(v, y, z);
  }
  updateMatrix(matrix) {
    this.camera.matrix.copy(matrix);
    this.camera.matrixWorldNeedsUpdate = true;
  }
  orthographic() {
    this.camera = this.orthographicCamera;
    this.cameraHelper = this.orthographicCameraHelper;
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.init();
    this.getDistanceFactor = () => this.orthographicCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.perspectiveCamera,
      current: this.orthographicCamera
    });
  }
  perspective() {
    this.camera = this.perspectiveCamera;
    this.cameraHelper = this.perspectiveCameraHelper;
    this.orthographicCameraHelper.dispose();
    this.perspectiveCameraHelper.init();
    this.getDistanceFactor = () => this.perspectiveCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.orthographicCamera,
      current: this.perspectiveCamera
    });
  }
  copyFromActive() {
    const camera = this.camera === this.orthographicCamera ? this.perspectiveCamera : this.orthographicCamera;
    camera.matrix.copy(this.camera.matrix);
    camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
    camera.layers = this.camera.layers;
  }
  getPerspectiveCamera() {
    return this.perspectiveCamera;
  }
  getOrthographicCamera() {
    return this.orthographicCamera;
  }
  get xDir() {
    return this.cameraX.setFromMatrixColumn(this.camera.matrix, 0);
  }
  get yDir() {
    return this.cameraY.setFromMatrixColumn(this.camera.matrix, 1);
  }
  get zDir() {
    return this.cameraZ.setFromMatrixColumn(this.camera.matrix, 2);
  }
  fromJson(props) {
    this.perspectiveCamera.matrix.fromArray(props.matrix);
    this.perspectiveCamera.matrix.decompose(this.perspectiveCamera.position, this.perspectiveCamera.quaternion, this.perspectiveCamera.scale);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    props.perspective.fov && (this.perspectiveCamera.fov = props.perspective.fov);
    props.perspective.aspect && (this.perspectiveCamera.aspect = props.perspective.aspect);
    props.perspective.near && (this.perspectiveCamera.near = props.perspective.near);
    props.perspective.far && (this.perspectiveCamera.far = props.perspective.far);
    this.perspectiveCamera.zoom = props.zoom;
    props.up && this.perspectiveCamera.up.fromArray(props.up);
    this.perspectiveCameraHelper.handleResize();
    this.orthographicCamera.matrix.fromArray(props.matrix);
    this.orthographicCamera.matrix.decompose(this.orthographicCamera.position, this.orthographicCamera.quaternion, this.orthographicCamera.scale);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.left = props.orthographic.left;
    this.orthographicCamera.right = props.orthographic.right;
    this.orthographicCamera.top = props.orthographic.top;
    this.orthographicCamera.bottom = props.orthographic.bottom;
    props.orthographic.near && (this.orthographicCamera.near = props.orthographic.near);
    props.orthographic.far && (this.orthographicCamera.far = props.orthographic.far);
    this.orthographicCamera.zoom = props.zoom;
    props.up && this.orthographicCamera.up.fromArray(props.up);
    this.orthographicCameraHelper.handleResize();
    const pivotPoint = new Vector33().fromArray(props.pivotPoint);
    this.updatePivotPoint(pivotPoint);
    this.viewport.dispatchEvent({ type: "cameraChanged", previous: this.camera, current: this.camera });
  }
  toJson() {
    return {
      perspective: {
        fov: this.perspectiveCamera.fov,
        aspect: this.perspectiveCamera.aspect,
        near: this.perspectiveCamera.near,
        far: this.perspectiveCamera.far
      },
      orthographic: {
        left: this.orthographicCamera.left,
        right: this.orthographicCamera.right,
        top: this.orthographicCamera.top,
        bottom: this.orthographicCamera.bottom,
        near: this.orthographicCamera.near,
        far: this.orthographicCamera.far
      },
      zoom: this.camera.zoom,
      matrix: this.camera.matrix.toArray(),
      type: "camera-controller",
      key: this.camera.uuid ?? "",
      up: this.camera.up.toArray(),
      pivotPoint: this.getPivotPoint().toArray()
    };
  }
  fromSavedState() {
    if (this.savedState) {
      this.fromJson(this.savedState);
    } else {
      console.error("state is not saved");
    }
  }
}

// studio/src/core/ListenerStorage.ts
class ListenerStorage {
  listeners;
  listenersForFire;
  firedListenerIndex;
  fireArgument;
  constructor() {
    this.listeners = [];
  }
  add(callback, priority = 0, force = false) {
    if (!this.has(callback)) {
      if (priority !== 0 && this.listeners.length > 0) {
        const index = this.findIndex(this.listeners, priority, 0, this.listeners.length - 1);
        this.listeners.splice(index, 0, {
          callback,
          enable: true,
          priority
        });
      } else {
        this.listeners.push({ callback, enable: true, priority });
      }
      if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
        if (priority !== 0 && this.listeners.length > 0) {
          const index = this.findIndex(this.listenersForFire, priority, 0, this.listeners.length - 1);
          if (index > this.firedListenerIndex) {
            this.listenersForFire.splice(index, 0, {
              callback,
              enable: true,
              priority
            });
          } else if (index <= this.firedListenerIndex) {
            if (this.listenersForFire[this.firedListenerIndex + 1].priority === priority) {
              this.listenersForFire.splice(this.firedListenerIndex + 1, 0, {
                callback,
                enable: true,
                priority
              });
            }
          }
        } else {
          this.listenersForFire.push({
            callback,
            enable: true,
            priority
          });
        }
      }
    }
  }
  remove(callback, force = false) {
    let index = this.listeners.findIndex((listener) => listener.callback === callback);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
    if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
      index = this.listenersForFire.findIndex((listener) => listener.callback === callback);
      if (index > this.firedListenerIndex) {
        this.listenersForFire.splice(index, 1);
      } else {
        console.error("listener already executed");
      }
    }
  }
  findIndex(listeners, priority, start, end) {
    let middle = Math.floor((start + end) / 2);
    if (listeners[middle].priority === priority) {
      return middle;
    } else {
      if (priority < listeners[middle].priority) {
        if (middle < end) {
          return this.findIndex(listeners, priority, middle + 1, end);
        }
        return end + 1;
      } else {
        if (start < middle) {
          return this.findIndex(listeners, priority, start, middle - 1);
        }
        return start;
      }
    }
  }
  fire(arg) {
    if (typeof arg === "undefined" && typeof this.fireArgument !== "undefined") {
      arg = this.fireArgument;
    }
    this.listenersForFire = this.getListeners();
    this.listenersForFire.forEach((listener, index) => {
      this.firedListenerIndex = index;
      if (listener.enable) {
        listener.callback(arg);
      }
    });
    this.listenersForFire = undefined;
    this.firedListenerIndex = undefined;
  }
  getListeners() {
    return [...this.listeners];
  }
  clear() {
    this.listeners.splice(0);
  }
  isEmpty() {
    return this.listeners.length === 0;
  }
  has(callback) {
    return this.listeners.findIndex((listener) => listener.callback === callback) >= 0;
  }
  setEnabled(value, callback) {
    if (callback) {
      if (callback.flag === "me") {
        const listener = this.listeners.find((listener2) => listener2.callback === callback.callback);
        if (listener) {
          listener.enable = value;
        }
      } else {
        const listener = this.listeners.filter((listener2) => listener2.callback !== callback.callback);
        listener.forEach((listener2) => listener2.enable = value);
      }
    } else {
      this.listeners.forEach((listener) => listener.enable = value);
    }
  }
  enable(callback) {
    if (callback) {
      this.setEnabled(true, { callback, flag: "me" });
    } else {
      this.setEnabled(true);
    }
  }
  disable(callback) {
    if (callback) {
      this.setEnabled(false, { callback, flag: "me" });
    } else {
      this.setEnabled(false);
    }
  }
  enableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(false, { callback, flag: "me" }));
    } else {
      this.setEnabled(true, { callback: callbacks, flag: "exceptMe" });
    }
  }
  disableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(true, { callback, flag: "me" }));
    } else {
      this.setEnabled(false, { callback: callbacks, flag: "exceptMe" });
    }
  }
}

// studio/src/core/EventDispatcher.ts
class EventDispatcher {
  listeners;
  map;
  constructor(eventsList) {
    this.listeners = new Map;
    this.map = new Map;
    if (eventsList) {
      this.setListeners(eventsList);
    }
  }
  setListeners(eventsList) {
    !this.listeners.size && eventsList.forEach((event) => this.listeners.set(event, new ListenerStorage));
  }
  deleteListeners() {
    this.clearListeners();
    this.listeners.clear();
  }
  addEventListener(type, callback, priority) {
    this.listeners.get(type).add(callback, priority);
    return () => this.removeEventListener(type, callback);
  }
  addEventListenerOnce(type, callback, priority) {
    let fnOnce = this.map.get(callback);
    if (!fnOnce) {
      fnOnce = (event) => {
        callback(event);
        this.removeEventListener(type, fnOnce);
        this.map.delete(callback);
      };
      this.map.set(callback, fnOnce);
    }
    return this.addEventListener(type, fnOnce, priority);
  }
  removeEventListener(type, callback, force) {
    this.listeners.get(type)?.remove(callback, force);
  }
  hasEventListener(type, callback) {
    return this.listeners.get(type).has(callback);
  }
  eventIsListened(eventName) {
    return this.listeners.has(eventName);
  }
  dispatchEvent(event) {
    this.listeners.get(event.type).fire(event);
  }
  clearListeners(type) {
    if (type) {
      this.listeners.get(type)?.clear();
    } else {
      this.listeners.forEach((listenerStorage) => listenerStorage.clear());
    }
  }
}

// studio/src/core/view/ViewportRenderer.ts
import {LinearEncoding, NoToneMapping} from "three";

class ViewportRenderer {
  viewport;
  constructor(viewport) {
    this.viewport = viewport;
  }
}

class StandardViewportRenderer extends ViewportRenderer {
  toneMapping;
  outputEncoding;
  constructor(viewport, toneMapping = NoToneMapping, outputEncoding = LinearEncoding) {
    super(viewport);
    this.toneMapping = toneMapping;
    this.outputEncoding = outputEncoding;
  }
  render() {
    const r = this.viewport.view.getRenderer();
    r.setViewport(this.viewport.size);
    r.setScissor(this.viewport.size);
    r.setScissorTest(true);
    r.setClearColor(this.viewport.clearColor, this.viewport.alpha);
    const toneMappingOld = r.toneMapping;
    const outputEncodingOld = r.outputEncoding;
    r.toneMapping = this.toneMapping;
    r.outputEncoding = this.outputEncoding;
    r.render(this.viewport.view.scene, this.viewport.camera);
    r.toneMapping = toneMappingOld;
    r.outputEncoding = outputEncodingOld;
  }
}

// studio/src/core/view/Viewport.ts
var viewportEventNames = ["cameraChanged", "updatePivotPoint", "resize", "reposition", "setCameraRollMode"];

class Viewport extends EventDispatcher {
  view;
  size;
  domElement;
  cameraController;
  toolController;
  modes;
  name;
  rect;
  resizeObserver;
  mutationObserver;
  requestID;
  enabled;
  mutationObserverTarget;
  clearColor;
  viewportRenderer;
  alpha;
  constructor(view, name, domElement, threeLayer = 0, {
    cameraControllerProps,
    eventNames,
    mutationObserverTarget
  } = {}) {
    super(eventNames || viewportEventNames);
    this.view = view;
    this.name = name;
    this.size = new Vector4;
    this.domElement = domElement;
    if (!this.domElement)
      console.error(`[Viewport] Reference ${name} was not found`);
    this.cameraController = new CameraController(this, cameraControllerProps);
    this.toolController = new ToolsController(this);
    this.rect = this.domElement.getBoundingClientRect();
    this.handleResize = this.handleResize.bind(this);
    this.onResizeViewport = this.onResizeViewport.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeViewport);
    this.mutationObserver = new MutationObserver(this.onMutateViewport);
    this.enabled = false;
    this.camera.layers.enable(threeLayer);
    this.clearColor = 0;
    this.alpha = 1;
    this.viewportRenderer = new StandardViewportRenderer(this);
    this.mutationObserverTarget = mutationObserverTarget;
  }
  init() {
    if (this.enabled)
      return;
    this.resizeObserver.observe(this.domElement);
    this.mutationObserver.observe(this.mutationObserverTarget || this.view.getContainer(), {
      attributeFilter: ["style", "class"],
      subtree: true
    });
    this.handleResize();
    this.cameraController.init();
    this.toolController.init();
    const modeName = this.view.toolController.getModeName();
    if (modeName) {
      this.activateMode(modeName);
    }
    this.enabled = true;
  }
  dispose() {
    if (!this.enabled)
      return;
    this.cameraController.dispose();
    this.toolController.dispose();
    this.resizeObserver.unobserve(this.domElement);
    this.mutationObserver.disconnect();
    this.enabled = false;
  }
  setDomElement(domElement) {
    if (this.domElement === domElement)
      return;
    if (this.domElement) {
      this.resizeObserver.unobserve(this.domElement);
    }
    this.resizeObserver.observe(domElement);
    App2.Instance().mouseController.redefineDomElement(domElement, this.domElement);
    this.domElement = domElement;
  }
  getDomElement() {
    return this.domElement;
  }
  handleResize() {
    this.rect = this.domElement.getBoundingClientRect();
    const { left, bottom, width, height } = this.rect;
    const { height: parentHeight, top: parentTop, left: parentLeft } = this.view.getContainer().getBoundingClientRect();
    this.size.set(left - parentLeft, parentHeight - bottom + parentTop, Math.ceil(width), Math.ceil(height));
  }
  onResizeViewport() {
    this.handleResize();
    this.dispatchEvent({ type: "resize" });
    App2.Instance().dispatchEvent({ type: "render" });
  }
  onMutateViewport = (() => {
    const cachedSize = new Vector4;
    return () => {
      this.handleResize();
      if (cachedSize.x !== this.size.x || cachedSize.y !== this.size.y) {
        this.dispatchEvent({ type: "reposition" });
        App2.Instance().dispatchEvent({ type: "render" });
      }
      cachedSize.copy(this.size);
    };
  })();
  get x() {
    return this.size.x;
  }
  set x(val) {
    this.size.x = val;
  }
  get y() {
    return this.size.y;
  }
  set y(val) {
    this.size.y = val;
  }
  get width() {
    return this.size.z;
  }
  set width(val) {
    this.size.z = val;
  }
  get height() {
    return this.size.w;
  }
  set height(val) {
    this.size.w = val;
  }
  get camera() {
    return this.cameraController.camera;
  }
  get cameraLayer() {
    return 31 - Math.clz32(this.camera.layers.mask);
  }
  activateMode(modeName) {
    this.toolController.activateMode(modeName);
  }
  renderImmediately() {
    this.viewportRenderer.render();
  }
  render() {
    if (this.requestID !== undefined)
      return;
    this.requestID = requestAnimationFrame(() => {
      this.renderImmediately();
      this.requestID = undefined;
    });
  }
  isEnabled() {
    return this.enabled;
  }
}

// studio/src/core/containers/SelectContainer.ts
class SelectContainer extends Container {
  constructor(hostObject, objects) {
    super(hostObject, objects);
  }
  add(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.addOne(child);
      });
    } else {
      this.addOne(selectComponent);
    }
  }
  delete(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.deleteOne(child);
      });
    } else {
      this.deleteOne(selectComponent);
    }
  }
  size() {
    return this.objects.size;
  }
  addOne(selectComponent) {
    selectComponent.select();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.add(selectComponent);
      } else {
        this.objects.set(layer, new Set([selectComponent]));
      }
    }
  }
  deleteOne(selectComponent) {
    selectComponent.deselect();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.delete(selectComponent);
      }
    }
  }
}

// studio/src/core/controllers/SelectControllers.ts
class SelectController {
  objects;
  container;
  constructor() {
    this.objects = new Map;
    this.container = new SelectContainer(this, this.objects);
  }
  getSelected(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? [...selected] : [];
    } else {
      const selected = [];
      this.objects.forEach((objects) => {
        selected.push(...objects);
      });
      return selected;
    }
  }
  add(selectables) {
    this.container.add(selectables);
    App2.Instance().dispatchEvent({
      type: "select",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  delete(selectables) {
    this.container.delete(selectables);
    App2.Instance().dispatchEvent({
      type: "deselect",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  clear(layer) {
    const objects = this.getSelected(layer);
    objects?.forEach((object) => object.deselect());
    if (layer) {
      this.objects.delete(layer);
    } else {
      this.objects.clear();
    }
    App2.Instance().dispatchEvent({ type: "deselect", objects });
  }
  selectedCount(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? selected.size : 0;
    } else {
      let selectedCount = 0;
      this.objects.forEach((objects) => {
        selectedCount += objects.size;
      });
      return selectedCount;
    }
  }
  has(obj) {
    const map = this.objects;
    for (let set of map.values()) {
      if (set.has(obj)) {
        return true;
      }
    }
    return false;
  }
}

// studio/src/core/view/View.ts
class View {
  container;
  resizeObserver;
  viewports;
  renderer;
  canvas;
  scene;
  layers;
  toolController;
  selectController;
  name;
  activeViewport;
  cameraLayers;
  constructor() {
    this.layers = new Map;
    this.scene = new Scene;
    this.viewports = new Map;
    this.toolController = new ToolsController(this);
    this.selectController = new SelectController;
    this.cameraLayers = [0];
    this.handleResize = this.handleResize.bind(this);
    this.onResizeView = this.onResizeView.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeView);
  }
  init(props) {
    this.name = props?.name;
    this.container = props?.container || document.getElementById("container") || document.body.appendChild(document.createElement("div"));
    if (!this.container)
      console.error(`[View] Reference ${this.name} was not found`);
    this.renderer = new WebGLRenderer(props?.rendererProps);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.canvas = this.renderer.domElement;
    this.canvas.dataset.belongsTo = this.name;
    if (!props?.rendererProps?.canvas) {
      this.container.appendChild(this.canvas);
      this.canvas.style.width = "100%";
      this.canvas.style.height = "100%";
    }
    this.handleResize();
    this.toolController.init();
    this.resizeObserver.observe(this.container);
    App2.Instance().addEventListener("resize", this.handleResize, 1);
    App2.Instance().dispatchEvent({ type: "render" });
    this.container.addEventListener("contextmenu", (event) => event.preventDefault(), false);
  }
  dispose() {
    this.toolController.dispose();
    this.selectController.clear();
    this.clearLayers();
    this.viewports.forEach((viewport) => {
      viewport.dispose();
    });
    if (this.container)
      this.resizeObserver.unobserve(this.container);
    App2.Instance().removeEventListener("resize", this.handleResize);
  }
  clear() {
    this.dispose();
    this.viewports.forEach((viewport) => {
      this.deleteViewport(viewport.name);
    });
    this.layers.forEach((layer) => {
      this.removeLayer(layer.getName());
    });
    this.toolController.clear();
  }
  clearLayers() {
    if (!this.layers.size)
      return;
    const layers = this.getLayers();
    for (const layer of layers) {
      layer.getObjects()?.forEach((obj) => obj.dispose());
      layer.clear();
    }
    App2.Instance().dispatchEvent({ type: "render" });
  }
  getRenderer() {
    return this.renderer;
  }
  getContainer() {
    return this.container;
  }
  getCanvas() {
    return this.canvas;
  }
  onResizeView() {
    this.handleResize();
  }
  handleResize() {
    const width = this.container.clientWidth || 0;
    const height = this.container.clientHeight || 0;
    this.renderer.setSize(width, height, true);
  }
  addViewport(name, domElement, options) {
    const viewport = new Viewport(this, name, domElement, this.getEmptyCameraLayer(), options);
    this.viewports.set(name, viewport);
    return viewport;
  }
  deleteViewport(name) {
    const viewport = this.viewports.get(name);
    if (viewport) {
      const layer = viewport.cameraLayer;
      if (layer !== 0) {
        this.cameraLayers[layer] = undefined;
      }
      viewport.dispose();
      this.viewports.delete(name);
    }
  }
  getEmptyCameraLayer() {
    for (let i = 1;i < 32; i++) {
      if (!this.cameraLayers[i]) {
        this.cameraLayers[i] = i;
        return i;
      }
    }
  }
  getViewport(name) {
    return this.viewports.get(name);
  }
  addLayer(name, objects) {
    let layer = this.layers.get(name);
    if (layer) {
      objects && layer.add(objects);
      return layer;
    }
    layer = new Layer(this, name, objects);
    this.layers.set(name, layer);
    this.scene.add(layer.getGroup());
    App2.Instance().dispatchEvent({ type: "addLayer", container: layer });
    return layer;
  }
  removeLayer(name) {
    const layer = this.layers.get(name);
    if (!layer)
      return;
    layer.getObjects()?.forEach((obj) => obj.dispose());
    layer.clear();
    this.scene.remove(layer.getGroup());
    this.layers.delete(name);
    App2.Instance().dispatchEvent({ type: "deleteLayer", container: layer });
  }
  getLayer(name) {
    if (!this.layers.has(name))
      console.warn("getLayer: no layer=", name);
    return this.layers.get(name);
  }
  *getLayers() {
    for (const layer of this.layers) {
      yield layer[1];
    }
  }
  *getVisibleLayers() {
    for (const layer of this.layers) {
      if (layer[1].getGroup().visible) {
        yield layer[1];
      }
    }
  }
  activateMode(modeName) {
    const previous = this.toolController.getModeName();
    if (previous === modeName)
      return;
    this.toolController.activateMode(modeName);
    this.viewports.forEach((viewport) => viewport.activateMode(modeName));
    const mode = this.toolController.getModeName();
    App2.Instance().dispatchEvent({ type: "modeChanged", previous: previous !== undefined ? previous : "", mode: mode !== undefined ? mode : "" });
  }
  setActiveViewport(viewportName) {
    const viewport = typeof viewportName === "undefined" ? viewportName : this.viewports.get(viewportName);
    if (this.activeViewport === viewport)
      return;
    App2.Instance().dispatchEvent({ type: "ActiveViewportWillChange", current: this.activeViewport, next: viewport });
    const previousViewport = this.activeViewport;
    this.activeViewport = viewport;
    App2.Instance().dispatchEvent({ type: "ActiveViewportChanged", previous: previousViewport, current: viewport });
  }
  getActiveViewport() {
    return this.activeViewport;
  }
}

// studio/src/core/UndoStack.ts
var undoStackEventNames = ["add", "undo", "redo", "clear"];

class UndoStack extends EventDispatcher {
  stack;
  index;
  lastIndex;
  constructor() {
    super(undoStackEventNames);
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
  }
  add(command) {
    if (this.index !== this.lastIndex) {
      this.stack.splice(this.index + 1);
    }
    this.stack.push(command);
    this.index++;
    this.lastIndex = this.index;
    this.dispatchEvent({ type: "add" });
  }
  getCommandList() {
    return [...this.stack];
  }
  removeLast() {
    this.stack.pop();
    this.index--;
    this.lastIndex = this.index;
  }
  undo() {
    if (this.index >= 0) {
      this.stack[this.index].undo();
      this.index--;
      this.dispatchEvent({ type: "undo" });
    }
  }
  redo() {
    if (this.index !== this.lastIndex) {
      this.stack[this.index + 1].execute();
      this.index++;
      this.dispatchEvent({ type: "redo" });
    }
  }
  undoAll() {
    do {
      this.undo();
    } while (this.index >= 0);
  }
  getIndex() {
    return this.index;
  }
  getLastIndex() {
    return this.lastIndex;
  }
  clear() {
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
    this.dispatchEvent({ type: "clear" });
  }
}

// studio/src/utils/PointerEventsStorage.ts
var mouseButtons = ["left", "middle", "right"];
var anyButtonEventTypesArr = ["pointerdown", "pointerup"];
var specialEventTypesArr = ["click", "dblclick", "contextmenu", "pointermove", "pointerenter", "pointerleave", "pointerout", "pointerover", "wheel"];
var isSpecialEventType = (type) => {
  return specialEventTypesArr.some((el) => el === type);
};
class PointerEventsStorage {
  element;
  listeners;
  callbacks;
  constructor(element = document) {
    this.element = element;
    this.listeners = {};
    this.callbacks = {};
  }
  init() {
    anyButtonEventTypesArr.forEach((type) => {
      this.listeners[type] = {
        left: new ListenerStorage,
        middle: new ListenerStorage,
        right: new ListenerStorage
      };
      this.callbacks[type] = {
        callback: (event) => this.listeners[type][mouseButtons[event.button]].fire(event),
        isListening: false
      };
    });
    specialEventTypesArr.forEach((type) => {
      this.listeners[type] = new ListenerStorage;
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].fire(event),
        isListening: false
      };
    });
  }
  dispose() {
    if (this.listeners) {
      this.clear();
      Object.keys(this.listeners).forEach((key) => delete this.listeners[key]);
    }
  }
  changeDomElement(element) {
    const oldDomElement = this.element;
    this.element = element;
    anyButtonEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
  }
  addAnyButtonListener(eventType, button, listener, priority, force) {
    this.listeners[eventType][button].add(listener, priority, force);
    this.startListening(eventType);
  }
  addSpecialEventListeners(eventType, listener, priority, force) {
    this.listeners[eventType].add(listener, priority, force);
    this.startListening(eventType);
  }
  removeButtonListener(eventType, button, listener, force) {
    if (this.listeners) {
      this.listeners[eventType][button].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  removeMoveAndWheelListener(eventType, listener, force) {
    if (this.listeners) {
      this.listeners[eventType].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  startListening(eventType) {
    if (this.callbacks[eventType].callback && !this.callbacks[eventType].isListening) {
      if (!this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = true;
        this.element.addEventListener(eventType, this.callbacks[eventType].callback, { capture: false, passive: false });
      }
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].callback && this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        this.element.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  clear() {
    anyButtonEventTypesArr.forEach((eventType) => {
      Object.values(this.listeners[eventType]).forEach((value) => value.clear());
      this.stopListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      this.listeners[eventType].clear();
      this.stopListening(eventType);
    });
  }
  isEmpty(eventType) {
    if (isSpecialEventType(eventType)) {
      return this.listeners[eventType].isEmpty();
    }
    return Object.values(this.listeners[eventType]).every((value) => value.isEmpty());
  }
  has(eventType, listener, button) {
    if (this.listeners[eventType] instanceof ListenerStorage) {
      return this.listeners[eventType].has(listener);
    } else if (button) {
      return this.listeners[eventType][button].has(listener);
    }
    return false;
  }
  disableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disable(listener);
    }
  }
  enableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enable(listener);
    }
  }
  disableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disableExcept(listener);
    }
  }
  enableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enableExcept(listener);
    }
  }
  disableWheel(listener) {
    this.listeners["wheel"].disable(listener);
  }
  enableWheel(listener) {
    this.listeners["wheel"].enable(listener);
  }
  disableMove(listener) {
    this.listeners["pointermove"].disable(listener);
  }
  enableMove(listener) {
    this.listeners["pointermove"].enable(listener);
  }
  disableMoveExcept(listeners) {
    this.listeners["pointermove"].disableExcept(listeners);
  }
  enableMoveExcept(listeners) {
    this.listeners["pointermove"].enableExcept(listeners);
  }
}

// studio/src/core/controllers/PointerController.ts
class PointerController {
  listeners;
  constructor() {
    this.listeners = new Map().set(document, new PointerEventsStorage);
  }
  init() {
    this.listeners.forEach((val) => val.init());
  }
  dispose() {
    this.listeners.forEach((val) => val.dispose());
  }
  clear() {
    this.dispose();
    this.listeners.clear();
  }
  redefineDomElement(newElement, oldElement) {
    const oldElementListeners = this.listeners.get(oldElement);
    if (oldElementListeners) {
      oldElementListeners.changeDomElement(newElement);
      this.listeners.set(newElement, oldElementListeners);
      this.listeners.delete(oldElement);
    }
  }
  addListener(eventType, listener, element = document, button, priority, force) {
    let mouseEventListeners = this.listeners.get(element);
    if (!mouseEventListeners) {
      mouseEventListeners = new PointerEventsStorage(element);
      this.listeners.set(element, mouseEventListeners);
      mouseEventListeners.init();
    }
    if (isSpecialEventType(eventType)) {
      if ((!button || typeof button === "number") && typeof priority !== "number") {
        mouseEventListeners.addSpecialEventListeners(eventType, listener, button, priority);
        return () => this.removeListener(eventType, listener, element);
      } else {
        console.error("listener is not added. Event type: " + eventType);
      }
    } else if (button !== undefined) {
      if (typeof button !== "number" && typeof priority !== "boolean") {
        mouseEventListeners.addAnyButtonListener(eventType, button, listener, priority, force);
        return () => this.removeListener(eventType, listener, element, button);
      } else {
        console.error("listener is not added. Event type: " + eventType + ", button: " + button);
      }
    } else {
      console.error("listener is not added. Event type: " + eventType + ", button: " + button);
    }
  }
  removeListener(eventType, listener, element = document, button, force = false) {
    let mouseEventListeners;
    if (element) {
      mouseEventListeners = this.listeners.get(element);
    }
    if (mouseEventListeners) {
      if (isSpecialEventType(eventType)) {
        if (typeof button === "boolean" || typeof button === "undefined") {
          mouseEventListeners.removeMoveAndWheelListener(eventType, listener, button);
        }
      } else if (button !== undefined && typeof button !== "boolean") {
        mouseEventListeners.removeButtonListener(eventType, button, listener, force);
      }
    } else {
      console.warn("listener is not found");
    }
  }
}

// studio/src/core/controllers/KeyboardController.ts
var keyBoardEventTypesArr = ["keydown", "keyup", "keypress"];
class KeyboardController {
  listeners;
  callbacks;
  init() {
    this.listeners = {
      keydown: new Map,
      keyup: new Map,
      keypress: new Map
    };
    this.callbacks = {};
    keyBoardEventTypesArr.forEach((type) => {
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].get(event.code)?.fire(event),
        isListening: true
      };
    });
  }
  dispose() {
    if (this.listeners) {
      keyBoardEventTypesArr.forEach((eventType) => {
        this.listeners[eventType].forEach((ListenerStorage5) => ListenerStorage5.clear());
        this.listeners[eventType].clear();
        this.stopListening(eventType);
      });
    }
  }
  addListener(eventType, listener, eventCode, priority) {
    const listenerStorage = this.listeners[eventType].get(eventCode);
    if (listenerStorage) {
      listenerStorage.add(listener, priority);
    } else {
      const storage = new ListenerStorage;
      storage.add(listener, priority);
      this.listeners[eventType].set(eventCode, storage);
    }
    this.startListening(eventType);
  }
  removeListener(eventType, listener, code) {
    const listenerStorage = this.listeners[eventType].get(code);
    if (listenerStorage) {
      listenerStorage.remove(listener);
      this.stopListening(eventType);
    }
  }
  disableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.disable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage5) => ListenerStorage5.disable()));
    }
  }
  enableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.enable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage5) => ListenerStorage5.enable()));
    }
  }
  startListening(eventType) {
    if (!this.isEmpty(eventType)) {
      document.addEventListener(eventType, this.callbacks[eventType].callback, false);
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        document.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  isEmpty(eventType) {
    return this.listeners[eventType].size === 0;
  }
}

// studio/src/core/controllers/RaycastController.ts
import {Raycaster} from "three";

// studio/src/core/components/Component.ts
class Component {
  entity;
  constructor(entity) {
    this.entity = entity;
  }
}

// studio/src/core/components/Raycast.ts
class Raycast extends Component {
  constructor(entity) {
    super(entity);
  }
  isRaycast(raycaster, recursive = true) {
    const activeViewport = App2.Instance().getActiveView().getActiveViewport();
    if (activeViewport) {
      raycaster.layers.mask = activeViewport.camera.layers.mask;
      const intersections = raycaster.intersectObject(this.entity.object3d, recursive);
      return intersections.length ? intersections : false;
    }
    return false;
  }
  *intersect(raycaster) {
    if (!this.entity.object3d.visible)
      return;
    const intersections = this.isRaycast(raycaster, true);
    if (intersections) {
      yield { raycast: this, intersections };
    }
  }
}

class RaycastChildrenOnly extends Raycast {
  *intersect(raycaster) {
    for (const child of this.entity.children) {
      const raycast = child.getComponent(Raycast);
      if (raycast) {
        yield* raycast.intersect(raycaster);
      }
    }
  }
}

// studio/src/core/highlighters/ColorHighlighter.ts
import {Mesh, ShaderMaterial, Object3D} from "three";

// studio/src/core/highlighters/Highlighter.ts
class Highlighter {
  entity;
  constructor(entity) {
    this.entity = entity;
  }
}

// studio/src/core/highlighters/ColorHighlighter.ts
class ColorHighlighter extends Highlighter {
  color;
  material;
  constructor(object3d, color = 16711680) {
    super(object3d);
    this.material = new Map;
    this.color = color;
  }
  highlight() {
    this.saveColor();
    this.setColor(this.color);
  }
  unHighlight() {
    this.restoreColor();
  }
  setColor(color) {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSetColor(this.entity.object3d.material, color);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeSetColor(obj.material, color);
      });
    }
  }
  saveColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSaveColor(this.entity.object3d.material, this.entity);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeSaveColor(obj.material, obj);
      });
    }
  }
  restoreColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeRestoreColor(this.entity.object3d.material, this.material.get(this.entity));
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material)
          this.makeRestoreColor(obj.material, this.material.get(obj));
      });
    }
  }
  makeSetColor(mat, color) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      material.uniforms.diffuse?.value?.set(color);
      material.uniforms.specular?.value?.set(color);
      material.uniforms.emissive?.value?.set(color);
    } else {
      material.color?.set(color);
      material.specular?.set(color);
      material.emissive?.set(color);
    }
  }
  makeSaveColor(mat, obj) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      this.material.set(obj, {
        color: material.uniforms.diffuse?.value?.toArray(),
        specular: material.uniforms.specular?.value?.toArray(),
        emissive: material.uniforms.emissive?.value?.toArray()
      });
    } else {
      this.material.set(obj, {
        color: material.color?.toArray(),
        specular: material.specular?.toArray(),
        emissive: material.emissive?.toArray()
      });
    }
  }
  makeRestoreColor(mat, data) {
    const material = mat;
    if (data) {
      if (material instanceof ShaderMaterial) {
        material.uniforms.diffuse?.value?.fromArray(data.color);
        material.uniforms.specular?.value?.fromArray(data.specular);
        material.uniforms.emissive?.value?.fromArray(data.emissive);
      } else {
        material.color?.fromArray(data.color);
        material.specular?.fromArray(data.specular);
        material.emissive?.fromArray(data.emissive);
      }
    }
  }
}

// studio/src/core/components/HoverComponent.ts
class HoverComponent extends Component {
  hovered;
  highlighter;
  constructor(entity, highlighter = new ColorHighlighter(entity, undefined)) {
    super(entity);
    this.hovered = false;
    this.highlighter = highlighter;
  }
  hover() {
    this.hovered = true;
    this.highlighter.highlight();
  }
  unhover() {
    this.hovered = false;
    this.highlighter.unHighlight();
  }
  isHovered() {
    return this.hovered;
  }
}

// studio/src/core/controllers/RaycastController.ts
class RaycastController {
  raycaster;
  viewport;
  state;
  _object;
  raycastData;
  constructor() {
    this.raycaster = new Raycaster;
    this.raycaster.params.Line = { threshold: 0.01 };
    this.state = {
      transformObject: false,
      transformCamera: false
    };
    this.handleChangeActiveViewport = this.handleChangeActiveViewport.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onTransformCameraStart = this.onTransformCameraStart.bind(this);
    this.onTransformCameraEnd = this.onTransformCameraEnd.bind(this);
    this.onTransformCamera = this.onTransformCamera.bind(this);
    this.update = this.update.bind(this);
  }
  get view() {
    return App2.Instance().getActiveView();
  }
  get position() {
    return App2.Instance().cursorController.position;
  }
  get object() {
    return this._object;
  }
  set object(value) {
    if (this._object === value)
      return;
    if (this._object) {
      App2.Instance().dispatchEvent({ type: "unhover", entity: this._object });
    }
    this._object = value;
    if (this._object && this._object.getComponent(HoverComponent)) {
      App2.Instance().dispatchEvent({ type: "hover", entity: this._object });
    }
  }
  getObject() {
    return this.object;
  }
  init() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    App2.Instance().addEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App2.Instance().addEventListener("transformStart", this.onTransformStart);
    App2.Instance().addEventListener("transformCamera", this.onTransformCamera);
    App2.Instance().addEventListener("transformCameraStart", this.onTransformCameraStart);
  }
  dispose() {
    this.changeListenActiveViewport(this.viewport);
    App2.Instance().removeEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App2.Instance().removeEventListener("transformStart", this.onTransformStart);
    App2.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    App2.Instance().removeEventListener("transformCamera", this.onTransformCamera);
    App2.Instance().removeEventListener("transformCameraStart", this.onTransformCameraStart);
    this.raycastData = undefined;
    this.object = undefined;
  }
  changeListenActiveViewport(currentViewport, viewport) {
    if (currentViewport) {
      App2.Instance().mouseController.removeListener("pointermove", this.update, currentViewport.getDomElement(), true);
      App2.Instance().mouseController.removeListener("pointerdown", this.update, currentViewport.getDomElement(), "left", true);
      currentViewport.removeEventListener("resize", this.update);
      currentViewport.removeEventListener("reposition", this.update);
    }
    if (viewport) {
      App2.Instance().mouseController.addListener("pointermove", this.update, viewport.getDomElement(), 20 /* RaycastController */);
      App2.Instance().mouseController.addListener("pointerdown", this.update, viewport.getDomElement(), "left", 20 /* RaycastController */);
      viewport.addEventListener("resize", this.update);
      viewport.addEventListener("reposition", this.update);
      this.updateLineThreshold(viewport);
    } else if (this.object) {
      this.raycastData = undefined;
      this.object = undefined;
    }
    this.viewport = viewport;
  }
  start() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    this.update();
  }
  stop() {
    this.changeListenActiveViewport(this.viewport);
  }
  handleChangeActiveViewport(event) {
    this.changeListenActiveViewport(this.viewport, event.next);
  }
  updateLineThreshold(viewport) {
    const zoom = viewport.cameraController.camera.zoom;
    this.raycaster.params.Line.threshold = 60 / zoom;
  }
  onTransformCamera(event) {
    if (event.viewport === this.viewport)
      this.updateLineThreshold(this.viewport);
  }
  onTransformCameraStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App2.Instance().addEventListener("transformCameraEnd", this.onTransformCameraEnd);
    this.state.transformCamera = true;
  }
  onTransformCameraEnd() {
    this.state.transformCamera = false;
    if (!this.state.transformObject) {
      this.start();
    }
    App2.Instance().removeEventListener("transformCameraEnd", this.onTransformCameraEnd);
  }
  onTransformStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App2.Instance().addEventListener("transformEnd", this.onTransformEnd);
    this.state.transformObject = true;
  }
  onTransformEnd() {
    this.state.transformObject = false;
    if (!this.state.transformCamera) {
      this.start();
    }
    App2.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  getNearest(generator) {
    const results = [];
    for (const intersection of generator) {
      intersection.intersections.sort((a, b) => a.distance - b.distance);
      results.push(intersection);
    }
    if (results.length) {
      results.sort((a, b) => a.intersections[0].distance - b.intersections[0].distance);
      return results[0];
    }
  }
  *raycast() {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    for (const layer of this.view.getLayers()) {
      const objects = layer.getComponent(Raycast);
      if (objects) {
        for (const object of objects) {
          const generator = object.intersect(this.raycaster);
          for (const intersection of generator) {
            yield intersection;
          }
        }
      }
    }
  }
  *raycastObject(object) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    let generator;
    if (object instanceof Raycast) {
      generator = object.intersect(this.raycaster);
    } else {
      const raycast = object.getComponent(Raycast);
      if (!raycast)
        return;
      generator = raycast.intersect(this.raycaster);
    }
    for (const intersection of generator) {
      yield intersection;
    }
  }
  getPositionOnZeroPlane(v) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    const distance = -this.raycaster.ray.origin.z / this.raycaster.ray.direction.z;
    v.x = this.raycaster.ray.origin.x + this.raycaster.ray.direction.x * distance;
    v.y = this.raycaster.ray.origin.y + this.raycaster.ray.direction.y * distance;
    v.z = 0;
    return v;
  }
  update() {
    this.raycastData = this.getNearest(this.raycast());
    this.object = this.raycastData?.raycast.entity;
  }
}

// studio/src/core/controllers/CursorController.ts
import {Vector2} from "three";
class CursorController {
  position = new Vector2(0, 0);
  clientPosition = new Vector2(0, 0);
  constructor() {
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onChangeActiveViewport = this.onChangeActiveViewport.bind(this);
  }
  init() {
    App2.Instance().addEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App2.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      this.viewport.addEventListener("resize", this.handleResize, 30 /* CursorController */);
      this.viewport.addEventListener("reposition", this.handleResize, 30 /* CursorController */);
      App2.Instance().mouseController.addListener("pointermove", this.handleChangePosition, this.viewport.getDomElement(), 30 /* CursorController */);
      App2.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left", 30 /* CursorController */);
    }
  }
  dispose() {
    App2.Instance().removeEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App2.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      App2.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, this.viewport.getDomElement());
      App2.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left");
      this.viewport.removeEventListener("resize", this.handleResize);
      this.viewport.removeEventListener("reposition", this.handleResize);
    }
  }
  get view() {
    return App2.Instance().getActiveView();
  }
  get viewport() {
    return this.view.getActiveViewport();
  }
  onChangeActiveViewport(event) {
    event.current?.removeEventListener("resize", this.handleResize);
    event.next?.addEventListener("resize", this.handleResize, 30 /* CursorController */);
    event.current?.removeEventListener("reposition", this.handleResize);
    event.next?.addEventListener("reposition", this.handleResize, 30 /* CursorController */);
    if (event.current) {
      App2.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, event.current.getDomElement());
      App2.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, event.current.getDomElement(), "left");
    }
    if (event.next) {
      App2.Instance().mouseController.addListener("pointermove", this.handleChangePosition, event.next.getDomElement(), 30 /* CursorController */);
      App2.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, event.next.getDomElement(), "left", 30 /* CursorController */);
    }
  }
  setPosition(x, y) {
    this.position.set((x - this.viewport.rect.left) / this.viewport.rect.width * 2 - 1, -(y - this.viewport.rect.top) / this.viewport.rect.height * 2 + 1);
  }
  handleChangePosition(event) {
    this.setPosition(event.clientX, event.clientY);
  }
  handleMouseMove(event) {
    this.clientPosition.set(event.clientX, event.clientY);
  }
  handleResize() {
    this.setPosition(this.clientPosition.x, this.clientPosition.y);
  }
}

// studio/src/core/App.ts
class App2 extends EventDispatcher {
  view;
  views;
  initialized = false;
  get isInitialized() {
    return this.initialized;
  }
  mouseController;
  keyboardController;
  raycastController;
  cursorController;
  undoStack;
  activeView;
  resources;
  resizeObserver;
  startupSystems;
  static instance;
  static Instance() {
    return this.instance ?? (this.instance = new App2);
  }
  constructor() {
    super();
    this.view = new View;
    this.activeView = this.view;
    this.views = {};
    this.mouseController = new PointerController;
    this.keyboardController = new KeyboardController;
    this.raycastController = new RaycastController;
    this.cursorController = new CursorController;
    this.undoStack = new UndoStack;
    this.handleResize = this.handleResize.bind(this);
    this.resources = new Map;
    this.startupSystems = [];
  }
  init(props) {
    this.mouseController.init();
    this.keyboardController.init();
    this.raycastController.init();
    this.cursorController.init();
    this.view.init(props);
    window.addEventListener("resize", this.handleResize, false);
    if (props?.container) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(props.container);
    }
    this.initialized = true;
  }
  dispose() {
    if (!this.initialized)
      return;
    this.view.dispose();
    for (const key in this.views) {
      this.views[key].dispose();
    }
    this.cursorController.dispose();
    this.raycastController.dispose();
    this.keyboardController.dispose();
    this.mouseController.dispose();
    this.undoStack.clearListeners();
    this.clearListeners();
    window.removeEventListener("resize", this.handleResize, false);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
    this.initialized = false;
  }
  clear() {
    this.dispose();
    this.view.clear();
    for (const key in this.views) {
      this.views[key].clear();
    }
    this.deleteListeners();
    this.undoStack.clear();
  }
  async runStartups() {
    for (let i = 0, il = this.startupSystems.length;i < il; i++) {
      const system = this.startupSystems[i];
      if (system.prototype?.constructor) {
        await new system().start();
      } else {
        await system();
      }
      this.dispatchEvent({ type: "applicationProgress", progress: (i + 1) / il });
    }
  }
  executeCommand(command, immediately = true) {
    if (immediately) {
      if (command.execute()) {
        this.undoStack.add(command);
      }
    } else {
      this.undoStack.add(command);
    }
  }
  getActiveView() {
    return this.activeView;
  }
  setActiveView(view) {
    if (this.activeView === view) {
      return;
    }
    this.activeView.setActiveViewport(undefined);
    this.activeView = view;
  }
  addResources(name, resource) {
    this.resources.set(name, resource);
    this.dispatchEvent({ type: "resourceAdded", name });
  }
  getResources(name) {
    return this.resources.get(name);
  }
  handleResize() {
    this.dispatchEvent({ type: "resize" });
  }
}

// studio/src/products/shared/SharedConstants.ts
var MPRViewportNames = ["Coronal", "Sagittal", "Axial"];

// studio/src/core/Mode.ts
class Mode {
  toolConstructors;
  hostObject;
  options;
  name;
  enable;
  constructor(options) {
    if (Array.isArray(options)) {
      this.options = { tools: { toolList: options, include: true } };
    } else {
      this.options = options;
    }
    this.toolConstructors = new Set;
    this.init = this.init.bind(this);
    this.name = "";
    this.enable = false;
  }
  setHostObject(hostObject, modeName) {
    this.hostObject = hostObject;
    this.name = modeName;
    const rootTools = this.hostObject.toolController.getTools();
    if (!rootTools) {
      console.error("Tools are not defined!");
      return;
    }
    if (!this.options) {
      Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
    } else {
      if (this.options.tools) {
        if (this.options.tools.include) {
          this.options.tools.toolList.forEach((item) => this.toolConstructors.add(item));
        } else {
          Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
          this.options.tools.toolList.forEach((toolName) => this.toolConstructors.delete(toolName));
        }
      } else {
        Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
      }
    }
  }
  init() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined before init");
      return;
    }
    const prevMode = this.hostObject.toolController.getMode();
    if (prevMode && prevMode.enable) {
      prevMode.toolConstructors.forEach((toolName) => {
        if (!this.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.dispose();
        }
      });
      this.toolConstructors.forEach((toolName) => {
        if (!prevMode.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.init();
        }
      });
    } else {
      this.toolConstructors.forEach((toolName) => {
        this.hostObject.toolController.getTool(toolName)?.init();
      });
    }
    this.enable = true;
  }
  dispose() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined while disposing");
      return;
    }
    this.toolConstructors.forEach((toolName) => {
      this.hostObject.toolController.getTool(toolName)?.dispose();
    });
    this.enable = false;
  }
  getName() {
    return this.name;
  }
  getToolNames() {
    return this.toolConstructors;
  }
}

// studio/src/core/Tool.ts
class Tool {
  enable;
  hostObject;
  history;
  removeEventsCallbacks = [];
  constructor(viewport) {
    this.hostObject = viewport;
    this.enable = false;
    this.onConstructor();
  }
  onConstructor() {
  }
  unsubscribeAll() {
    this.removeEventsCallbacks.forEach((cb) => cb());
    this.removeEventsCallbacks.splice(0);
  }
  init() {
    if (!this.enable && this.hostObject) {
      this.activate();
      this.enable = true;
    } else {
      console.error("tool is not initialized", this.enable, typeof this.hostObject === "undefined");
    }
  }
  dispose() {
    if (this.enable) {
      this.deactivate();
      this.enable = false;
    }
  }
  getHostObject() {
    return this.hostObject;
  }
  on(type, callback, priority) {
    const removeCallback = App2.Instance().addEventListener(type, callback, priority);
    this.removeEventsCallbacks?.push(removeCallback);
    return removeCallback;
  }
  off(type, callback, force) {
    return App2.Instance().removeEventListener(type, callback, force);
  }
  deactivate() {
    this.unsubscribeAll();
  }
}

class ViewportTool extends Tool {
  constructor(viewport) {
    super(viewport);
  }
  getHostObject() {
    return this.hostObject;
  }
}

class ViewTool extends Tool {
  constructor(view) {
    super(view);
  }
  getHostObject() {
    return this.hostObject;
  }
}

// studio/src/core/tools/Render.ts
class Render extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.render = this.render.bind(this);
  }
  activate() {
    App2.Instance().addEventListener("add", this.render);
    App2.Instance().addEventListener("delete", this.render);
    App2.Instance().addEventListener("resize", this.render);
    App2.Instance().addEventListener("transformCamera", this.render);
    App2.Instance().addEventListener("select", this.render);
    App2.Instance().addEventListener("deselect", this.render);
    App2.Instance().addEventListener("transform", this.render);
    App2.Instance().addEventListener("viewportSchemeChanged", this.render);
    App2.Instance().addEventListener("render", this.render);
  }
  deactivate() {
    App2.Instance().removeEventListener("add", this.render);
    App2.Instance().removeEventListener("delete", this.render);
    App2.Instance().removeEventListener("resize", this.render);
    App2.Instance().removeEventListener("transformCamera", this.render);
    App2.Instance().removeEventListener("select", this.render);
    App2.Instance().removeEventListener("deselect", this.render);
    App2.Instance().removeEventListener("transform", this.render);
    App2.Instance().removeEventListener("viewportSchemeChanged", this.render);
    App2.Instance().removeEventListener("render", this.render);
  }
  render() {
    this.hostObject.render();
  }
}

// studio/src/core/tools/CameraToggle.ts
class CameraToggle extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.setPerspectiveCamera = this.setPerspectiveCamera.bind(this);
    this.setOrthographicCamera = this.setOrthographicCamera.bind(this);
  }
  activate() {
    App2.Instance().keyboardController.addListener("keydown", this.setPerspectiveCamera, "KeyP");
    App2.Instance().keyboardController.addListener("keydown", this.setOrthographicCamera, "KeyO");
  }
  deactivate() {
    App2.Instance().keyboardController.removeListener("keydown", this.setPerspectiveCamera, "KeyP");
    App2.Instance().keyboardController.removeListener("keydown", this.setOrthographicCamera, "KeyO");
  }
  setPerspectiveCamera() {
    this.hostObject.cameraController.copyFromActive();
    this.hostObject.cameraController.perspective();
    App2.Instance().dispatchEvent({ type: "render" });
  }
  setOrthographicCamera() {
    this.hostObject.cameraController.copyFromActive();
    this.hostObject.cameraController.orthographic();
    App2.Instance().dispatchEvent({ type: "render" });
  }
}

// studio/src/core/tools/OrbitControls.ts
import {PerspectiveCamera as PerspectiveCamera2, Quaternion as Quaternion2, Spherical, Vector2 as Vector22, Vector3 as Vector37, TOUCH, MOUSE, Color as Color3, Plane, Euler as Euler2} from "three";

// studio/src/utils/debug/debugDraw.ts
import {
Vector3 as Vector36,
Color as Color2,
Material as Material2,
Float32BufferAttribute,
Points,
PointsMaterial,
LineSegments,
LineBasicMaterial,
ArrowHelper,
Box3Helper,
Group as Group2,
SphereGeometry,
MeshBasicMaterial as MeshBasicMaterial2,
Mesh as Mesh2,
Line
} from "three";
import {Text} from "troika-three-text";
var debugDrawClear = (debugObj, options = { debugLines: false, debugPoints: false, debugHelpers: true }) => {
  if (!debugObj.debugHelpers) {
    debugObj.debugHelpers = [];
  }
  if (options.debugLines && debugObj.debugLines) {
    if (debugObj.debugLines.material instanceof Material2)
      debugObj.debugLines.material.dispose();
    debugObj.debugLines.geometry.dispose();
    debugObj.scene?.remove(debugObj.debugLines);
    delete debugObj.debugLines;
  }
  if (options.debugPoints && debugObj.debugPoints) {
    if (debugObj.debugPoints.material instanceof Material2)
      debugObj.debugPoints.material.dispose();
    debugObj.debugPoints.geometry.dispose();
    debugObj.scene?.remove(debugObj.debugLines);
    delete debugObj.debugPoints;
  }
  if (options.debugHelpers && debugObj.debugHelpers) {
    debugObj.debugHelpers.forEach((debugHelper) => debugObj.scene?.remove(debugHelper));
    debugObj.debugHelpers.splice(0);
  }
};
var debugDrawArrow = (debugObj, pos, dir, style) => {
  const { color = 16711680, length: length2 = 1, renderOrder = 200, text } = style;
  const debugArrow = new ArrowHelper(dir, pos, length2, color);
  debugObj?.debugHelpers?.push(debugArrow);
  debugObj?.scene?.add(debugArrow);
  debugArrow.renderOrder = renderOrder;
  debugArrow.frustumCulled = false;
  if (debugArrow.line.material instanceof Material2)
    debugArrow.line.material.depthTest = false;
  if (debugArrow.line.material instanceof Material2)
    debugArrow.line.material.transparent = true;
  debugArrow.line.renderOrder = renderOrder;
  debugArrow.line.frustumCulled = false;
  if (debugArrow.cone.material instanceof Material2)
    debugArrow.cone.material.depthTest = false;
  if (debugArrow.cone.material instanceof Material2)
    debugArrow.cone.material.transparent = true;
  debugArrow.cone.renderOrder = renderOrder;
  debugArrow.cone.frustumCulled = false;
  if (text) {
    debugDrawText(debugObj, pos.clone().add(dir.clone().normalize().multiplyScalar(length2)), text.text, { color, fontSize: text.fontSize, rotation: text.rotation });
  }
  return debugArrow;
};
var debugDrawPoints = (debugObj, points, colors, { size = 5, renderOrder = 200 } = {}) => {
  let object = debugObj?.debugPoints;
  if (!object) {
    object = new Points(undefined, new PointsMaterial({ color: 16777215, vertexColors: true, size }));
    if (object.material instanceof Material2)
      object.material.depthTest = false;
    if (object.material instanceof Material2)
      object.material.transparent = true;
    object.renderOrder = renderOrder;
    object.frustumCulled = false;
  }
  if (debugObj && !debugObj.debugPoints) {
    debugObj.debugPoints = object;
    debugObj.scene?.add(object);
  }
  const colorsArray = [];
  colors.forEach((color) => colorsArray.push(color.r, color.g, color.b));
  object.geometry.setFromPoints(points);
  object.geometry.setAttribute("color", new Float32BufferAttribute(colorsArray, 3));
  return object;
};
var debugDrawPoint = (debugObj, position, { size = 5, renderOrder = 200, color = 16711680 } = {}) => {
  const object = new Points(undefined, new PointsMaterial({ color, size }));
  debugObj?.scene?.add(object);
  debugObj?.debugHelpers?.push(object);
  if (object.material instanceof Material2)
    object.material.depthTest = false;
  if (object.material instanceof Material2)
    object.material.transparent = true;
  object.renderOrder = renderOrder;
  object.frustumCulled = false;
  object.geometry.setFromPoints([position]);
  return object;
};
var debugDrawLine = (debugObj, a, b, { renderOrder = 2000, color = 16711680 }) => {
  const object = new LineSegments(undefined, new LineBasicMaterial({ color, linewidth: 3, linecap: "round", linejoin: "round" }));
  debugObj?.debugHelpers?.push(object);
  debugObj?.scene?.add(object);
  if (object.material instanceof Material2)
    object.material.depthTest = false;
  if (object.material instanceof Material2)
    object.material.transparent = true;
  object.renderOrder = renderOrder;
  object.frustumCulled = false;
  object.geometry.setFromPoints([a, b]);
  return object;
};
var debugDrawText = (debugObj, pos, text, style) => {
  const { color = 16711680, fontSize = 0.05, rotation, onSync } = style;
  const object = new Text;
  debugObj?.debugHelpers?.push(object);
  debugObj?.scene?.add(object);
  object.text = text;
  object.fontSize = fontSize;
  object.position.copy(pos);
  object.renderOrder = Infinity;
  if (rotation === undefined) {
    object.rotation.x = Math.PI * 0.5;
    object.rotation.y = Math.PI;
    object.rotation.z = Math.PI;
  } else {
    object.rotation.copy(rotation);
  }
  object.color = new Color2(color).getHex();
  object.sync(debugObj.onTextSync);
  object.material.depthTest = false;
  return object;
};

// studio/src/core/tools/OrbitControls.ts
class OrbitControls extends ViewportTool {
  minDistance = 0;
  maxDistance = Infinity;
  minZoom = 0;
  maxZoom = Infinity;
  minPolarAngle = 0;
  maxPolarAngle = Math.PI;
  minAzimuthAngle = -Infinity;
  maxAzimuthAngle = Infinity;
  enableZoom = true;
  zoomSpeed = 1;
  enableRotate = true;
  rotateSpeed = 1;
  enablePan = true;
  panSpeed = 1;
  screenSpacePanning = true;
  keyPanSpeed = 7;
  zoomToCursor = false;
  scale = 1;
  zoomChanged = false;
  EPS = 0.000001;
  target;
  spherical = new Spherical;
  offset = new Vector37;
  lastPosition = new Vector37;
  lastQuaternion = new Quaternion2;
  sphericalDelta = new Spherical;
  panOffset = new Vector37;
  v = new Vector37;
  quat = new Quaternion2;
  quatInverse = new Quaternion2;
  dollyDirection = new Vector37;
  mouse = new Vector22;
  performCursorZoom = false;
  debugObjectCamera;
  handlers;
  enabledByTransform = true;
  _enabled = true;
  get enabled() {
    return this._enabled && this.enabledByTransform;
  }
  set enabled(value) {
    if (this._enabled === value)
      return;
    this._enabled = value;
  }
  constructor(viewport) {
    super(viewport);
    this.handlers = new OrbitControlsHandlers(this);
    this.target = this.hostObject.cameraController.getPivotPoint();
    this.update = this.update.bind(this);
    this.onUpdatePivotPoint = this.onUpdatePivotPoint.bind(this);
    this.onChangeCamera = this.onChangeCamera.bind(this);
    this.onSetRollMode = this.onSetRollMode.bind(this);
  }
  get camera() {
    return this.hostObject.camera;
  }
  activate() {
    if (this.debugObjectCamera)
      this.debugObjectCamera = { scene: App2.Instance().view.scene, onTextSync: () => App2.Instance().dispatchEvent({ type: "render" }) };
    const dir = this.hostObject.cameraController.zDir;
    const distance = -dir.dot(this.camera.position);
    const target = this.camera.position.clone().add(dir.clone().multiplyScalar(distance));
    if (Math.abs(target.x - this.camera.position.x) < 0.001 && Math.abs(target.y - this.camera.position.y) < 0.001) {
      target.y += 0.001;
    }
    this.target.set(target.x, target.y, 0);
    this.onChangeCamera({ previous: this.camera, current: this.camera });
    this.handlers.init();
    this.update();
    this.hostObject.addEventListener("cameraChanged", this.onChangeCamera);
    this.hostObject.addEventListener("updatePivotPoint", this.onUpdatePivotPoint);
    this.hostObject.addEventListener("setCameraRollMode", this.onSetRollMode);
  }
  deactivate() {
    this.handlers.dispose();
    this.hostObject.removeEventListener("cameraChanged", this.onChangeCamera);
    this.hostObject.removeEventListener("updatePivotPoint", this.onUpdatePivotPoint);
    this.hostObject.removeEventListener("setCameraRollMode", this.onSetRollMode);
  }
  rollMode = false;
  onSetRollMode(e) {
    this.rollMode = e.state;
  }
  onChangeCamera(event) {
    this.quat.setFromUnitVectors(this.camera.up, new Vector37(0, 1, 0));
    this.quatInverse.copy(this.quat).invert();
  }
  onUpdatePivotPoint(event) {
    this.update(event.fireEvent);
  }
  twoPI = 2 * Math.PI;
  update(fireEvent = true) {
    if (this.debugObjectCamera) {
      debugDrawClear(this.debugObjectCamera);
    }
    const position = this.camera.position;
    this.offset.copy(position).sub(this.target);
    if (this.debugObjectCamera) {
      debugDrawArrow(this.debugObjectCamera, this.target.clone(), this.offset.clone().normalize(), { color: 65280, length: 10 });
    }
    this.offset.applyQuaternion(this.quat);
    if (this.debugObjectCamera) {
      debugDrawArrow(this.debugObjectCamera, this.target.clone(), this.offset.clone().normalize(), { color: 16711680, length: 15 });
    }
    this.spherical.setFromVector3(this.offset);
    this.spherical.theta += this.sphericalDelta.theta;
    this.spherical.phi += this.sphericalDelta.phi;
    let min = this.minAzimuthAngle;
    let max = this.maxAzimuthAngle;
    if (isFinite(min) && isFinite(max)) {
      if (min < -Math.PI)
        min += this.twoPI;
      else if (min > Math.PI)
        min -= this.twoPI;
      if (max < -Math.PI)
        max += this.twoPI;
      else if (max > Math.PI)
        max -= this.twoPI;
      if (min <= max) {
        this.spherical.theta = Math.max(min, Math.min(max, this.spherical.theta));
      } else {
        this.spherical.theta = this.spherical.theta > (min + max) / 2 ? Math.max(min, this.spherical.theta) : Math.min(max, this.spherical.theta);
      }
    }
    this.spherical.phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, this.spherical.phi));
    this.spherical.makeSafe();
    this.spherical.radius *= this.scale;
    this.spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.radius));
    this.target.add(this.panOffset);
    this.offset.setFromSpherical(this.spherical);
    if (this.debugObjectCamera) {
      debugDrawArrow(this.debugObjectCamera, this.target.clone(), this.offset.clone().normalize(), { color: 13434828, length: 20 });
    }
    this.offset.applyQuaternion(this.quatInverse);
    position.copy(this.target).add(this.offset);
    this.camera.lookAt(this.target);
    this.sphericalDelta.set(0, 0, 0);
    this.panOffset.set(0, 0, 0);
    this.scale = 1;
    if (this.debugObjectCamera) {
      const fontSize = 2;
      const rotation = new Euler2().setFromQuaternion(this.camera.quaternion);
      const deltaTarget = this.target.clone().sub(position);
      const dirTarget = deltaTarget.clone().normalize();
      debugDrawArrow(this.debugObjectCamera, this.target.clone(), this.camera.up.clone(), { color: 16764108, length: 15 });
      debugDrawLine(this.debugObjectCamera, position.clone(), this.target.clone(), { color: 16711680 });
      debugDrawPoint(this.debugObjectCamera, position.clone().add(dirTarget.clone().multiplyScalar(0.1)).add(this.camera.up.clone().multiplyScalar(1)), { color: 16711680, size: 5 });
      debugDrawPoint(this.debugObjectCamera, this.target.clone(), { color: 255, size: 5 });
      debugDrawArrow(this.debugObjectCamera, this.target.clone(), this.offset.clone().normalize(), { color: 255, length: 25 });
    }
    if (this.zoomChanged || this.lastPosition.distanceToSquared(this.camera.position) > this.EPS || 8 * (1 - this.lastQuaternion.dot(this.camera.quaternion)) > this.EPS) {
      if (fireEvent)
        App2.Instance().dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
      this.lastPosition.copy(this.camera.position);
      this.lastQuaternion.copy(this.camera.quaternion);
      this.zoomChanged = false;
      return true;
    }
    return false;
  }
  getZoomScale() {
    return Math.pow(0.95, this.zoomSpeed);
  }
  rotateLeft(angle) {
    this.sphericalDelta.theta -= angle;
  }
  rotateUp(angle) {
    this.sphericalDelta.phi -= angle;
  }
  panLeft(distance) {
    this.v.setFromMatrixColumn(this.camera.matrix, 0);
    this.v.multiplyScalar(-distance);
    this.panOffset.add(this.v);
  }
  panUp(distance) {
    if (this.screenSpacePanning) {
      this.v.setFromMatrixColumn(this.camera.matrix, 1);
    } else {
      this.v.setFromMatrixColumn(this.camera.matrix, 0);
      this.v.crossVectors(this.camera.up, this.v);
    }
    this.v.multiplyScalar(distance);
    this.panOffset.add(this.v);
  }
  pan(deltaX, deltaY) {
    if (this.camera instanceof PerspectiveCamera2) {
      const position = this.camera.position;
      this.offset.copy(position).sub(this.target);
      let targetDistance = this.offset.length();
      targetDistance *= Math.tan(this.camera.fov / 2 * Math.PI / 180);
      this.panLeft(2 * deltaX * targetDistance / this.hostObject.height);
      this.panUp(2 * deltaY * targetDistance / this.hostObject.height);
    } else {
      this.panLeft(deltaX * (this.camera.right - this.camera.left) / this.camera.zoom / this.hostObject.height);
      this.panUp(deltaY * (this.camera.top - this.camera.bottom) / this.camera.zoom / this.hostObject.height);
    }
  }
  dollyOut(dollyScale) {
    if (this.camera instanceof PerspectiveCamera2) {
      this.scale /= dollyScale;
    } else {
      this.camera.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom * dollyScale));
      this.camera.updateProjectionMatrix();
      this.zoomChanged = true;
    }
  }
  dollyIn(dollyScale) {
    if (this.camera instanceof PerspectiveCamera2) {
      this.scale *= dollyScale;
    } else {
      this.camera.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom / dollyScale));
      this.camera.updateProjectionMatrix();
      this.zoomChanged = true;
    }
  }
  updateZoomParameters(x, y) {
    if (!this.zoomToCursor)
      return;
    this.performCursorZoom = true;
    const rect = this.hostObject.getDomElement().getBoundingClientRect();
    const dx = x - rect.left;
    const dy = y - rect.top;
    const w = rect.width;
    const h = rect.height;
    this.mouse.x = dx / w * 2 - 1;
    this.mouse.y = -(dy / h) * 2 + 1;
    this.dollyDirection.set(this.mouse.x, this.mouse.y, 1).unproject(this.camera).sub(this.camera.position).normalize();
  }
}

class OrbitControlsHandlers {
  STATE = {
    NONE: -1,
    ROTATE: 0,
    DOLLY: 1,
    PAN: 2,
    TOUCH_ROTATE: 3,
    TOUCH_PAN: 4,
    TOUCH_DOLLY_PAN: 5,
    TOUCH_DOLLY_ROTATE: 6
  };
  state = this.STATE.NONE;
  rotateStart = new Vector22;
  rotateEnd = new Vector22;
  rotateDelta = new Vector22;
  panStart = new Vector22;
  panEnd = new Vector22;
  panDelta = new Vector22;
  dollyStart = new Vector22;
  dollyEnd = new Vector22;
  dollyDelta = new Vector22;
  orbitControls;
  pointers = [];
  pointerPositions = {};
  controlActive = false;
  mouseButtons = { LEFT: MOUSE.ROTATE, MIDDLE: MOUSE.DOLLY, RIGHT: MOUSE.PAN };
  touches = { ONE: TOUCH.ROTATE, TWO: TOUCH.DOLLY_PAN };
  tmpQuaternion = new Quaternion2;
  quaternionStart = new Quaternion2;
  rotationAxis = new Vector37;
  angleStart = 0;
  pickPlane = new Plane;
  debugObjectPickPoint;
  constructor(orbitControls) {
    this.orbitControls = orbitControls;
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onStartMove = this.onStartMove.bind(this);
    if (this.debugObjectPickPoint)
      this.debugObjectPickPoint = { scene: App2.Instance().view.scene };
  }
  get viewport() {
    return this.orbitControls.hostObject;
  }
  get domElementMouseDown() {
    return this.viewport.domElement;
  }
  get domElementMouseMove() {
    return document;
  }
  get domElementMouseUp() {
    return document;
  }
  get domElementMouseWheel() {
    return this.viewport.domElement;
  }
  init() {
    App2.Instance().mouseController.addListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "left" /* left */);
    App2.Instance().mouseController.addListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "right" /* right */);
    App2.Instance().mouseController.addListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "middle" /* middle */);
    App2.Instance().mouseController.addListener("wheel", this.onMouseWheel, this.domElementMouseWheel);
    App2.Instance().addEventListener("transformStart", this.onTransformStart);
    App2.Instance().addEventListener("transformEnd", this.onTransformEnd);
  }
  dispose() {
    App2.Instance().mouseController.removeListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "left" /* left */);
    App2.Instance().mouseController.removeListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "middle" /* middle */);
    App2.Instance().mouseController.removeListener("pointerdown", this.onPointerDown, this.domElementMouseDown, "right" /* right */);
    App2.Instance().mouseController.removeListener("wheel", this.onMouseWheel, this.domElementMouseWheel);
    App2.Instance().removeEventListener("transformStart", this.onTransformStart);
    App2.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformStart() {
    this.orbitControls.enabledByTransform = false;
  }
  onTransformEnd() {
    this.orbitControls.enabledByTransform = true;
  }
  handleMouseDownRotate(event) {
    this.rotateStart.set(event.clientX, event.clientY);
  }
  handleMouseDownDolly(event) {
    this.dollyStart.set(event.clientX, event.clientY);
  }
  handleMouseDownPan(event) {
    if (this.orbitControls.rollMode) {
      const pivotPoint = this.orbitControls.hostObject.cameraController.getPivotPoint();
      this.pickPlane.setFromNormalAndCoplanarPoint(this.orbitControls.hostObject.cameraController.zDir, pivotPoint);
      const raycaster = App2.Instance().raycastController.raycaster;
      raycaster.setFromCamera(App2.Instance().raycastController.position, this.orbitControls.hostObject.camera);
      const pickPoint = raycaster.ray.intersectPlane(this.pickPlane, new Vector37);
      if (!pickPoint) {
        console.warn("onRightMouseDown: no pickPoint");
        return;
      }
      if (this.debugObjectPickPoint) {
        debugDrawClear(this.debugObjectPickPoint);
        debugDrawPoints(this.debugObjectPickPoint, [pickPoint.clone(), pivotPoint.clone()], [new Color3(1, 1, 0), new Color3(1, 0, 0)]);
      }
      const origin = pivotPoint;
      const deltaV3 = pickPoint.clone().sub(origin);
      const deltaN = deltaV3.clone().normalize();
      const angle = Math.atan2(deltaN.dot(this.orbitControls.hostObject.cameraController.yDir), deltaN.dot(this.orbitControls.hostObject.cameraController.xDir));
      this.angleStart = angle;
      this.rotationAxis.copy(this.orbitControls.hostObject.cameraController.zDir);
      this.quaternionStart.copy(this.orbitControls.hostObject.camera.quaternion);
    } else {
      this.panStart.set(event.clientX, event.clientY);
    }
  }
  handleMouseMoveRotate(event) {
    this.rotateEnd.set(event.clientX, event.clientY);
    this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.orbitControls.rotateSpeed);
    const element = this.orbitControls.hostObject.domElement;
    this.orbitControls.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientHeight);
    this.orbitControls.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight);
    this.rotateStart.copy(this.rotateEnd);
    this.orbitControls.update();
  }
  handleMouseMoveDolly(event) {
    this.dollyEnd.set(event.clientX, event.clientY);
    this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);
    if (this.dollyDelta.y > 0) {
      this.orbitControls.dollyOut(this.orbitControls.getZoomScale());
    } else if (this.dollyDelta.y < 0) {
      this.orbitControls.dollyIn(this.orbitControls.getZoomScale());
    }
    this.dollyStart.copy(this.dollyEnd);
    this.orbitControls.update();
  }
  handleMouseMovePan(event) {
    if (this.orbitControls.rollMode) {
      const pivotPoint = this.orbitControls.hostObject.cameraController.getPivotPoint();
      const raycaster = App2.Instance().raycastController.raycaster;
      raycaster.setFromCamera(App2.Instance().raycastController.position, this.orbitControls.hostObject.camera);
      const pickPoint = raycaster.ray.intersectPlane(this.pickPlane, new Vector37);
      if (!pickPoint) {
        console.warn("onMouseMove: no pickPoint");
        return;
      }
      if (this.debugObjectPickPoint) {
        debugDrawClear(this.debugObjectPickPoint);
        debugDrawPoints(this.debugObjectPickPoint, [pickPoint.clone()], [new Color3(0, 0, 1)]);
      }
      const origin = pivotPoint;
      const deltaV3 = pickPoint.clone().sub(origin);
      const deltaN = deltaV3.clone().normalize();
      const angle = Math.atan2(deltaN.dot(this.orbitControls.hostObject.cameraController.yDir), deltaN.dot(this.orbitControls.hostObject.cameraController.xDir));
      const angleDelta = angle - this.angleStart;
      this.tmpQuaternion.setFromAxisAngle(this.rotationAxis, -angleDelta).normalize();
      const q = this.tmpQuaternion.clone().multiply(this.quaternionStart).normalize();
      this.orbitControls.hostObject.camera.quaternion.copy(q);
      this.orbitControls.camera.up.copy(this.orbitControls.hostObject.cameraController.yDir);
      this.orbitControls.onChangeCamera({ previous: this.orbitControls.camera, current: this.orbitControls.camera });
      this.orbitControls.update();
    } else {
      this.panEnd.set(event.clientX, event.clientY);
      this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.orbitControls.panSpeed);
      this.orbitControls.pan(this.panDelta.x, this.panDelta.y);
      this.panStart.copy(this.panEnd);
      this.orbitControls.update();
    }
  }
  wheelEventEndTimeout;
  handleMouseWheel(event) {
    if (event.deltaY < 0) {
      this.orbitControls.dollyIn(this.orbitControls.getZoomScale());
    } else if (event.deltaY > 0) {
      this.orbitControls.dollyOut(this.orbitControls.getZoomScale());
    }
    this.orbitControls.update();
  }
  handleTouchStartRotate(event) {
    if (this.pointers.length === 1) {
      this.rotateStart.set(event.pageX, event.pageY);
    } else {
      const position = this.getSecondPointerPosition(event);
      const x = 0.5 * (event.pageX + position.x);
      const y = 0.5 * (event.pageY + position.y);
      this.rotateStart.set(x, y);
    }
  }
  handleTouchStartPan(event) {
    if (this.pointers.length === 1) {
      this.panStart.set(event.pageX, event.pageY);
    } else {
      const position = this.getSecondPointerPosition(event);
      const x = 0.5 * (event.pageX + position.x);
      const y = 0.5 * (event.pageY + position.y);
      this.panStart.set(x, y);
    }
  }
  handleTouchStartDolly(event) {
    const position = this.getSecondPointerPosition(event);
    const dx = event.pageX - position.x;
    const dy = event.pageY - position.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    this.dollyStart.set(0, distance);
  }
  handleTouchStartDollyPan(event) {
    if (this.orbitControls.enableZoom)
      this.handleTouchStartDolly(event);
    if (this.orbitControls.enablePan)
      this.handleTouchStartPan(event);
  }
  handleTouchStartDollyRotate(event) {
    if (this.orbitControls.enableZoom)
      this.handleTouchStartDolly(event);
    if (this.orbitControls.enableRotate)
      this.handleTouchStartRotate(event);
  }
  handleTouchMoveRotate(event) {
    if (this.pointers.length == 1) {
      this.rotateEnd.set(event.pageX, event.pageY);
    } else {
      const position = this.getSecondPointerPosition(event);
      const x = 0.5 * (event.pageX + position.x);
      const y = 0.5 * (event.pageY + position.y);
      this.rotateEnd.set(x, y);
    }
    this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.orbitControls.rotateSpeed);
    const element = this.orbitControls.hostObject.getDomElement();
    this.orbitControls.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientHeight);
    this.orbitControls.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight);
    this.rotateStart.copy(this.rotateEnd);
  }
  handleTouchMovePan(event) {
    if (this.pointers.length === 1) {
      this.panEnd.set(event.pageX, event.pageY);
    } else {
      const position = this.getSecondPointerPosition(event);
      const x = 0.5 * (event.pageX + position.x);
      const y = 0.5 * (event.pageY + position.y);
      this.panEnd.set(x, y);
    }
    this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.orbitControls.panSpeed);
    this.orbitControls.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
  }
  handleTouchMoveDolly(event) {
    const position = this.getSecondPointerPosition(event);
    const dx = event.pageX - position.x;
    const dy = event.pageY - position.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    this.dollyEnd.set(0, distance);
    this.dollyDelta.set(0, Math.pow(this.dollyEnd.y / this.dollyStart.y, this.orbitControls.zoomSpeed));
    this.orbitControls.dollyOut(this.dollyDelta.y);
    this.dollyStart.copy(this.dollyEnd);
    const centerX = (event.pageX + position.x) * 0.5;
    const centerY = (event.pageY + position.y) * 0.5;
    this.orbitControls.updateZoomParameters(centerX, centerY);
  }
  handleTouchMoveDollyPan(event) {
    if (this.orbitControls.enableZoom)
      this.handleTouchMoveDolly(event);
    if (this.orbitControls.enablePan)
      this.handleTouchMovePan(event);
  }
  handleTouchMoveDollyRotate(event) {
    if (this.orbitControls.enableZoom)
      this.handleTouchMoveDolly(event);
    if (this.orbitControls.enableRotate)
      this.handleTouchMoveRotate(event);
  }
  onStartMove(event) {
    App2.Instance().dispatchEvent({ type: "transformCameraStart", viewport: this.orbitControls.hostObject });
    App2.Instance().mouseController.removeListener("pointermove", this.onStartMove, this.domElementMouseMove);
    const mask = {
      1: "left",
      2: "right",
      4: "middle"
    };
    let count = 0;
    for (let key in mask) {
      if (event.buttons & Number(key)) {
        count++;
        const onEndMove = () => {
          count--;
          if (count === 0) {
            App2.Instance().dispatchEvent({ type: "transformCameraEnd", viewport: this.orbitControls.hostObject });
          }
          App2.Instance().mouseController.removeListener("pointerup", onEndMove, this.domElementMouseUp, mask[key]);
        };
        App2.Instance().mouseController.addListener("pointerup", onEndMove, this.domElementMouseUp, mask[key]);
      }
    }
  }
  onPointerDown(e) {
    const event = e;
    if (this.orbitControls.enabled === false)
      return;
    if (this.pointers.length === 0) {
      App2.Instance().mouseController.addListener("pointermove", this.onPointerMove, this.domElementMouseMove);
      App2.Instance().mouseController.addListener("pointerup", this.onPointerUp, this.domElementMouseUp, "left" /* left */);
      App2.Instance().mouseController.addListener("pointerup", this.onPointerUp, this.domElementMouseUp, "right" /* right */);
      App2.Instance().mouseController.addListener("pointerup", this.onPointerUp, this.domElementMouseUp, "middle" /* middle */);
    }
    if (this.isTrackingPointer(event))
      return;
    this.addPointer(event);
    if (event.pointerType === "touch") {
      this.onTouchStart(event);
    } else {
      this.onMouseDown(event);
    }
    App2.Instance().mouseController.addListener("pointermove", this.onStartMove, this.domElementMouseMove);
  }
  onPointerUp(e) {
    const event = e;
    this.removePointer(event);
    switch (this.pointers.length) {
      case 0:
        App2.Instance().mouseController.removeListener("pointermove", this.onPointerMove, this.domElementMouseMove);
        App2.Instance().mouseController.removeListener("pointerup", this.onPointerUp, this.domElementMouseUp, "left" /* left */);
        App2.Instance().mouseController.removeListener("pointerup", this.onPointerUp, this.domElementMouseUp, "right" /* right */);
        App2.Instance().mouseController.removeListener("pointerup", this.onPointerUp, this.domElementMouseUp, "middle" /* middle */);
        this.state = this.STATE.NONE;
        break;
      case 1:
        const pointerId = this.pointers[0];
        const position = this.pointerPositions[pointerId];
        this.onTouchStart({ pointerId, pageX: position.x, pageY: position.y });
        break;
    }
    App2.Instance().mouseController.removeListener("pointermove", this.onStartMove, this.domElementMouseMove);
  }
  onPointerMove(e) {
    const event = e;
    if (this.orbitControls.enabled === false)
      return;
    if (event.pointerType === "touch") {
      this.onTouchMove(event);
    } else {
      this.onMouseMove(event);
    }
  }
  onMouseDown(event) {
    let mouseAction;
    switch (event.button) {
      case 0:
        mouseAction = this.mouseButtons.LEFT;
        break;
      case 1:
        mouseAction = this.mouseButtons.MIDDLE;
        break;
      case 2:
        mouseAction = this.mouseButtons.RIGHT;
        break;
      default:
        mouseAction = -1;
    }
    switch (mouseAction) {
      case MOUSE.DOLLY:
        if (this.orbitControls.enableZoom === false)
          return;
        this.handleMouseDownDolly(event);
        this.state = this.STATE.DOLLY;
        break;
      case MOUSE.ROTATE:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.orbitControls.enablePan === false)
            return;
          this.handleMouseDownPan(event);
          this.state = this.STATE.PAN;
        } else {
          if (this.orbitControls.enableRotate === false)
            return;
          this.handleMouseDownRotate(event);
          this.state = this.STATE.ROTATE;
        }
        break;
      case MOUSE.PAN:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.orbitControls.enableRotate === false)
            return;
          this.handleMouseDownRotate(event);
          this.state = this.STATE.ROTATE;
        } else {
          if (this.orbitControls.enablePan === false)
            return;
          this.handleMouseDownPan(event);
          this.state = this.STATE.PAN;
        }
        break;
      default:
        this.state = this.STATE.NONE;
    }
  }
  onMouseMove(event) {
    switch (this.state) {
      case this.STATE.ROTATE:
        if (this.orbitControls.enableRotate === false)
          return;
        this.handleMouseMoveRotate(event);
        break;
      case this.STATE.DOLLY:
        if (this.orbitControls.enableZoom === false)
          return;
        this.handleMouseMoveDolly(event);
        break;
      case this.STATE.PAN:
        if (this.orbitControls.enablePan === false)
          return;
        this.handleMouseMovePan(event);
        break;
    }
  }
  onMouseWheel(event) {
    if (this.orbitControls.enabled === false || this.orbitControls.enableZoom === false || this.state !== this.STATE.NONE)
      return;
    event.preventDefault();
    this.handleMouseWheel(this.customWheelEvent(event));
  }
  customWheelEvent(event) {
    const mode = event.deltaMode;
    const newEvent = { clientX: event.clientX, clientY: event.clientY, deltaY: event.deltaY };
    switch (mode) {
      case 1:
        newEvent.deltaY *= 16;
        break;
      case 2:
        newEvent.deltaY *= 100;
        break;
    }
    if (event.ctrlKey && !this.controlActive) {
      newEvent.deltaY *= 10;
    }
    return newEvent;
  }
  onTouchStart(event) {
    this.trackPointer(event);
    switch (this.pointers.length) {
      case 1:
        switch (this.touches.ONE) {
          case TOUCH.ROTATE:
            if (this.orbitControls.enableRotate === false)
              return;
            this.handleTouchStartRotate(event);
            this.state = this.STATE.TOUCH_ROTATE;
            break;
          case TOUCH.PAN:
            if (this.orbitControls.enablePan === false)
              return;
            this.handleTouchStartPan(event);
            this.state = this.STATE.TOUCH_PAN;
            break;
          default:
            this.state = this.STATE.NONE;
        }
        break;
      case 2:
        switch (this.touches.TWO) {
          case TOUCH.DOLLY_PAN:
            if (this.orbitControls.enableZoom === false && this.orbitControls.enablePan === false)
              return;
            this.handleTouchStartDollyPan(event);
            this.state = this.STATE.TOUCH_DOLLY_PAN;
            break;
          case TOUCH.DOLLY_ROTATE:
            if (this.orbitControls.enableZoom === false && this.orbitControls.enableRotate === false)
              return;
            this.handleTouchStartDollyRotate(event);
            this.state = this.STATE.TOUCH_DOLLY_ROTATE;
            break;
          default:
            this.state = this.STATE.NONE;
        }
        break;
      default:
        this.state = this.STATE.NONE;
    }
  }
  onTouchMove(event) {
    this.trackPointer(event);
    switch (this.state) {
      case this.STATE.TOUCH_ROTATE:
        if (this.orbitControls.enableRotate === false)
          return;
        this.handleTouchMoveRotate(event);
        this.orbitControls.update();
        break;
      case this.STATE.TOUCH_PAN:
        if (this.orbitControls.enablePan === false)
          return;
        this.handleTouchMovePan(event);
        this.orbitControls.update();
        break;
      case this.STATE.TOUCH_DOLLY_PAN:
        if (this.orbitControls.enableZoom === false && this.orbitControls.enablePan === false)
          return;
        this.handleTouchMoveDollyPan(event);
        this.orbitControls.update();
        break;
      case this.STATE.TOUCH_DOLLY_ROTATE:
        if (this.orbitControls.enableZoom === false && this.orbitControls.enableRotate === false)
          return;
        this.handleTouchMoveDollyRotate(event);
        this.orbitControls.update();
        break;
      default:
        this.state = this.STATE.NONE;
    }
  }
  addPointer(event) {
    this.pointers.push(event.pointerId);
  }
  removePointer(event) {
    delete this.pointerPositions[event.pointerId];
    for (let i = 0;i < this.pointers.length; i++) {
      if (this.pointers[i] == event.pointerId) {
        this.pointers.splice(i, 1);
        return;
      }
    }
  }
  isTrackingPointer(event) {
    for (let i = 0;i < this.pointers.length; i++) {
      if (this.pointers[i] == event.pointerId)
        return true;
    }
    return false;
  }
  trackPointer(event) {
    let position = this.pointerPositions[event.pointerId];
    if (position === undefined) {
      position = new Vector22;
      this.pointerPositions[event.pointerId] = position;
    }
    position.set(event.pageX, event.pageY);
  }
  getSecondPointerPosition(event) {
    const pointerId = event.pointerId === this.pointers[0] ? this.pointers[1] : this.pointers[0];
    return this.pointerPositions[pointerId];
  }
}

// studio/src/products/mpr/tools/MprVolumeSliceSpawner.ts
import {Box2, OrthographicCamera as OrthographicCamera3, Vector2 as Vector23} from "three";

// studio/src/products/mpr/entities/VolumeSlice.ts
import {Box3 as Box35, Box3Helper as Box3Helper2, BufferGeometry, Color as Color4, Line as Line2, LineBasicMaterial as LineBasicMaterial2, Mesh as Mesh3, MeshBasicMaterial as MeshBasicMaterial3, Object3D as Object3D4, Plane as Plane2, PlaneGeometry, Triangle, Vector3 as Vector311} from "three";

// studio/src/core/components/ViewportComponent.ts
class ViewportComponent extends Component {
  viewports;
  constructor(entity) {
    super(entity);
    this.viewports = new Set;
  }
  addViewport = (name, recursive = true) => {
    const viewport = name instanceof Viewport ? name : App2.Instance().view.getViewport(name);
    if (viewport) {
      const cameraLayer = viewport.cameraLayer;
      if (cameraLayer) {
        this.viewports.add(viewport);
        this.entity.object3d.layers.enable(cameraLayer);
        this.entity.object3d.layers.disable(0);
        if (recursive) {
          this.entity.children.forEach((entity) => {
            const component = entity.getComponent(ViewportComponent);
            component?.addViewport(name, true);
          });
        }
      }
    }
  };
  deleteViewport = (name, recursive = true) => {
    const viewport = name instanceof Viewport ? name : App2.Instance().view.getViewport(name);
    if (viewport) {
      const cameraLayer = viewport.cameraLayer;
      if (cameraLayer) {
        this.viewports.delete(viewport);
        this.entity.object3d.layers.disable(cameraLayer);
        if (recursive) {
          this.entity.children.forEach((entity) => {
            const component = entity.getComponent(ViewportComponent);
            component?.deleteViewport(name, true);
          });
        }
      }
    }
  };
  deleteAllViewports(recursive = true) {
    this.viewports.clear();
    this.entity.object3d.layers.set(0);
    if (recursive) {
      this.entity.children.forEach((entity) => {
        const component = entity.getComponent(ViewportComponent);
        component?.deleteAllViewports(true);
      });
    }
  }
  hasViewport(name) {
    const viewport = name instanceof Viewport ? name : App2.Instance().view.getViewport(name);
    return this.viewports.has(viewport);
  }
}

// studio/src/utils/debug/DebugUtils.ts
import {Group as Group3} from "three";
var getContainer = (name) => {
  const scene = App2.Instance().view.scene;
  let container = scene.getObjectByName(name);
  if (!container) {
    container = new Group3;
    container.name = name;
    scene.add(container);
    return container;
  }
  return container;
};
var DebugUtils = { getContainer };

// studio/src/utils/materials/SliceMaterial.ts
import {DoubleSide, Matrix4 as Matrix43, RawShaderMaterial, Vector3 as Vector38} from "three";

// studio/src/utils/shaders/mpr/slice.vs.glsl
var slice_vs_default = "#version 300 es\nprecision mediump float;\n\nuniform mat4 modelMatrix;\nuniform mat4 projectionMatrix;\nuniform mat4 viewMatrix;\n\nuniform mat4 xyztoijk;\nin vec4 position;\nout vec3 ijk;\n\nvoid main(){\n    // Apply translation, rotation and scale\n    vec4 xyz = modelMatrix * position;\n    // Transform xyz in terms of ijk\n    ijk = (xyztoijk * xyz).xyz;\n    // Clip-space vertex position\n    gl_Position = projectionMatrix * viewMatrix * xyz;\n}\n";

// studio/src/utils/shaders/mpr/slice.fs.glsl
var slice_fs_default = "#version 300 es\nprecision highp float;\nuniform mediump sampler3D volume;\nuniform float ww; // window width (WW)\nuniform float wmin; // window minimum = window level (WL) - half window width\nuniform float contrast; // https://github.com/evanw/glfx.js/blob/master/src/filters/adjust/brightnesscontrast.js\nuniform float brightness; // https://evanw.github.io/webgl-filter\nin vec3 ijk;\n\nlayout(location = 0) out vec4 fragColor;\n\nvoid main() {\n\t// Compare ijk coordinate with boundaries\n    vec3 inBounds = step(vec3(0.0), ijk) * step(ijk, vec3(1.0));\n\t// If any component is out-of-bounds then discard\n    if (inBounds.x * inBounds.y * inBounds.z < 1.0) {\n        discard;\n    }\n\t// get r component because texture have meaningful data in red channel only\n    float value = texture(volume, ijk).r;\n\t// windowing\n    float v = (value - wmin) / ww;\n\n    fragColor = vec4(v, v, v, 1.0);\n\n    fragColor.rgb += brightness;\n    if (contrast > 0.0) {\n        fragColor.rgb = (fragColor.rgb - 0.5) / (1.0 - contrast) + 0.5;\n    } else {\n        fragColor.rgb = (fragColor.rgb - 0.5) * (1.0 + contrast) + 0.5;\n    }\n}\n";

// studio/src/utils/materials/SliceMaterial.ts
class SliceMaterial extends RawShaderMaterial {
  users = [];
  constructor(data3DTexture, box, ww = 3200, wc = 1000) {
    const size = box.getSize(new Vector38);
    const scale = new Vector38(1, 1, 1).divide(size);
    const translate = box.min.clone().negate().divide(size);
    const xyz_to_ijk = new Matrix43().makeScale(scale.x, scale.y, scale.z).setPosition(translate);
    super({
      side: DoubleSide,
      vertexShader: slice_vs_default,
      fragmentShader: slice_fs_default,
      uniforms: {
        volume: { value: data3DTexture },
        xyztoijk: { value: xyz_to_ijk },
        ww: { value: ww },
        wmin: { value: wc - ww / 2 },
        contrast: { value: 0 },
        brightness: { value: 0 }
      }
    });
  }
  setWWWC(ww, wc) {
    this.uniforms.ww.value = ww;
    this.uniforms.wmin.value = wc - ww / 2;
  }
  increaseUsers(user) {
    if (!this.users.includes(user))
      this.users.push(user);
    else
      console.warn(this.constructor.name + ".increaseUsers: user is already added = ", user, this);
  }
  decreaseUsers(user) {
    const userIndex = this.users.indexOf(user);
    if (userIndex !== -1)
      this.users.splice(userIndex, 1);
    else
      console.warn(this.constructor.name + ".decreaseUsers: no user = ", user, this);
  }
  destroy(user) {
    this.decreaseUsers(user);
    if (this.users.length === 0) {
      this.dispose();
    }
  }
}

// studio/src/products/mpr/utils/Texture3DLoader.ts
import {Box3 as Box34, Data3DTexture as Data3DTexture2, FloatType, LinearFilter, RedFormat, Vector3 as Vector310} from "three";

// src/utils/parseNRRD.js
function unescapeValue(val) {
  return val.split("\\\\").map(function(s) {
    return s.replace("\\n", "\n");
  }).join("\\");
}
function parseField(identifier, descriptor) {
  switch (identifier) {
    case "content":
    case "number":
    case "sampleUnits":
      break;
    case "dimension":
    case "blockSize":
    case "lineSkip":
    case "byteSkip":
    case "spaceDimension":
      descriptor = parseNRRDInteger(descriptor);
      break;
    case "min":
    case "max":
    case "oldMin":
    case "oldMax":
      descriptor = parseNRRDFloat(descriptor);
      break;
    case "spaceOrigin":
      descriptor = parseNRRDVector(descriptor);
      break;
    case "labels":
    case "units":
    case "spaceUnits":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDQuotedString);
      break;
    case "sizes":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDInteger);
      break;
    case "spacings":
    case "thicknesses":
    case "axisMins":
    case "axisMaxs":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDFloat);
      break;
    case "spaceDirections":
    case "measurementFrame":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDVector);
      break;
    case "type":
      descriptor = parseNRRDType(descriptor);
      break;
    case "encoding":
      descriptor = parseNRRDEncoding(descriptor);
      break;
    case "endian":
      descriptor = parseNRRDEndian(descriptor);
      break;
    case "dataFile":
      descriptor = parseNRRDDataFile(descriptor);
      break;
    case "centers":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDCenter);
      break;
    case "kinds":
      descriptor = parseNRRDWhitespaceSeparatedList(descriptor, parseNRRDKind);
      break;
    case "space":
      descriptor = parseNRRDSpace(descriptor);
      break;
    default:
      console.warn("Unrecognized NRRD field: " + identifier);
  }
  return descriptor;
}
function mapNRRDToJavascript(id) {
  id = id.toLowerCase();
  if (id in mapNRRDToJavascriptStatic)
    return mapNRRDToJavascriptStatic[id];
  return id;
}
function parseNRRDInteger(str) {
  var val = parseInt(str, 10);
  if (Number.isNaN(val))
    throw new Error("Malformed NRRD integer: " + str);
  return val;
}
function parseNRRDFloat(str) {
  str = str.toLowerCase();
  if (str.indexOf("nan") >= 0)
    return NaN;
  if (str.indexOf("-inf") >= 0)
    return -Infinity;
  if (str.indexOf("inf") >= 0)
    return Infinity;
  var val = parseFloat(str);
  if (Number.isNaN(val))
    throw new Error("Malformed NRRD float: " + str);
  return val;
}
function parseNRRDVector(str) {
  if (str == "none")
    return null;
  if (str.length < 2 || str[0] !== "(" || str[str.length - 1] !== ")")
    throw new Error("Malformed NRRD vector: " + str);
  return str.slice(1, -1).split(",").map(parseNRRDFloat);
}
function parseNRRDQuotedString(str) {
  if (length < 2 || str[0] != '"' || str[str.length - 1] != '"') {
    throw new Error("Invalid NRRD quoted string: " + str);
  }
  return str.slice(1, -1).replace('\\"', '"');
}
function parseNRRDWhitespaceSeparatedList(str, parseElement) {
  return str.split(whitespaceListSeparator).map(parseElement);
}
function parseNRRDType(descriptor) {
  switch (descriptor.toLowerCase()) {
    case "signed char":
    case "int8":
    case "int8_t":
      return "int8";
    case "uchar":
    case "unsigned char":
    case "uint8":
    case "uint8_t":
      return "uint8";
    case "short":
    case "short int":
    case "signed short":
    case "signed short int":
    case "int16":
    case "int16_t":
      return "int16";
    case "ushort":
    case "unsigned short":
    case "unsigned short int":
    case "uint16":
    case "uint16_t":
      return "uint16";
    case "int":
    case "signed int":
    case "int32":
    case "int32_t":
      return "int32";
    case "uint":
    case "unsigned int":
    case "uint32":
    case "uint32_t":
      return "uint32";
    case "longlong":
    case "long long":
    case "long long int":
    case "signed long long":
    case "signed long long int":
    case "int64":
    case "int64_t":
      return "int64";
    case "ulonglong":
    case "unsigned long long":
    case "unsigned long long int":
    case "uint64":
    case "uint64_t":
      return "uint64";
    case "float":
      return "float";
    case "double":
      return "double";
    case "block":
      return "block";
    default:
      console.warn("Unrecognized NRRD type: " + descriptor);
      return descriptor;
  }
}
function parseNRRDEncoding(encoding) {
  switch (encoding.toLowerCase()) {
    case "raw":
      return "raw";
    case "txt":
    case "text":
    case "ascii":
      return "ascii";
    case "hex":
      return "hex";
    case "gz":
    case "gzip":
      return "gzip";
    case "bz2":
    case "bzip2":
      return "bzip2";
    default:
      console.warn("Unrecognized NRRD encoding: " + encoding);
      return encoding;
  }
}
function parseNRRDSpace(space) {
  switch (space.toLowerCase()) {
    case "right-anterior-superior":
    case "ras":
      return "right-anterior-superior";
    case "left-anterior-superior":
    case "las":
      return "left-anterior-superior";
    case "left-posterior-superior":
    case "lps":
      return "left-posterior-superior";
    case "right-anterior-superior-time":
    case "rast":
      return "right-anterior-superior-time";
    case "left-anterior-superior-time":
    case "last":
      return "left-anterior-superior-time";
    case "left-posterior-superior-time":
    case "lpst":
      return "left-posterior-superior-time";
    case "scanner-xyz":
      return "scanner-xyz";
    case "scanner-xyz-time":
      return "scanner-xyz-time";
    case "3d-right-handed":
      return "3D-right-handed";
    case "3d-left-handed":
      return "3D-left-handed";
    case "3d-right-handed-time":
      return "3D-right-handed-time";
    case "3d-left-handed-time":
      return "3D-left-handed-time";
    default:
      console.warn("Unrecognized space: " + space);
      return space;
  }
}
function parseNRRDEndian(endian) {
  switch (endian.toLowerCase()) {
    case "little":
      return "little";
    case "big":
      return "big";
    default:
      console.warn("Unrecognized NRRD endianness: " + endian);
      return endian;
  }
}
function parseNRRDDataFile(dataFile) {
  var match = dataFileFormatRE.exec(dataFile);
  if (match) {
    if (match.length == 5 && match[4]) {
      return {
        format: dataFile.substring(0, match.index),
        min: parseNRRDInteger(match[1]),
        max: parseNRRDInteger(match[2]),
        step: parseNRRDInteger(match[3]),
        subdim: parseNRRDInteger(match[4])
      };
    } else {
      return {
        format: dataFile.substring(0, match.index),
        min: parseNRRDInteger(match[1]),
        max: parseNRRDInteger(match[2]),
        step: parseNRRDInteger(match[3])
      };
    }
  } else {
    return dataFile;
  }
}
function parseNRRDCenter(center) {
  switch (center.toLowerCase()) {
    case "cell":
      return "cell";
    case "node":
      return "node";
    case "???":
    case "none":
      return null;
    default:
      console.warn("Unrecognized NRRD center: " + center);
      return center;
  }
}
function parseNRRDKind(kind) {
  var kindLC = kind.toLowerCase();
  if (kindLC in NRRDKinds)
    return NRRDKinds[kindLC];
  console.warn("Unrecognized NRRD kind: " + kind);
  return kind;
}
function parseNRRDRawData(buffer, type, sizes, options) {
  var i, arr, view, totalLen = 1, endianFlag;
  for (i = 0;i < sizes.length; i++) {
    if (sizes[i] <= 0)
      throw new Error("Sizes should be a list of positive (>0) integers!");
    totalLen *= sizes[i];
  }
  if (type == "block") {
    return buffer.slice(0, totalLen * options.blockSize);
  } else if (type == "int8" || type == "uint8" || options.endian == systemEndianness) {
    switch (type) {
      case "int8":
        checkSize(1);
        return new Int8Array(buffer.slice(0, totalLen));
      case "uint8":
        checkSize(1);
        return new Uint8Array(buffer.slice(0, totalLen));
      case "int16":
        checkSize(2);
        return new Int16Array(buffer.slice(0, totalLen * 2));
      case "uint16":
        checkSize(2);
        return new Uint16Array(buffer.slice(0, totalLen * 2));
      case "int32":
        checkSize(4);
        return new Int32Array(buffer.slice(0, totalLen * 4));
      case "uint32":
        checkSize(4);
        return new Uint32Array(buffer.slice(0, totalLen * 4));
      case "float":
        checkSize(4);
        return new Float32Array(buffer.slice(0, totalLen * 4));
      case "double":
        checkSize(8);
        return new Float64Array(buffer.slice(0, totalLen * 8));
      default:
        console.warn("Unsupported NRRD type: " + type + ", returning raw buffer.");
        return;
    }
  } else {
    switch (options.endian) {
      case "big":
        endianFlag = false;
        break;
      case "little":
        endianFlag = true;
        break;
      default:
        console.warn("Unsupported endianness in NRRD file: " + options.endian);
        return;
    }
    view = new DataView(buffer);
    switch (type) {
      case "int8":
        arr = new Int8Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getInt8(i);
        }
        return arr;
      case "uint8":
        arr = new Uint8Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getUint8(i);
        }
        return arr;
      case "int16":
        arr = new Int16Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getInt16(i * 2);
        }
        return arr;
      case "uint16":
        arr = new Uint16Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getUint16(i * 2);
        }
        return arr;
      case "int32":
        arr = new Int32Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getInt32(i * 4);
        }
        return arr;
      case "uint32":
        arr = new Uint32Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getUint32(i * 4);
        }
        return arr;
      case "float":
        arr = new Float32Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getFloat32(i * 4);
        }
        return arr;
      case "double":
        arr = new Float64Array(totalLen);
        for (i = 0;i < totalLen; i++) {
          arr[i] = view.getFloat64(i * 8);
        }
        return arr;
      default:
        console.warn("Unsupported NRRD type: " + type + ", returning raw buffer.");
        return;
    }
  }
  function checkSize(sizeOfType) {
    if (buffer.byteLength < totalLen * sizeOfType)
      throw new Error("NRRD file does not contain enough data!");
  }
}
function parseNRRDTextData(buffer, type, sizes) {
  var i, buf8, str, strList, totalLen = 1;
  for (i = 0;i < sizes.length; i++) {
    if (sizes[i] <= 0)
      throw new Error("Sizes should be a list of positive (>0) integers!");
    totalLen *= sizes[i];
  }
  buf8 = new Uint8Array(buffer);
  str = String.fromCharCode.apply(null, buf8);
  strList = str.split(whitespaceDataValueListSeparatorRE);
  if (strList.length < totalLen) {
    throw new Error("Not enough data in NRRD file!");
  } else if (strList.length > totalLen) {
    if (strList[0] === "")
      strList = strList.slice(1);
    strList = strList.slice(0, totalLen);
  }
  switch (type) {
    case "int8":
      return new Int8Array(strList.map(parseNRRDInteger));
    case "uint8":
      return new Uint8Array(strList.map(parseNRRDInteger));
    case "int16":
      return new Int16Array(strList.map(parseNRRDInteger));
    case "uint16":
      return new Uint16Array(strList.map(parseNRRDInteger));
    case "int32":
      return new Int32Array(strList.map(parseNRRDInteger));
    case "uint32":
      return new Uint32Array(strList.map(parseNRRDInteger));
    case "float":
      return new Float32Array(strList.map(parseNRRDFloat));
    case "double":
      return new Float64Array(strList.map(parseNRRDFloat));
    default:
      console.warn("Unsupported NRRD type: " + type + ".");
      return;
  }
}
function checkNRRD(ret) {
  if (ret.dimension === undefined) {
    throw new Error("Dimension missing from NRRD file!");
  } else if (ret.type === undefined) {
    throw new Error("Type missing from NRRD file!");
  } else if (ret.encoding === undefined) {
    throw new Error("Encoding missing from NRRD file!");
  } else if (ret.sizes === undefined) {
    throw new Error("Sizes missing from NRRD file!");
  }
  if (ret.type != "block" && ret.type != "int8" && ret.type != "uint8" && ret.encoding != "ascii" && ret.endian === undefined) {
    throw new Error("Endianness missing from NRRD file!");
  } else if (ret.type == "block" && ret.blockSize === undefined) {
    throw new Error("Missing block size in NRRD file!");
  }
  if (ret.dimension === 0) {
    throw new Error("Zero-dimensional NRRD file?");
  } else if (ret.dimension != ret.sizes.length) {
    throw new Error("Length of 'sizes' is different from 'dimension' in an NRRD file!");
  } else if (ret.spacings && ret.dimension != ret.spacings.length) {
    throw new Error("Length of 'spacings' is different from 'dimension' in an NRRD file!");
  } else if (ret.thicknesses && ret.dimension != ret.thicknesses.length) {
    throw new Error("Length of 'thicknesses' is different from 'dimension' in an NRRD file!");
  } else if (ret.axisMins && ret.dimension != ret.axisMins.length) {
    throw new Error("Length of 'axis mins' is different from 'dimension' in an NRRD file!");
  } else if (ret.axisMaxs && ret.dimension != ret.axisMaxs.length) {
    throw new Error("Length of 'axis maxs' is different from 'dimension' in an NRRD file!");
  } else if (ret.centers && ret.dimension != ret.centers.length) {
    throw new Error("Length of 'centers' is different from 'dimension' in an NRRD file!");
  } else if (ret.labels && ret.dimension != ret.labels.length) {
    throw new Error("Length of 'labels' is different from 'dimension' in an NRRD file!");
  } else if (ret.units && ret.dimension != ret.units.length) {
    throw new Error("Length of 'units' is different from 'dimension' in an NRRD file!");
  } else if (ret.kinds && ret.dimension != ret.kinds.length) {
    throw new Error("Length of 'kinds' is different from 'dimension' in an NRRD file!");
  }
  if ((ret.data === undefined || ret.data.length === 0) && (ret.buffer === undefined || ret.buffer.byteLength === 0) && ret.dataFile === undefined) {
    throw new Error("NRRD file has neither inline or external data!");
  }
}
var lineSeparatorRE = /[ \f\t\v]*\r?\n/;
var NRRDMagicRE = /^NRRD\d{4}$/;
var lineRE = /^([^:]*)(:[ =])(.*)$/;
var dataFileListRE = /^LIST(?: (\d+))?$/;
var parseNRRD = function(buffer, fflate) {
  var i, header, dataStart, ret = {
    data: undefined,
    buffer: undefined,
    keys: {},
    version: undefined
  }, lines, match, match2, buf8 = new Uint8Array(buffer);
  if (buf8.buffer !== buffer)
    buffer = buf8.buffer;
  i = 2;
  while (i < buf8.length) {
    if (buf8[i] == 10) {
      if (buf8[i - 1] == 10 || buf8[i - 1] == 13 && buf8[i - 2] == 10) {
        dataStart = i + 1;
        break;
      } else {
        i++;
      }
    } else if (buf8[i] == 13) {
      i++;
    } else {
      i += 2;
    }
  }
  if (dataStart === undefined) {
    header = String.fromCharCode.apply(null, buf8);
  } else {
    header = String.fromCharCode.apply(null, buf8.subarray(0, dataStart));
    ret.buffer = buffer.slice(dataStart);
  }
  lines = header.split(lineSeparatorRE);
  lines = lines.filter(function(l) {
    return l.length > 0 && l[0] != "#";
  });
  if (!NRRDMagicRE.test(lines[0])) {
    throw new Error("File is not an NRRD file!");
  }
  ret.version = parseInt(lines[0].substring(4, 8), 10);
  if (ret.version > 5) {
    console.warn("Reading an unsupported version of the NRRD format; things may go haywire.");
  }
  for (i = 1;i < lines.length; i++) {
    match = lineRE.exec(lines[i]);
    if (!match) {
      console.warn("Unrecognized line in NRRD header: " + lines[i]);
      continue;
    }
    if (match[2] == ": ") {
      match[1] = mapNRRDToJavascript(match[1]);
      if (match[1] == "dataFile" && (match2 = dataFileListRE.exec(match[3]))) {
        if (match2.length == 2 && match2[1]) {
          ret[match[1]] = {
            files: lines.slice(i + 1),
            subdim: parseNRRDInteger(match2[1])
          };
        } else {
          ret[match[1]] = lines.slice(i + 1);
        }
        lines.length = i;
      } else {
        ret[match[1]] = parseField(match[1], match[3]);
      }
    } else if (match[2] == ":=") {
      ret.keys[match[1]] = unescapeValue(match[3]);
    } else {
      throw new Error("Logic error in NRRD parser.");
    }
  }
  checkNRRD(ret);
  if ("dataFile" in ret) {
    console.warn("No support for external data yet!");
  } else {
    switch (ret.encoding) {
      case "raw":
        ret.data = parseNRRDRawData(ret.buffer, ret.type, ret.sizes, {
          endian: ret.endian,
          blockSize: ret.blockSize
        });
        break;
      case "ascii":
        ret.data = parseNRRDTextData(ret.buffer, ret.type, ret.sizes);
        break;
      case "gzip":
        ret.buffer = fflate.gunzipSync(new Uint8Array(ret.buffer)).buffer;
        ret.data = parseNRRDRawData(ret.buffer, ret.type, ret.sizes, {
          endian: ret.endian,
          blockSize: ret.blockSize
        });
        break;
      default:
        console.warn("Unsupported NRRD encoding: " + ret.encoding);
    }
  }
  return ret;
};
var mapNRRDToJavascriptStatic = {
  "block size": "blockSize",
  blocksize: "blockSize",
  "old min": "oldMin",
  oldmin: "oldMin",
  "old max": "oldMax",
  oldmax: "oldMax",
  "data file": "dataFile",
  datafile: "dataFile",
  "line skip": "lineSkip",
  lineskip: "lineSkip",
  "byte skip": "byteSkip",
  byteskip: "byteSkip",
  "sample units": "sampleUnits",
  sampleunits: "sampleUnits",
  "axis mins": "axisMins",
  "axis maxs": "axisMaxs",
  centers: "centers",
  centerings: "centers",
  "space dimension": "spaceDimension",
  "space units": "spaceUnits",
  "space origin": "spaceOrigin",
  "space directions": "spaceDirections",
  "measurement frame": "measurementFrame"
};
var whitespaceListSeparator = /[ \t]+/;
var dataFileFormatRE = / (-?\d+) (-?\d+) (-?\d+)(?: (\d+))?$/;
var NRRDKinds = {
  domain: "domain",
  space: "space",
  time: "time",
  list: "list",
  point: "point",
  vector: "vector",
  "covariant-vector": "covariant-vector",
  normal: "normal",
  stub: "stub",
  scalar: "scalar",
  complex: "complex",
  "2-vector": "2-vector",
  "3-color": "3-color",
  "rgb-color": "RGB-color",
  "hsv-color": "HSV-color",
  "xyz-color": "XYZ-color",
  "4-color": "4-color",
  "rgba-color": "RGBA-color",
  "3-vector": "3-vector",
  "3-gradient": "3-gradient",
  "3-normal": "3-normal",
  "4-vector": "4-vector",
  quaternion: "quaternion",
  "2d-symmetric-matrix": "2D-symmetric-matrix",
  "2d-masked-symmetric-matrix": "2D-masked-symmetric-matrix",
  "2d-matrix": "2D-matrix",
  "2d-masked-matrix": "2D-masked-matrix",
  "3d-symmetric-matrix": "3D-symmetric-matrix",
  "3d-masked-symmetric-matrix": "3D-masked-symmetric-matrix",
  "3d-matrix": "3D-matrix",
  "3d-masked-matrix": "3D-masked-matrix",
  "???": null,
  none: null
};
var systemEndianness = function() {
  var buf = new ArrayBuffer(4), intArr = new Uint32Array(buf), byteArr = new Uint8Array(buf);
  intArr[0] = 16909060;
  if (byteArr[0] == 1 && byteArr[1] == 2 && byteArr[2] == 3 && byteArr[3] == 4) {
    return "big";
  } else if (byteArr[0] == 4 && byteArr[1] == 3 && byteArr[2] == 2 && byteArr[3] == 1) {
    return "little";
  }
  console.warn("Unrecognized system endianness!");
  return;
}();
var whitespaceDataValueListSeparatorRE = /[ \t\n\r\v\f]+/;

// studio/src/products/mpr/utils/Texture3DLoader.ts
import * as fflate from "fflate";

// studio/src/utils/Environment.ts
var API_URI_BASE = process.env.API_URI_BASE?.replaceAll('"', "") || "API_URI_BASE_IS_UNSET";
var API_LOGIN = process.env.API_LOGIN?.replaceAll('"', "") || "API_LOGIN_IS_UNSET";
var IS_DEVELOPMENT = true;
var IS_FEATURE_BRANCH = process.env.IS_FEATURE_BRANCH === "true";
var IS_LOCALHOST = window.location.hostname.includes("localhost");
var IS_LOCALHOST_OR_FEATURE_BRANCH = IS_LOCALHOST || IS_FEATURE_BRANCH;
var IS_WHITE_CLIENT = Boolean(process.env.IS_WHITE_CLIENT);
var IS_BLACK_CLIENT = Boolean(process.env.IS_BLACK_CLIENT);
var IS_RU_PROD = process.env.IS_RU_PROD === "true";
var IS_US_PROD = process.env.IS_US_PROD === "true";
var IS_HIDDEN_FOR_CLINICAL_TRIALS_ON_EU = process.env.IS_EU_PROD === "true";
var IS_BLACK_DEV = API_URI_BASE.includes("dev.diagnocat.com");
var DEBUG_PRINT_ENV = false;
window.printEnv = () => {
  console.log("process env API_URI_BASE=", process.env.API_URI_BASE);
  console.log("process env API_LOGIN=", process.env.API_LOGIN);
  console.log("process env TRANSPORT=", process.env.TRANSPORT);
  console.log("process env NODE_ENV=", "development");
  console.log("process env IS_FEATURE_BRANCH=", process.env.IS_FEATURE_BRANCH);
  console.log("window.location.hostname=", global.window?.location?.hostname);
  console.log("API_URI_BASE=", API_URI_BASE);
  console.log("API_LOGIN=", API_LOGIN);
  console.log("IS_DEVELOPMENT=", IS_DEVELOPMENT);
  console.log("IS_FEATURE_BRANCH=", IS_FEATURE_BRANCH);
  console.log("IS_LOCALHOST=", IS_LOCALHOST);
  console.log("IS_LOCALHOST_OR_FEATURE_BRANCH=", IS_LOCALHOST_OR_FEATURE_BRANCH);
  console.log("IS_RU_PROD=", IS_RU_PROD);
  console.log("IS_US_PROD=", IS_US_PROD);
  console.log("IS_HIDDEN_FOR_CLINICAL_TRIALS_ON_EU=", IS_HIDDEN_FOR_CLINICAL_TRIALS_ON_EU);
  console.log("IS_WHITE_CLIENT=", IS_WHITE_CLIENT);
  console.log("IS_BLACK_CLIENT=", IS_BLACK_CLIENT);
  console.log("IS_BLACK_DEV=", IS_BLACK_DEV);
};
if (DEBUG_PRINT_ENV) {
  window.printEnv();
}

// studio/src/products/shared/Credentials.ts
var signLoader = (loader) => {
  loader.setCrossOrigin("use-credentials");
  loader.setWithCredentials(true);
};
var unsignLoader = (loader) => {
  loader.setCrossOrigin("anonymous");
  loader.setWithCredentials(false);
};
var fetchCredentials = (url) => IS_LOCALHOST_OR_FEATURE_BRANCH && url.includes(API_URI_BASE) ? "include" : "same-origin";
var corsifyLoader = (loader, url) => {
  if (IS_LOCALHOST_OR_FEATURE_BRANCH && url.includes(API_URI_BASE)) {
    signLoader(loader);
  } else {
    unsignLoader(loader);
  }
};
var Credentials = {
  signLoader,
  unsignLoader,
  fetchCredentials,
  corsifyLoader
};

// studio/src/utils/assetUrl.ts
import urlJoin from "url-join";
var assetUrl = (fileID, propagateExt = undefined) => urlJoin(API_URI_BASE, "api/storage/download/file", fileID, propagateExt ? `#${propagateExt}` : "");

// studio/src/products/mpr/utils/MPR2Storage.ts
import {Box3 as Box33, Vector3 as Vector39} from "three";

// studio/src/products/mpr/constants/DefaultColorProfile.ts
var DEFAULT_COLOR_PROFILE = {
  ww: 3200,
  wc: 1000
};

// studio/src/products/mpr/utils/MPR2Storage.ts
class MPR2Storage {
  subvolumes = [];
  boundingBox = new Box33;
  colorProfile = Object.assign({}, DEFAULT_COLOR_PROFILE);
  initialColorProfile = Object.assign({}, DEFAULT_COLOR_PROFILE);
  toothToFocusOn = null;
  put(subvolumes, colorProfile, toothToFocusOn) {
    this.subvolumes = subvolumes;
    this.colorProfile.ww = colorProfile.ww;
    this.colorProfile.wc = colorProfile.wc;
    this.initialColorProfile.ww = colorProfile.ww;
    this.initialColorProfile.wc = colorProfile.wc;
    this.toothToFocusOn = Object.assign({}, toothToFocusOn);
    this.subvolumes.forEach((tile) => {
      this.boundingBox.union(tile.box);
    });
  }
  resetColorProfileToInitial() {
    this.colorProfile.ww = this.initialColorProfile.ww;
    this.colorProfile.wc = this.initialColorProfile.wc;
  }
  getToothPosition(iso, idx) {
    const subvolume = this.subvolumes.find((subvolume2) => subvolume2.numeration.iso === iso && subvolume2.numeration.idx === idx);
    if (subvolume) {
      return subvolume.box.getCenter(new Vector39);
    }
    console.log("[MPR2Storage] getToothPosition: Tooth not found", iso, idx);
    return null;
  }
}
var MPR2Storage_default = new MPR2Storage;

// studio/src/products/mpr/utils/Texture3DLoader.ts
var MAX_ERROR_AMOUNT = 5;
var RETRY_FETCH_TIMEOUT = 1000;

class Texture3DLoader {
  onLoadMeta = [];
  bvh;
  status = "initial";
  leafNodes = [];
  errors = [];
  loadingNodes = [];
  loadingNodesQueue = [];
  queueSize = 100;
  constructor() {
  }
  destroy() {
    this.status = "initial";
    this.onLoadMeta.splice(0);
    this.bvh = undefined;
    this.leafNodes.splice(0);
    this.errors.splice(0);
    this.loadingNodes.splice(0);
    this.loadingNodesQueue.splice(0);
  }
  async start() {
    const tilesAssets = MPR2Storage_default.subvolumes;
    const internalTiles = [];
    for (let i = 0;i < tilesAssets.length; i++) {
      const tile = tilesAssets[i];
      const internalTile = {
        box: tile.box,
        data: {
          fileId: tile.fileId,
          requesters: [],
          errors: [],
          index: i
        }
      };
      internalTiles.push(internalTile);
    }
    this.bvh = new NodeBVH(MPR2Storage_default.boundingBox, 0);
    this.buildBVH(internalTiles, this.bvh);
    if (this.errors.length > 0) {
      console.warn("[buildBVH] errors=", this);
      console.dir(this.errors);
    }
    this.status = "metaFetched";
    this.onLoadMeta.forEach((callback) => callback());
    this.onLoadMeta.length = 0;
  }
  async fetchTexture(node) {
    const tile = node.data;
    if (!tile)
      return;
    if (tile.controller?.signal.aborted) {
      this.onNodeAborted(node);
      return;
    }
    try {
      const url = assetUrl(tile.fileId);
      const response = await fetch(url, {
        credentials: Credentials.fetchCredentials(url),
        signal: tile.controller?.signal
      });
      const buffer = await response.arrayBuffer();
      const nrrd = parseNRRD(buffer, fflate);
      const texture = new Data3DTexture2(new Float32Array(nrrd.data), nrrd.sizes[0], nrrd.sizes[1], nrrd.sizes[2]);
      texture.internalFormat = "R16F";
      texture.format = RedFormat;
      texture.type = FloatType;
      texture.minFilter = LinearFilter;
      texture.magFilter = LinearFilter;
      texture.unpackAlignment = 1;
      texture.needsUpdate = true;
      const colorProfile = MPR2Storage_default.colorProfile;
      tile.material = new SliceMaterial(texture, node.box, colorProfile.ww, colorProfile.wc);
      this.onNodeLoaded(node);
    } catch (error) {
      if (error.name === "AbortError") {
        this.onNodeAborted(node);
      } else {
        this.onNodeError(node, error);
      }
    }
  }
  async onNodeError(node, error) {
    const tile = node.data;
    if (!tile)
      return;
    console.warn("onNodeError: error=", error, tile.index, node);
    tile.errors.push(error);
    if (tile.errors.length < MAX_ERROR_AMOUNT) {
      const timeout = setTimeout(() => {
        this.fetchTexture(node);
      }, RETRY_FETCH_TIMEOUT);
      tile.controller?.signal.addEventListener("abort", (abortEvent) => {
        clearTimeout(timeout);
      });
    } else {
      this.removeLoadingNode(node, "onNodeError");
      tile.status = undefined;
      tile.requesters.forEach((requester) => requester.onNodeError(node));
      tile.requesters.splice(0);
      for (let node2 of this.loadingNodes) {
        if (this.loadingNodesQueue.length < this.queueSize) {
          if (!this.loadingNodesQueue.includes(node2)) {
            this.loadingNodesQueue.push(node2);
            this.fetchTexture(node2);
          }
        } else
          break;
      }
    }
  }
  async onNodeAborted(node) {
    const tile = node.data;
    if (!tile)
      return;
  }
  removeLoadingNode(node, str) {
    let nodeIndex = this.loadingNodes.indexOf(node);
    if (nodeIndex !== -1) {
      this.loadingNodes.splice(nodeIndex, 1);
    } else {
      console.warn("no tile in loadingNodes=", node.data?.index, node, this, str);
    }
    nodeIndex = this.loadingNodesQueue.indexOf(node);
    if (nodeIndex !== -1) {
      this.loadingNodesQueue.splice(nodeIndex, 1);
    }
  }
  onNodeLoaded(node) {
    if (!node.data)
      return;
    this.removeLoadingNode(node, "onNodeLoaded");
    node.data.status = "fetched";
    node.data.requesters.forEach((requester) => requester.onNodeLoaded(node));
    node.data.requesters.splice(0);
    for (let node2 of this.loadingNodes) {
      if (this.loadingNodesQueue.length < this.queueSize) {
        if (!this.loadingNodesQueue.includes(node2)) {
          this.loadingNodesQueue.push(node2);
          this.fetchTexture(node2);
        }
      } else
        break;
    }
  }
  setNodesRequests(nodesToAdd, nodesToRemove, requester) {
    if (this.status !== "metaFetched") {
      console.warn("no metaFetched=", requester.name);
      return;
    }
    const nodesToLoad = nodesToAdd.filter((n) => (!n.data?.status || n.data?.status === "fetching") && !n.data?.material);
    const nodesToAbort = this.loadingNodes.filter((n) => nodesToRemove.includes(n));
    const nodesLoaded = nodesToAdd.filter((n) => n.data?.status === "fetched" && n.data?.material);
    if (requester.debugBoxes) {
      console.log("nodesToLoad=", requester.name);
      console.dir(nodesToLoad);
      console.dir(nodesToLoad.map((n) => n.data?.index));
      console.log("nodesToAbort=", requester.name);
      console.dir(nodesToAbort);
      console.dir(nodesToAbort.map((n) => n.data?.index));
      console.log("loadingNodes=", requester.name);
      console.dir(this.loadingNodes.slice());
      console.dir(this.loadingNodes.map((n) => n.data?.index));
      console.log("nodesLoaded=", requester.name);
      console.dir(nodesLoaded.slice());
      console.dir(nodesLoaded.map((n) => n.data?.index));
    }
    const nodesAborted = [];
    nodesToAbort.forEach((n) => {
      if (!n.data)
        return;
      const rIndex = n.data.requesters.indexOf(requester);
      if (rIndex !== -1) {
        n.data.requesters.splice(rIndex, 1);
        if (n.data.requesters.length === 0) {
          n.data.controller?.abort();
          this.removeLoadingNode(n, "abort");
          n.data.status = undefined;
          nodesAborted.push(n);
        }
      }
    });
    if (requester.debugBoxes) {
      console.log("nodesAborted=", requester.name);
      console.dir(nodesAborted);
      console.dir(nodesAborted.map((n) => n.data?.index));
    }
    if (nodesToLoad.length) {
      const nodesToFetch = [];
      for (let node of nodesToLoad) {
        const tile = node.data;
        if (!tile)
          continue;
        if (!tile.requesters.includes(requester))
          tile.requesters.push(requester);
        else
          console.warn("node is already requested by this requester=", node, requester, this);
        if (!(!tile.status && !tile.material))
          continue;
        tile.controller = new AbortController;
        if (!this.loadingNodes.includes(node))
          this.loadingNodes.push(node);
        else
          console.warn("node is already in loadingNodes=", node, this);
        tile.status = "fetching";
        nodesToFetch.push(node);
      }
      for (let node of nodesToFetch) {
        if (this.loadingNodesQueue.length < this.queueSize) {
          if (!this.loadingNodesQueue.includes(node)) {
            this.loadingNodesQueue.push(node);
            this.fetchTexture(node);
          } else
            console.warn("node is already in loadingNodesQueue=", node, this);
        } else
          break;
      }
    }
    if (requester.debugBoxes) {
      console.log("loadingNodes=", requester.name);
      console.dir(this.loadingNodes.slice());
      console.dir(this.loadingNodes.map((n) => n.data?.index));
    }
  }
  getComposedTexture(cb, name) {
    const nodesLoaded = this.leafNodes.filter((n) => n.data?.material);
    const nodesToLoad = this.leafNodes.filter((n) => !n.data?.material);
    console.log("leafNodes=", this.leafNodes.length);
    console.dir(this.leafNodes);
    console.log("nodesLoaded=", nodesLoaded.length);
    console.dir(nodesLoaded);
    console.log("nodesToLoad=", nodesToLoad.length);
    console.dir(nodesToLoad);
    const queueSizeOld = this.queueSize;
    this.queueSize = 1000;
    const requester = {
      name,
      debugBoxes: undefined,
      onNodeLoaded: (node) => {
        if (!nodesLoaded.includes(node))
          nodesLoaded.push(node);
        else
          console.warn("node is already in nodesLoaded=", node.data?.index, node, requester.name, requester, nodesLoaded);
        const nIndex = nodesToLoad.indexOf(node);
        if (nIndex !== -1)
          nodesToLoad.splice(nIndex, 1);
        else
          console.warn("no node in nodesToLoad=", node.data?.index, node, requester.name, requester, nodesToLoad);
        if (nodesToLoad.length === 0) {
          this.queueSize = queueSizeOld;
          const box = this.bvh.box;
          console.log(box, box.getSize(new Vector310));
          const indexSizeGlobal = box.getSize(new Vector310).toArray().map((item) => Math.round(item));
          const data = new Float32Array(indexSizeGlobal[0] * indexSizeGlobal[1] * indexSizeGlobal[2]);
          this.leafNodes.forEach((n) => {
            const startIndices = n.box.min.clone().sub(box.min).toArray().map((item) => Math.round(item));
            const indexSize = n.box.getSize(new Vector310).toArray().map((item) => Math.round(item));
            if (!n.data?.material)
              console.warn("no material=", n);
            const texture3d = n.data.material.uniforms.volume.value;
            for (let z = 0;z < indexSize[2]; z++) {
              for (let y = 0;y < indexSize[1]; y++) {
                for (let x = 0;x < indexSize[0]; x++) {
                  const val = texture3d.image.data[x + y * indexSize[0] + z * indexSize[0] * indexSize[1]];
                  const xg = x + startIndices[0];
                  const yg = y + startIndices[1];
                  const zg = z + startIndices[2];
                  data[xg + yg * indexSizeGlobal[0] + zg * indexSizeGlobal[0] * indexSizeGlobal[1]] = val;
                }
              }
            }
          });
          const texture = new Data3DTexture2(data, indexSizeGlobal[0], indexSizeGlobal[1], indexSizeGlobal[2]);
          texture.internalFormat = "R16F";
          texture.format = RedFormat;
          texture.type = FloatType;
          texture.minFilter = LinearFilter;
          texture.magFilter = LinearFilter;
          texture.unpackAlignment = 1;
          texture.needsUpdate = true;
          cb(indexSizeGlobal[0], indexSizeGlobal[1], indexSizeGlobal[2], texture);
        }
      },
      onNodeError: (node) => {
        console.error("node loading failed=", node);
        if (!nodesLoaded.includes(node))
          nodesLoaded.push(node);
        else
          console.warn("node is already in nodesLoaded=", node.data?.index, node, requester.name, requester, nodesLoaded);
        const nIndex = nodesToLoad.indexOf(node);
        if (nIndex !== -1)
          nodesToLoad.splice(nIndex, 1);
        else
          console.warn("no node in nodesToLoad=", node.data?.index, node, requester.name, requester, nodesToLoad);
      }
    };
    if (!this.bvh) {
      console.warn("Texture3DLoader.getComposedTexture: no bvh, try later=", name);
      this.onLoadMeta.push(() => {
        this.getComposedTexture(cb, name);
      });
    } else {
      this.setNodesRequests(nodesToLoad, [], requester);
    }
  }
  findTheLongestDimensionName(boxDimensions) {
    const { x, y, z } = boxDimensions;
    if (x >= y && x >= z)
      return "x";
    if (y >= x && y >= z)
      return "y";
    return "z";
  }
  buildBVH(tiles, node) {
    if (!tiles.length)
      return;
    if (tiles.length === 1) {
      node.data = { ...tiles[0].data };
      return;
    }
    const data = {
      left: { boxes: [], expandBox: new Box34 },
      right: { boxes: [], expandBox: new Box34 }
    };
    const vector = new Vector310;
    const longestDimensionName = this.findTheLongestDimensionName(node.box.getSize(vector));
    const centerOfTheLongestDimension = node.box.getCenter(vector)[longestDimensionName];
    for (let i = 0, l = tiles.length;i < l; i++) {
      const tileBox = tiles[i].box;
      let side = tileBox.getCenter(vector)[longestDimensionName] < centerOfTheLongestDimension ? "left" : "right";
      data[side].boxes.push(tiles[i]);
      data[side].expandBox.expandByPoint(tileBox.min);
      data[side].expandBox.expandByPoint(tileBox.max);
    }
    if (!data.left.boxes.length && data.right.boxes.length) {
      data.left.boxes.push(data.right.boxes.pop());
      this.errors.push("Possibly incorrect data");
    } else if (!data.right.boxes.length && data.left.boxes.length) {
      data.right.boxes.push(data.left.boxes.pop());
      this.errors.push("Possibly incorrect data");
    }
    node.left = new NodeBVH(data.left.expandBox, node.depth + 1);
    node.right = new NodeBVH(data.right.expandBox, node.depth + 1);
    if (data.left.boxes.length > 1) {
      this.buildBVH(data.left.boxes, node.left);
    } else {
      if (data.left.boxes.length > 0) {
        node.left.data = { ...data.left.boxes[0].data };
        this.leafNodes.push(node.left);
      }
    }
    if (data.right.boxes.length > 1) {
      this.buildBVH(data.right.boxes, node.right);
    } else {
      if (data.right.boxes.length > 0) {
        node.right.data = { ...data.right.boxes[0].data };
        this.leafNodes.push(node.right);
      }
    }
  }
  traverseBVH(condition, cbOk) {
    const bvh = this.bvh;
    if (!bvh) {
      console.warn("traverseBVH: no bvh=", this);
      return;
    }
    this._traverseBVH(condition, bvh, cbOk);
  }
  _traverseBVH(condition, bvh, cbOk) {
    if (condition(bvh)) {
      if (bvh.left) {
        this._traverseBVH(condition, bvh.left, cbOk);
      }
      if (bvh.right) {
        this._traverseBVH(condition, bvh.right, cbOk);
      }
      if (!bvh.left && !bvh.right) {
        cbOk(bvh);
      }
    }
  }
}

class NodeBVH {
  box;
  depth;
  left;
  right;
  data;
  constructor(box, depth) {
    this.box = box;
    this.depth = depth;
  }
}
var Texture3DLoader_default = new Texture3DLoader;

// studio/src/core/Entity.ts
import {Material as Material3, MathUtils as MathUtils2} from "three";

// studio/src/core/containers/EntityContainer.ts
class EntityContainer extends Container {
  constructor(parent, objects = []) {
    super(parent, objects);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = [...entity].filter(this.addOne.bind(this));
      App2.Instance().dispatchEvent({ type: "add", entities: added, container: this.hostObject });
    } else {
      if (this.addOne(entity)) {
        App2.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter((child) => {
        this.deleteOne(child);
      });
      App2.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
    } else {
      if (this.deleteOne(entity)) {
        App2.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
      }
    }
  }
  addOne(entity) {
    if (entity.parent !== this.hostObject) {
      if (entity.parent) {
        entity.parent.delete(entity);
      }
      this.objects.push(entity);
      entity.parent = this.hostObject;
      this.hostObject.object3d?.add(entity.object3d);
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    if (entity.parent === this.hostObject) {
      const index = this.hostObject.children.indexOf(entity);
      if (index >= 0) {
        this.hostObject.children.splice(index, 1);
      }
      entity.parent = undefined;
      this.hostObject.object3d?.remove(entity.object3d);
      return true;
    }
    return false;
  }
}

// studio/src/core/Entity.ts
var entityEventNames = ["transform", "update", "delete", "add"];

class Entity extends EventDispatcher {
  children;
  parent;
  object3d;
  container;
  components;
  layer;
  uuid;
  constructor(object3D) {
    super(entityEventNames);
    this.object3d = object3D;
    this.children = [];
    this.container = new EntityContainer(this, this.children);
    this.components = new Map;
    this.uuid = MathUtils2.generateUUID();
  }
  add(children) {
    this.container.add(children);
  }
  delete(children) {
    this.container.delete(children);
  }
  getBaseClass(component) {
    const prototype = Object.getPrototypeOf(component);
    if (prototype === Component) {
      return component;
    } else {
      return this.getBaseClass(prototype);
    }
  }
  getComponent(type) {
    return this.components.get(type);
  }
  setComponent(component, instance) {
    const BaseConcreteClass = this.getBaseClass(component);
    if (instance) {
      this.components.set(BaseConcreteClass, instance);
    } else if (component) {
      const instance2 = new component(this);
      !this.components.has(component) && this.components.set(BaseConcreteClass, instance2);
      return instance2;
    }
  }
  removeComponents() {
    this.components.clear();
  }
  getLayer() {
    return this.layer || this.parent?.getLayer();
  }
  setLayer(layer) {
    if (!this.layer?.has(this)) {
      if (layer?.has(this)) {
        this.layer = layer;
      } else {
        this.layer = undefined;
      }
    }
  }
  dispose() {
    this.object3d.traverse((obj) => {
      if (obj.geometry) {
        obj.geometry.dispose();
      }
      if (obj.material) {
        if (obj.material instanceof Material3) {
          obj.material.dispose();
        } else if (Array.isArray(obj.material)) {
          obj.material.forEach((material) => material instanceof Material3 && material.dispose());
        }
      }
    });
    this.object3d.clear();
  }
}

// studio/src/products/common/components/Visibility.ts
class Visibility extends Component {
  isUser = true;
  show() {
    this.entity.object3d.visible = true;
    App2.Instance().dispatchEvent({ type: "visibility", entity: this.entity });
  }
  hide() {
    this.entity.object3d.visible = false;
    App2.Instance().dispatchEvent({ type: "visibility", entity: this.entity });
  }
  isVisible() {
    return this.entity.object3d.visible;
  }
  toggle() {
    this.isVisible() ? this.hide() : this.show();
  }
  setVisible(visible) {
    if (this.isVisible() !== visible) {
      if (visible)
        this.show();
      else
        this.hide();
    }
  }
}

// studio/src/products/mpr/entities/MPR2Entity.ts
class MPR2Entity extends Entity {
  get name() {
    return this.object3d.name;
  }
  set name(name) {
    this.object3d.name = name;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
  get visible() {
    let result;
    const visibility = this.getComponent(Visibility);
    if (visibility) {
      result = visibility.isVisible();
    } else {
      result = this.object3d.visible;
    }
    return result;
  }
  set visible(value) {
    const visibility = this.getComponent(Visibility);
    if (visibility) {
      visibility.setVisible(value);
      visibility.isUser = true;
    } else {
      this.object3d.visible = value;
    }
  }
}

// studio/src/products/mpr/config/MPR2App.ts
var MPR2AppInstance = App2.Instance();

// studio/src/products/mpr/entities/VolumeSlice.ts
var triangle = new Triangle;
var v1 = new Vector311;
var v2 = new Vector311;
var v3 = new Vector311;
var _a = 0;
var _b = 0;
var _c = 0;
var noMaterial = new MeshBasicMaterial3;
var DEBUG_DRAW_NODES = true;
var EPS = 0.01;
var BORNDERLINE_ENABLED = true;

class VolumeSlice extends MPR2Entity {
  tag;
  plane;
  linkedCamera;
  planeGeometry;
  borderLine;
  visibleOnMainViewport;
  objects;
  nodes = [];
  static findAllSlices = () => {
    const layer = App2.Instance().view.getLayer("mpr");
    if (!layer) {
      throw new Error("[VolumeSlice] Layer 'mpr' not found");
    }
    return layer.getObjects().filter((object) => object instanceof VolumeSlice);
  };
  static findSlice = (name) => {
    const layer = App2.Instance().view.getLayer("mpr");
    if (!layer) {
      throw new Error("[VolumeSlice] Layer 'mpr' not found");
    }
    const object = layer.getObjectByName(name);
    return object instanceof VolumeSlice ? object : undefined;
  };
  constructor(tag, color = 16777215, width = 500, height = 500, linkedCamera) {
    super(new Object3D4);
    this.tag = tag;
    this.plane = new Plane2(new Vector311(0, 0, 1));
    this.setComponent(RaycastChildrenOnly);
    this.planeGeometry = new PlaneGeometry(1, 1, 1, 1);
    this.objects = new Map;
    this.object3d.scale.set(width, height, 1);
    this.visibleOnMainViewport = true;
    this.linkedCamera = linkedCamera;
    this.setComponent(RaycastChildrenOnly);
    if (BORNDERLINE_ENABLED) {
      this.borderLine = new MPR2Entity(new Line2(new BufferGeometry().setAttribute("position", this.planeGeometry.getAttribute("position")).setIndex([0, 1, 3, 2, 0]), new LineBasicMaterial2({ color, depthWrite: false, depthTest: false })));
      this.borderLine.setComponent(ViewportComponent);
      this.borderLine.setComponent(Visibility);
      this.borderLine.object3d.renderOrder = 100;
      this.add(this.borderLine);
    }
    console.log("Volume slice render order", this.object3d.renderOrder);
  }
  syncWWWC() {
    this.objects.forEach((entity) => {
      if (entity.object3d.material instanceof SliceMaterial) {
        entity.object3d.material.setWWWC(MPR2Storage_default.colorProfile.ww, MPR2Storage_default.colorProfile.wc);
      }
    });
  }
  updatePlane() {
    this.object3d.updateMatrix();
    this.object3d.updateMatrixWorld(true);
    this.plane.normal.setFromMatrixColumn(this.object3d.matrix, 2);
    this.plane.constant = -this.object3d.position.dot(this.plane.normal);
  }
  update() {
    this.updatePlane();
    this.getData();
  }
  setSize(width, height) {
    this.object3d.updateMatrix();
    this.getData();
  }
  fitToCamera() {
    this.linkedCamera.updateProjectionMatrix();
    const zoom = this.linkedCamera.zoom;
    const width = (this.linkedCamera.right - this.linkedCamera.left) / zoom;
    const height = (this.linkedCamera.top - this.linkedCamera.bottom) / zoom;
    this.setSize(width, height);
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
  showOnMainViewport() {
    this.objects.forEach((entity) => {
      const viewportComponent = entity.getComponent(ViewportComponent);
      if (viewportComponent)
        viewportComponent.addViewport("Main");
    });
    this.visibleOnMainViewport = true;
  }
  hideOnMainViewport() {
    this.objects.forEach((entity) => {
      const viewportComponent = entity.getComponent(ViewportComponent);
      if (viewportComponent)
        viewportComponent.deleteViewport("Main");
    });
    this.visibleOnMainViewport = false;
  }
  getData() {
    if (!Texture3DLoader_default.bvh) {
      Texture3DLoader_default.onLoadMeta.push(() => {
        this.updateActiveNodes();
      });
    } else {
      this.updateActiveNodes();
    }
  }
  getActiveNodes() {
    if (!Texture3DLoader_default.bvh) {
      console.warn("no bvh=", this.name);
      return [];
    }
    const mprLayer = App2.Instance().view.getLayer("mpr");
    if (!mprLayer) {
      throw new Error("[VolumeSlice] App must have 'mpr' layer for MPR to work");
    }
    const objectsMPR = mprLayer.getObjects();
    if (!objectsMPR) {
      console.warn("no objectsMPR=", this.name);
      return [];
    }
    const otherSlices = objectsMPR.filter((object) => object instanceof VolumeSlice && object.name !== this.name);
    const resultsData = [];
    const box = new Box35;
    Texture3DLoader_default.traverseBVH((node) => {
      box.copy(node.box).expandByScalar(EPS);
      if (!box.intersectsPlane(this.plane))
        return false;
      const position = this.planeGeometry.getAttribute("position").clone().applyMatrix4(this.object3d.matrix);
      for (let i = 0, il = this.planeGeometry.index.count;i < il; i += 3) {
        _a = this.planeGeometry.index.array[i];
        _b = this.planeGeometry.index.array[i + 1];
        _c = this.planeGeometry.index.array[i + 2];
        triangle.set(v1.set(position.getX(_a), position.getY(_a), position.getZ(_a)), v2.set(position.getX(_b), position.getY(_b), position.getZ(_b)), v3.set(position.getX(_c), position.getY(_c), position.getZ(_c)));
        if (box.intersectsTriangle(triangle)) {
          return true;
        }
      }
      return false;
    }, (n) => {
      const dist = otherSlices.reduce((accumulator, currentValue) => accumulator + Math.abs(currentValue.plane.distanceToPoint(n.box.getCenter(new Vector311))), 0);
      resultsData.push({ n, dist });
    });
    resultsData.sort((a, b) => a.dist - b.dist);
    const results = resultsData.map((rd) => rd.n);
    return results;
  }
  nodesDispatched = [];
  progressEvent;
  progressStart() {
    const viewport = App2.Instance().view.getViewport(this.name);
    if (!viewport) {
      console.warn("no viewport=", this.name, this);
      return;
    }
    if (this.progressEvent)
      this.progressFinish();
    this.nodesDispatched = this.nodes.filter((n) => n.data?.material);
    this.progressEvent = { type: "showSpinner", viewports: [viewport], onProgress: () => {
    } };
    this.progressUpdate();
  }
  progressFinish() {
    const viewport = App2.Instance().view.getViewport(this.name);
    if (!viewport) {
      console.warn("no viewport=", this.name, this);
      return;
    }
    if (!this.progressEvent) {
      console.warn("progressFinish: no progressEvent=", this.name, this);
      return;
    }
    this.progressEvent = undefined;
  }
  progressUpdate() {
    if (!this.progressEvent) {
      console.warn("progressNode: no progressEvent=", this.name, this);
      return;
    }
    const progress = this.nodes.length === 0 ? 0 : Math.round(this.nodesDispatched.length / this.nodes.length * 100);
    this.progressEvent.onProgress?.(progress);
    if (this.nodesDispatched.length === this.nodes.length)
      this.progressFinish();
  }
  progressNode(node) {
    const nIndex = this.nodes.indexOf(node);
    if (nIndex !== -1) {
      if (!this.nodesDispatched.includes(node)) {
        this.nodesDispatched.push(node);
      } else
        console.warn("node is already in dispatched=", node.data?.index, node, this.name, this);
      this.progressUpdate();
    } else
      console.warn("no node in progress=", node.data?.index, node, this.name, this);
  }
  updateActiveNodes() {
    const nodesNew = this.getActiveNodes();
    const nodesToAdd = nodesNew.filter((n) => !this.nodes.includes(n));
    const nodesToRemove = this.nodes.filter((n) => !nodesNew.includes(n));
    this.nodes = nodesNew;
    if (!(nodesToAdd.length || nodesToRemove.length)) {
      return;
    }
    this.progressStart();
    nodesToRemove.forEach((n) => {
      const mesh = this.objects.get(n);
      if (mesh) {
        this.delete(mesh);
        this.objects.delete(n);
      }
      const material = n.data?.material;
      if (material) {
        material.destroy(this);
      }
    });
    const errors = [];
    nodesToAdd.forEach((n) => {
      let mesh = this.objects.get(n);
      if (!mesh) {
        mesh = new MPR2Entity(new Mesh3(this.planeGeometry, noMaterial));
        const viewportComponent = mesh.setComponent(ViewportComponent);
        viewportComponent.addViewport("Axial");
        viewportComponent.addViewport("Coronal");
        viewportComponent.addViewport("Sagittal");
        if (this.visibleOnMainViewport)
          viewportComponent.addViewport("Main");
        this.objects.set(n, mesh);
        this.add(mesh);
      }
      const material = n.data?.material;
      if (material) {
        mesh.object3d.material = material;
        if (material instanceof SliceMaterial) {
          material.increaseUsers(this);
          material.setWWWC(MPR2Storage_default.colorProfile.ww, MPR2Storage_default.colorProfile.wc);
        } else
          console.warn("material is not SliceMaterial=", material);
      } else {
        errors.push({ error: "add node: no material yet (wait for load)=", n, mesh });
        mesh.object3d.visible = false;
      }
    });
    if (DEBUG_DRAW_NODES) {
      const color = new Color4((() => {
        if (this.name === "Axial")
          return "red";
        if (this.name === "Coronal")
          return "green";
        if (this.name === "Sagittal")
          return "blue";
        return "white";
      })());
      const container = DebugUtils.getContainer(`DEBUG_DRAW_NODES_${this.name}`);
      container.clear();
      nodesNew.forEach((n) => {
        const boxHelper = new Box3Helper2(n.box, color);
        container.add(boxHelper);
      });
    }
    Texture3DLoader_default.setNodesRequests(nodesToAdd, nodesToRemove, this);
    MPR2AppInstance.dispatchEvent({ type: "render" });
  }
  onNodeLoaded(node) {
    const mesh = this.objects.get(node);
    if (mesh) {
      const material = node.data?.material;
      if (material) {
        if (!mesh.object3d.visible) {
          if (material instanceof SliceMaterial)
            material.increaseUsers(this);
          mesh.object3d.material = material;
          mesh.object3d.visible = true;
          this.progressNode(node);
        }
        if (material instanceof SliceMaterial) {
          material.setWWWC(MPR2Storage_default.colorProfile.ww, MPR2Storage_default.colorProfile.wc);
        } else
          console.warn("material is not SliceMaterial=", material);
      } else
        console.warn("onNodeLoaded: no material=", node, mesh);
    } else {
      console.warn("onNodeLoaded: no node in slice=", this.name, node, this);
      const material = node.data?.material;
      if (material) {
        material.destroy(this);
      }
    }
    MPR2AppInstance.dispatchEvent({ type: "render" });
  }
  onNodeError(node) {
    console.error("node loading failed=", node);
    this.progressNode(node);
  }
}

// studio/src/products/mpr/tools/MprVolumeSliceSpawner.ts
var colors = {
  Axial: "yellow",
  Sagittal: "cyan",
  Coronal: "magenta"
};

class MprVolumeSliceSpawner extends ViewportTool {
  slice;
  storedSliceMatrix;
  constructor(viewport) {
    super(viewport);
    this.onTransform = this.onTransform.bind(this);
    this.onResizeViewport = this.onResizeViewport.bind(this);
    this.onViewportSchemeChanged = this.onViewportSchemeChanged.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }
  activate() {
    if (!this.slice)
      this.makeSlice();
    App2.Instance().addEventListener("transform", this.onTransform, 1);
    this.hostObject.addEventListener("resize", this.onResizeViewport, 5);
    App2.Instance().addEventListener("resize", this.onResizeViewport, 5);
    App2.Instance().addEventListener("viewportSchemeChanged", this.onViewportSchemeChanged);
    App2.Instance().mouseController.addListener("pointermove", this.onMouseMove, document);
    this.onUpdateSliceBorderVisibilityByActiveViewport(this.hostObject.view.getActiveViewport());
  }
  deactivate() {
    if (this.slice)
      this.destroySlice();
    App2.Instance().removeEventListener("transform", this.onTransform);
    this.hostObject.removeEventListener("resize", this.onResizeViewport);
    App2.Instance().removeEventListener("resize", this.onResizeViewport);
    App2.Instance().removeEventListener("viewportSchemeChanged", this.onViewportSchemeChanged);
    App2.Instance().mouseController.removeListener("pointermove", this.onMouseMove, document);
  }
  onResizeViewport() {
    this.slice?.fitToCamera();
    if (this.slice && this.slice.borderLine)
      this.slice.borderLine.visible = !(this.hostObject.width === 0 && this.hostObject.height === 0);
  }
  onViewportSchemeChanged(event) {
  }
  onTransform(event) {
    if (this.slice && event.entities?.includes(this.slice)) {
      this.slice.update();
    }
  }
  cursorPoint = new Vector23;
  viewportBound = new Box2;
  onMouseMove(event) {
    this.cursorPoint.set(event.clientX, event.clientY);
    let viewportInsideNew = undefined;
    this.hostObject.view.viewports.forEach((viewport) => {
      this.viewportBound.min.set(viewport.rect.left, viewport.rect.top);
      this.viewportBound.max.set(viewport.rect.right, viewport.rect.bottom);
      if (this.viewportBound.containsPoint(this.cursorPoint)) {
        viewportInsideNew = viewport;
        return;
      }
    });
    this.viewportInside = viewportInsideNew;
  }
  _viewportInside;
  set viewportInside(value) {
    if (this._viewportInside === value)
      return;
    this._viewportInside = value;
    this.onUpdateSliceBorderVisibilityByActiveViewport(value);
  }
  onUpdateSliceBorderVisibilityByActiveViewport(viewport) {
    if (!(this.slice && this.slice.borderLine))
      return;
    const viewportComponent = this.slice.borderLine.getComponent(ViewportComponent);
    if (!viewportComponent)
      return;
    const isMpr = viewport ? Boolean(colors[viewport.name]) : false;
    if (isMpr && !viewportComponent.hasViewport("Main")) {
      viewportComponent.addViewport("Main");
      App2.Instance().dispatchEvent({ type: "render" });
    } else if (!isMpr && viewportComponent.hasViewport("Main")) {
      viewportComponent.deleteViewport("Main");
      App2.Instance().dispatchEvent({ type: "render" });
    }
  }
  makeSlice() {
    this.hostObject.camera.far = 1;
    this.hostObject.camera.near = -1;
    const color = colors[this.hostObject.name];
    const tag = this.hostObject.name;
    if (!(this.hostObject.camera instanceof OrthographicCamera3)) {
      throw new Error("[MprVolumeSliceSpawner] Camera is not an OrthographicCamera");
    }
    const DEFAULT_WIDTH_HEIGHT = 500;
    this.slice = new VolumeSlice(tag, color, DEFAULT_WIDTH_HEIGHT, DEFAULT_WIDTH_HEIGHT, this.hostObject.camera);
    this.slice.name = this.hostObject.name;
    if (this.slice.borderLine) {
      const mainViewport = App2.Instance().view.getViewport("Main");
      if (mainViewport) {
        this.slice.borderLine.getComponent(ViewportComponent)?.addViewport("Main");
        this.slice.borderLine.getComponent(ViewportComponent)?.deleteViewport("Main");
      } else {
        this.slice.borderLine.getComponent(Visibility)?.hide();
      }
    }
    this.slice.update();
    this.hostObject.view.getLayer("mpr")?.add(this.slice);
  }
  destroySlice() {
    if (!this.slice) {
      console.warn("destroySlice: already destroyed=", this.hostObject.name, this);
      return;
    }
    this.storedSliceMatrix = this.slice.object3d.matrix.clone();
    this.hostObject.view.getLayer("mpr")?.delete(this.slice);
    this.slice.dispose();
    this.slice = undefined;
  }
}

// studio/src/core/tuple.ts
var tuple = (...args) => args;

// studio/src/products/mpr/entities/CrossLine.ts
import {LineSegments as LineSegments2, BufferGeometry as BufferGeometry2, BufferAttribute as BufferAttribute2, Group as Group4, Color as Color5, LineBasicMaterial as LineBasicMaterial3} from "three";
var CROSS_LINE_LENGTH = 1000;

class CrossLine extends Entity {
  static createFromTag(tag) {
    if (tag === "Axial") {
      return new CrossLine("Axial CrossLine", "magenta", "cyan");
    }
    if (tag === "Sagittal") {
      return new CrossLine("Sagittal CrossLine", "yellow", "magenta");
    }
    if (tag === "Coronal") {
      return new CrossLine("Coronal CrossLine", "yellow", "cyan");
    }
    throw new Error("Invalid tag");
  }
  constructor(name = "CrossLine", colorHorizontal = "white", colorVertical = "white") {
    super(new Group4);
    const positionAttributeX = new BufferAttribute2(new Float32Array([-1, 0, 0, 1, 0, 0]), 3);
    const lineHorizontal = new LineSegments2(new BufferGeometry2().setAttribute("position", positionAttributeX), new LineBasicMaterial3({ color: new Color5(colorHorizontal), depthTest: false }));
    const positionAttributeY = new BufferAttribute2(new Float32Array([0, -1, 0, 0, 1, 0]), 3);
    const lineVertical = new LineSegments2(new BufferGeometry2().setAttribute("position", positionAttributeY), new LineBasicMaterial3({ color: new Color5(colorVertical), depthTest: false }));
    lineHorizontal.scale.set(CROSS_LINE_LENGTH, CROSS_LINE_LENGTH, CROSS_LINE_LENGTH);
    lineVertical.scale.set(CROSS_LINE_LENGTH, CROSS_LINE_LENGTH, CROSS_LINE_LENGTH);
    this.object3d.add(lineHorizontal, lineVertical);
    this.setComponent(ViewportComponent);
    this.setComponent(Visibility);
    this.object3d.frustumCulled = false;
    this.object3d.renderOrder = 200;
    this.object3d.name = name;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

// studio/src/products/mpr/entities/MPR2SpatialCore/MPR2SpatialCore.ts
import {Object3D as Object3D6, Quaternion as Quaternion4, Vector3 as Vector313} from "three";

// studio/src/products/mpr/entities/MPR2SpatialCore/CoreTransform.ts
import {Object3D as Object3D5, Vector3 as Vector312} from "three";

class Transform extends Object3D5 {
  forward(out) {
    out = out || new Vector312;
    out.set(0, 0, 1);
    out.setFromMatrixColumn(this.matrixWorld, 2);
    return out;
  }
  positionWorld(out) {
    out = out || new Vector312;
    this.getWorldPosition(out);
    return out;
  }
  z(out) {
    return this.forward(out);
  }
  x(out) {
    out = out || new Vector312;
    out.set(1, 0, 0);
    out.setFromMatrixColumn(this.matrixWorld, 0);
    return out;
  }
  y(out) {
    out = out || new Vector312;
    out.set(0, 1, 0);
    out.setFromMatrixColumn(this.matrixWorld, 1);
    return out;
  }
}

class CoreTransform {
  object3d;
  axialTransform;
  sagittalTransform;
  coronalTransform;
  getTransformByMPRTag(tag) {
    if (tag === "Axial") {
      return this.axialTransform;
    }
    if (tag === "Sagittal") {
      return this.sagittalTransform;
    }
    if (tag === "Coronal") {
      return this.coronalTransform;
    }
  }
  constructor(spatialCore) {
    this.object3d = new Object3D5;
    spatialCore.object3d.add(this.object3d);
    this.axialTransform = new Transform;
    this.sagittalTransform = new Transform;
    this.coronalTransform = new Transform;
    this.object3d.add(this.axialTransform);
    this.object3d.add(this.sagittalTransform);
    this.object3d.add(this.coronalTransform);
    this.axialTransform.name = "AxialTransform";
    this.sagittalTransform.name = "SagittalTransform";
    this.coronalTransform.name = "CoronalTransform";
  }
  reset() {
    this.object3d.position.set(0, 0, 0);
    this.object3d.quaternion.identity();
    this.axialTransform.position.set(0, 0, 0);
    this.sagittalTransform.position.set(0, 0, 0);
    this.coronalTransform.position.set(0, 0, 0);
    this.axialTransform.quaternion.setFromLookRotation(new Vector312(0, 0, 1), new Vector312(0, 1, 0));
    this.sagittalTransform.quaternion.setFromLookRotation(new Vector312(1, 0, 0), new Vector312(0, 0, 1));
    this.coronalTransform.quaternion.setFromLookRotation(new Vector312(0, -1, 0), new Vector312(0, 0, 1));
  }
}

// studio/src/products/mpr/entities/MPR2SpatialCore/SliceTransform.ts
class SliceTransform extends CoreTransform {
}

// studio/src/products/mpr/entities/MPR2SpatialCore/ViewTransform.ts
class ViewTransform extends CoreTransform {
}

// studio/src/products/mpr/entities/MPR2SpatialCore/MPR2SpatialCore.ts
class MPR2SpatialCore extends Entity {
  sliceCoreTransform;
  viewCoreTransform;
  constructor() {
    super(new Object3D6);
    this.sliceCoreTransform = new SliceTransform(this);
    this.viewCoreTransform = new ViewTransform(this);
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
  panViewTransform = (tag, pan) => {
    const viewTransform = this.viewCoreTransform.getTransformByMPRTag(tag);
    viewTransform.translateX(pan.x);
    viewTransform.translateY(pan.y);
  };
  translateXY(tag, x, y) {
    const viewSubTransform = this.viewCoreTransform.getTransformByMPRTag(tag);
    const right = viewSubTransform.x().clone();
    const up = viewSubTransform.y().clone();
    const wsTranslation = right.clone().multiplyScalar(x).add(up.clone().multiplyScalar(y));
    this.sliceCoreTransform.object3d.position.add(wsTranslation);
    this.viewCoreTransform.object3d.position.add(wsTranslation);
    viewSubTransform.translateX(-x);
    viewSubTransform.translateY(-y);
  }
  translateZ(tag, z) {
    const viewSubTransform = this.viewCoreTransform.getTransformByMPRTag(tag);
    const wsForward = viewSubTransform.forward().clone();
    const wsTranslation = wsForward.clone().multiplyScalar(z);
    this.sliceCoreTransform.object3d.position.add(wsTranslation);
    this.viewCoreTransform.object3d.position.add(wsTranslation);
  }
  rotateAxes(tag, angleRad) {
    const viewSubTransform = this.viewCoreTransform.getTransformByMPRTag(tag);
    const sliceSubTransform = this.sliceCoreTransform.getTransformByMPRTag(tag);
    const wsForward = sliceSubTransform.forward().clone();
    const pos = viewSubTransform.getWorldPosition(new Vector313);
    const q = viewSubTransform.getWorldQuaternion(new Quaternion4);
    viewSubTransform.removeFromParent();
    this.sliceCoreTransform.object3d.rotateOnWorldAxis(wsForward, angleRad);
    this.viewCoreTransform.object3d.rotateOnWorldAxis(wsForward, angleRad);
    viewSubTransform.position.copy(pos);
    viewSubTransform.quaternion.copy(q);
    this.viewCoreTransform.object3d.attach(viewSubTransform);
  }
  rotateViewSpace(tag, angleRad) {
    const sliceSubTransform = this.sliceCoreTransform.getTransformByMPRTag(tag);
    const wsForward = sliceSubTransform.forward().clone();
    this.sliceCoreTransform.object3d.rotateOnWorldAxis(wsForward, -angleRad);
    this.viewCoreTransform.object3d.rotateOnWorldAxis(wsForward, -angleRad);
  }
  focus(position) {
    this.sliceCoreTransform.reset();
    this.viewCoreTransform.reset();
    this.sliceCoreTransform.object3d.position.copy(position);
    this.viewCoreTransform.object3d.position.copy(position);
  }
}
var SpatialCore = new MPR2SpatialCore;

// studio/src/products/mpr/tools/MPR2CrossLineSpawner.ts
class MPR2CrossLineSpawner extends ViewportTool {
  crossLine;
  activate() {
    this.crossLine = CrossLine.createFromTag(this.hostObject.name);
    App2.Instance().view.getLayer("mpr").add(this.crossLine);
    SpatialCore.sliceCoreTransform.getTransformByMPRTag(this.hostObject.name).add(this.crossLine.object3d);
    this.crossLine.getComponent(ViewportComponent)?.addViewport(this.hostObject.name);
  }
}

// studio/src/products/mpr/tools/MPR2MoveSlice.ts
import {Vector3 as Vector314} from "three";

// studio/src/products/mpr/utils/listenPointerDrag.ts
var listenPointerDrag = (onDrag, domElement, button = "left", predicate) => {
  const unsubscribe = [];
  const handleMouseUp = () => {
    unsubscribe.forEach((u) => u());
  };
  const handleMouseDown = (event) => {
    if (predicate && !predicate(event))
      return;
    const unsubscribeMove = App2.Instance().mouseController.addListener("pointermove", onDrag, document);
    const unsubscribeUp = App2.Instance().mouseController.addListener("pointerup", handleMouseUp, document, button);
    const unsubscribeLeave = App2.Instance().mouseController.addListener("pointerleave", handleMouseUp, document);
    unsubscribe.push(unsubscribeMove, unsubscribeUp, unsubscribeLeave);
  };
  App2.Instance().mouseController.addListener("pointerdown", handleMouseDown, domElement, button);
  return () => {
    unsubscribe.forEach((u) => u());
    App2.Instance().mouseController.removeListener("pointerdown", handleMouseDown, domElement, button);
  };
};

// studio/src/products/mpr/utils/getClipSpaceCursor.ts
var getClipSpaceCursor = (out) => {
  const tmp = App2.Instance().cursorController.position;
  out.set(tmp.x, tmp.y, 0);
  return out;
};

// studio/src/products/mpr/tools/MPR2MoveSlice.ts
var CENTER_TOUCHING_TOLERANCE = 0.2;

class MPR2MoveSlice extends ViewportTool {
  stopListenPointerDrag;
  activate = () => {
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.getDomElement(), "left", this.predicate);
  };
  deactivate = () => {
    this.stopListenPointerDrag?.();
  };
  predicate = (event) => {
    const csCursor = getClipSpaceCursor(new Vector314);
    const vsTransformCenter = SpatialCore.sliceCoreTransform.object3d.position.clone().project(this.hostObject.camera);
    const isTouchingCenter = csCursor.distanceTo(vsTransformCenter) <= CENTER_TOUCHING_TOLERANCE;
    return isTouchingCenter;
  };
  handlePointerDrag = (event) => {
    const deltaX = event.movementX;
    const deltaY = event.movementY;
    const sizeRatio = this.hostObject.cameraController.orthographicCameraHelper.getSizeRatio();
    const zoom = this.hostObject.camera.zoom;
    SpatialCore.translateXY(this.hostObject.name, deltaX * sizeRatio / zoom, -(deltaY * sizeRatio) / zoom);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/tools/MPR2Pan2D.ts
import {Vector2 as Vector25} from "three";
class MPR2Pan2D extends ViewportTool {
  panStart = new Vector25;
  panEnd = new Vector25;
  panDelta = new Vector25;
  panOffset = new Vector25;
  constructor(viewport) {
    super(viewport);
    this.handleLeftMouseButtonDown = this.handleLeftMouseButtonDown.bind(this);
    this.handleMiddleMouseButtonDown = this.handleMiddleMouseButtonDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseMoveOnce = this.handleMouseMoveOnce.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }
  get cameraController() {
    return this.hostObject.cameraController;
  }
  get camera() {
    return this.hostObject.camera;
  }
  activate() {
    App2.Instance().mouseController.addListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App2.Instance().mouseController.addListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
  }
  deactivate() {
    App2.Instance().mouseController.removeListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App2.Instance().mouseController.removeListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
    App2.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
  }
  pan(deltaX, deltaY) {
    this.computePanOffset(deltaX, deltaY);
    SpatialCore.panViewTransform(this.hostObject.name, this.panOffset);
    this.panOffset.set(0, 0);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  }
  computePanOffset(deltaX, deltaY) {
    const sizeRatio = this.cameraController.orthographicCameraHelper.getSizeRatio();
    const zoom = this.camera.zoom;
    this.panOffset.x = -(deltaX * sizeRatio) / zoom;
    this.panOffset.y = deltaY * sizeRatio / zoom;
  }
  handleMouseMoveOnce(event) {
    this.panStart.set(event.clientX, event.clientY);
    App2.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App2.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
  }
  handleMouseDown(event) {
    event.preventDefault();
    App2.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App2.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "left");
    App2.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
  }
  handleLeftMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMiddleMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMouseMove(event) {
    this.panEnd.set(event.clientX, event.clientY);
    this.panDelta.subVectors(this.panEnd, this.panStart);
    this.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
  }
  handleMouseUp(event) {
    App2.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    App2.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "left");
    App2.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
  }
}

// studio/src/products/mpr/tools/MPR2Zoom.ts
class MPR2Zoom extends ViewportTool {
  zoomFactor = Math.pow(0.95, 0.4);
  maxZoom = Infinity;
  minZoom = 0;
  get camera() {
    return this.hostObject.camera;
  }
  stopListenPointerDrag;
  activate = () => {
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.domElement, "left");
  };
  deactivate = () => {
    this.stopListenPointerDrag?.();
  };
  handlePointerDrag = (event) => {
    const amount = event.movementY;
    if (amount === 0)
      return;
    const zoomIn = amount > 0;
    if (zoomIn) {
      this.zoom(this.zoomFactor);
    } else {
      this.zoom(1 / this.zoomFactor);
    }
    App2.Instance().dispatchEvent({ type: "render" });
  };
  zoom = (factor) => {
    this.camera.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom * factor));
    this.camera.updateProjectionMatrix();
  };
}

// studio/src/products/mpr/entities/MPR2Tag.ts
var isMPR2Tag = (tag) => {
  return tag === "Axial" || tag === "Coronal" || tag === "Sagittal";
};

// studio/src/products/mpr/tools/MPR2RotateAxes.ts
import {OrthographicCamera as OrthographicCamera6, Vector3 as Vector317} from "three";

// studio/src/products/mpr/utils/computeCursorProjections.ts
import {Vector3 as Vector316} from "three";
var getAspectRatio = (camera) => {
  return (-camera.left + camera.right) / (-camera.top + camera.bottom);
};
var computeCursorProjections = (transform, camera, outTransformPosition, outStartCursorFromCenter) => {
  const csCursor = getClipSpaceCursor(new Vector316);
  const csTransformPosition = transform.positionWorld().project(camera);
  csCursor.sub(csTransformPosition);
  const aspectRatio = getAspectRatio(camera);
  const vsTransformX = transform.x().add(camera.position).project(camera).normalize();
  vsTransformX.x *= aspectRatio;
  const vsTransformY = transform.y().add(camera.position).project(camera).normalize();
  vsTransformY.x *= aspectRatio;
  outStartCursorFromCenter.copy(csCursor);
  outTransformPosition.copy(csTransformPosition);
  const vsCursor = csCursor;
  vsCursor.x *= aspectRatio;
  const csXLength = vsCursor.dot(vsTransformX) / aspectRatio;
  const csYLength = vsCursor.dot(vsTransformY);
  return {
    xLength: csXLength,
    yLength: csYLength
  };
};

// studio/src/products/mpr/tools/MPR2RotateAxes.ts
var AXIS_TOUCHING_TOLERANCE = 0.1;

class MPR2RotateAxes extends ViewportTool {
  stopListenPointerDrag;
  csStartCursorFromCenter = new Vector317;
  csEndCursorFromCenter = new Vector317;
  csTransformPosition = new Vector317;
  activate() {
    if (this.hostObject.camera instanceof OrthographicCamera6 === false) {
      throw new Error("MPR2RotateAxes can only be used with OrthographicCamera");
    }
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.getDomElement(), "left", this.predicate);
  }
  deactivate() {
    console.log("MPR2RotateAxes.deactivate");
    this.stopListenPointerDrag?.();
  }
  predicate = (event) => {
    const csCursor = getClipSpaceCursor(new Vector317);
    const сsTransformCenter = SpatialCore.sliceCoreTransform.object3d.position.clone().project(this.hostObject.camera);
    const isTouchingCenter = csCursor.distanceTo(сsTransformCenter) <= CENTER_TOUCHING_TOLERANCE;
    if (isTouchingCenter)
      return false;
    const transform = SpatialCore.sliceCoreTransform.getTransformByMPRTag(this.hostObject.name);
    const { xLength, yLength } = computeCursorProjections(transform, this.hostObject.camera, this.csTransformPosition, this.csStartCursorFromCenter);
    const isTouchingAxis = Math.abs(xLength) < AXIS_TOUCHING_TOLERANCE || Math.abs(yLength) < AXIS_TOUCHING_TOLERANCE;
    return isTouchingAxis;
  };
  handlePointerDrag = (event) => {
    this.csEndCursorFromCenter = getClipSpaceCursor(this.csEndCursorFromCenter).sub(this.csTransformPosition);
    const aspect = this.hostObject.width / this.hostObject.height;
    const vsStartCusrorFromCenter = this.csStartCursorFromCenter.clone();
    vsStartCusrorFromCenter.x *= aspect;
    const vsEndCusrorFromCenter = this.csEndCursorFromCenter.clone();
    vsEndCusrorFromCenter.x *= aspect;
    let angle = vsStartCusrorFromCenter.angleTo(vsEndCusrorFromCenter);
    const cross = vsStartCusrorFromCenter.cross(vsEndCusrorFromCenter);
    angle = cross.z > 0 ? angle : -angle;
    this.csStartCursorFromCenter.copy(this.csEndCursorFromCenter);
    console.assert(isMPR2Tag(this.hostObject.name), "Invalid MPR2Tag");
    SpatialCore.rotateAxes(this.hostObject.name, angle);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/tools/MPR2RotateViewSpace.ts
import {OrthographicCamera as OrthographicCamera7, Vector3 as Vector318} from "three";
var AXIS_TOUCHING_TOLERANCE2 = 0.1;

class MPR2RotateViewSpace extends ViewportTool {
  stopListenPointerDrag;
  csStartCursorFromCenter = new Vector318;
  vsEndCursorFromCenter = new Vector318;
  csTransformPosition = new Vector318;
  activate() {
    if (this.hostObject.camera instanceof OrthographicCamera7 === false) {
      throw new Error("MPR2RotateViewSpace can only be used with OrthographicCamera");
    }
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.getDomElement(), "left", this.predicate);
  }
  deactivate() {
    this.stopListenPointerDrag?.();
  }
  predicate = (event) => {
    const transform = SpatialCore.sliceCoreTransform.getTransformByMPRTag(this.hostObject.name);
    const { xLength, yLength } = computeCursorProjections(transform, this.hostObject.camera, this.csTransformPosition, this.csStartCursorFromCenter);
    const isTouchingVoid = Math.abs(xLength) > AXIS_TOUCHING_TOLERANCE2 && Math.abs(yLength) > AXIS_TOUCHING_TOLERANCE2;
    return isTouchingVoid;
  };
  handlePointerDrag = (event) => {
    this.vsEndCursorFromCenter = getClipSpaceCursor(this.vsEndCursorFromCenter).sub(this.csTransformPosition);
    let angle = this.csStartCursorFromCenter.angleTo(this.vsEndCursorFromCenter);
    const cross = this.csStartCursorFromCenter.cross(this.vsEndCursorFromCenter);
    angle = cross.z > 0 ? angle : -angle;
    this.csStartCursorFromCenter.copy(this.vsEndCursorFromCenter);
    SpatialCore.rotateViewSpace(this.hostObject.name, angle);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/tools/MPR2ThroughDepth.ts
class MPR2ThroughDepth extends ViewportTool {
  stopListenPointerDrag;
  activate() {
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.getDomElement(), "left", this.predicate);
  }
  predicate = (event) => {
    return true;
  };
  handlePointerDrag = (event) => {
    SpatialCore.translateZ(this.hostObject.name, event.movementY);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/config/mprAppViewportToolsAndModes.ts
class MprAppViewportMode extends Mode {
  static except = (tools) => new MprAppViewportMode({ tools: { toolList: tools, include: false } });
  static only = (tools) => new MprAppViewportMode({ tools: { toolList: tools, include: true } });
}
var mprAppViewportServiceTools = new Set([
  Render,
  MprVolumeSliceSpawner,
  MPR2CrossLineSpawner
]);
var mprAppViewportTools = tuple(MPR2Pan2D, MPR2MoveSlice, MPR2Zoom, MPR2RotateAxes, MPR2RotateViewSpace, MPR2ThroughDepth);
var mprAppMainViewportTools = tuple(Render, OrbitControls, CameraToggle);
var viewports = tuple(...MPRViewportNames);
var mprViewportModes = {};
viewports.forEach((viewportName) => {
  mprViewportModes[viewportName] = {
    mainMode: MprAppViewportMode.only([
      MPR2MoveSlice,
      MPR2RotateAxes,
      MPR2RotateViewSpace
    ]),
    contrastBrightnessMode: MprAppViewportMode.only([]),
    panMode: MprAppViewportMode.only([
      MPR2Pan2D
    ]),
    zoomMode: MprAppViewportMode.only([
      MPR2Zoom
    ]),
    scrollThroughVolumeMode: MprAppViewportMode.only([
      MPR2ThroughDepth
    ])
  };
});
var mprAppMainViewportModes = {
  mainMode: MprAppViewportMode.except([]),
  panMode: MprAppViewportMode.except([]),
  zoomMode: MprAppViewportMode.except([]),
  contrastBrightnessMode: MprAppViewportMode.except([]),
  scrollThroughVolumeMode: MprAppViewportMode.except([])
};

// studio/src/products/mpr/tools/MprScreenshot.ts
import {WebGLRenderTarget, WebGLRenderer as WebGLRenderer2} from "three";
var DEBUG = false;

class Screenshot extends ViewTool {
  renderer;
  constructor(view) {
    super(view);
    this.renderer = new WebGLRenderer2({ preserveDrawingBuffer: true });
    this.makeScreenshot = this.makeScreenshot.bind(this);
  }
  activate() {
    App2.Instance().addEventListener("makeScreenshot", this.makeScreenshot);
  }
  deactivate() {
    App2.Instance().removeEventListener("makeScreenshot", this.makeScreenshot);
  }
  makeScreenshot(event) {
    const canvasWidth = this.hostObject.getRenderer().domElement.width;
    const canvasHeight = this.hostObject.getRenderer().domElement.height;
    const target = new WebGLRenderTarget(canvasWidth, canvasHeight);
    const renderer = this.hostObject.getRenderer();
    renderer.setRenderTarget(target);
    const canvas2d = document.createElement("canvas");
    const context2d = canvas2d.getContext("2d");
    this.hostObject.viewports.forEach((viewport, i) => {
      renderer.setViewport(viewport.size);
      renderer.setScissor(viewport.size);
      renderer.setScissorTest(true);
      viewport.camera.scale.y = -1;
      renderer.render(viewport.view.scene, viewport.camera);
      viewport.camera.scale.y = 1;
      const width = Math.round(viewport.size.z);
      const height = Math.round(viewport.size.w);
      const x0 = Math.round(viewport.size.x);
      const y0 = Math.round(viewport.size.y);
      const buffer = new Uint8ClampedArray(width * height * 4);
      renderer.readRenderTargetPixels(target, x0, y0, width, height, buffer);
      let imageData = new ImageData(buffer, width, height);
      canvas2d.width = width;
      canvas2d.height = height;
      context2d.putImageData(imageData, 0, 0);
      if (event) {
        canvas2d.toBlob((blob) => {
          if (blob) {
            event.onBlob(viewport.name, blob);
          }
        });
      }
      if (DEBUG) {
        const image = canvas2d.toDataURL();
        const img = document.createElement("img");
        img.width = width / 2;
        img.height = height / 2;
        img.src = image;
        document.body.appendChild(img);
      }
    });
    renderer.setRenderTarget(null);
  }
}

// studio/src/core/tools/ActiveViewportSwitcher.ts
class ActiveViewSwitcher extends ViewTool {
  event;
  constructor(view) {
    super(view);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onMouseOverWhileTransform = this.onMouseOverWhileTransform.bind(this);
  }
  activate() {
    this.hostObject.viewports.forEach((viewport) => {
      App2.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App2.Instance().mouseController.addListener("pointermove", this.onMouseMove, viewport.getDomElement());
    });
    App2.Instance().addEventListener("transformStart", this.onTransformStart);
  }
  deactivate() {
    this.hostObject.viewports.forEach((viewport) => {
      App2.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App2.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
    });
    App2.Instance().removeEventListener("transformStart", this.onTransformStart);
    App2.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformStart() {
    this.event = undefined;
    this.hostObject.viewports.forEach((viewport) => {
      App2.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App2.Instance().mouseController.addListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App2.Instance().addEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformEnd() {
    this.hostObject.viewports.forEach((viewport) => {
      App2.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App2.Instance().mouseController.removeListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App2.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    if (this.event) {
      this.onMouseOver(this.event);
    }
  }
  onMouseOverWhileTransform(event) {
    this.event = event;
  }
  onMouseOver(event) {
    for (let [name, viewport] of this.hostObject.viewports) {
      if (viewport.isEnabled() && viewport.getDomElement() === event.target) {
        App2.Instance().setActiveView(this.hostObject);
        this.hostObject.setActiveViewport(name);
        return true;
      }
    }
    return false;
  }
  onMouseMove(event) {
    if (this.onMouseOver(event)) {
      this.hostObject.viewports.forEach((viewport) => {
        App2.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
      });
    }
  }
}

// studio/src/products/mpr/tools/MPR2ContrastBrightness.ts
class MPR2ContrastBrightness extends ViewTool {
  SENSITIVITY = 10;
  constructor(view) {
    super(view);
  }
  stopListenPointerDrag;
  async activate() {
    this.stopListenPointerDrag = listenPointerDrag(this.handlePointerDrag, this.hostObject.getContainer(), "left", this.predicate);
  }
  predicate = (event) => {
    return true;
  };
  deactivate() {
    this.stopListenPointerDrag?.();
  }
  handlePointerDrag = (event) => {
    const colorProfile = MPR2Storage_default.colorProfile;
    const deltaX = event.movementX;
    const deltaY = event.movementY;
    colorProfile.ww += deltaX * this.SENSITIVITY;
    colorProfile.wc += deltaY * this.SENSITIVITY;
    MPR2AppInstance.dispatchEvent({ type: "colorProfileChanged" });
  };
}

// studio/src/products/mpr/tools/ListenAndControlCrossLineVisibility.ts
class ListenAndControlCrossLineVisibility extends ViewTool {
  activate() {
    MPR2AppInstance.addEventListener("hideCrossLines", this.hideCrossLines);
    MPR2AppInstance.addEventListener("showCrossLines", this.showCrossLines);
  }
  hideCrossLines = () => {
    const crossLines = MPR2AppInstance.view.getLayer("mpr")?.getObjects().filter((obj) => obj instanceof CrossLine);
    crossLines.forEach((obj) => obj.getComponent(Visibility)?.hide());
    MPR2AppInstance.dispatchEvent({ type: "render" });
  };
  showCrossLines = () => {
    const crossLines = MPR2AppInstance.view.getLayer("mpr")?.getObjects().filter((obj) => obj instanceof CrossLine);
    crossLines.forEach((obj) => obj.getComponent(Visibility)?.show());
    MPR2AppInstance.dispatchEvent({ type: "render" });
  };
  deactivate() {
    MPR2AppInstance.removeEventListener("hideCrossLines", this.hideCrossLines);
    MPR2AppInstance.removeEventListener("showCrossLines", this.showCrossLines);
  }
}

// studio/src/products/mpr/tools/serviceTools/MPR2SyncVolumeSlicesToSpatialCore.ts
import {Vector3 as Vector319} from "three";

// studio/src/products/shared/waitForApplicationReady.ts
var internalResolve;
var promisifiedReady = new Promise((resolve) => {
  internalResolve = resolve;
});
var resolveWaitForApplicationReady = () => {
  internalResolve();
};
var waitForApplicationReady = () => promisifiedReady;

// studio/src/products/mpr/tools/serviceTools/MPR2SyncVolumeSlicesToSpatialCore.ts
class MPR2SyncVolumeSlicesToSpatialCore extends ViewTool {
  volumeSlices = [];
  stubVector3 = new Vector319;
  activate = async () => {
    await waitForApplicationReady();
    this.volumeSlices = VolumeSlice.findAllSlices();
    const spatialCore = SpatialCore;
    console.log("Mpr2SyncVolumeSlicesToSpatialCore.activate", this.volumeSlices);
    const bind = (slice, sliceTransform, viewSubTransform) => {
      this.on("syncWithSpatialCore", () => {
        sliceTransform.updateWorldMatrix(true, false);
        sliceTransform.matrixWorld.decompose(slice.object3d.position, slice.object3d.quaternion, this.stubVector3);
        viewSubTransform.updateWorldMatrix(true, false);
        viewSubTransform.matrixWorld.decompose(slice.linkedCamera.position, slice.linkedCamera.quaternion, slice.linkedCamera.scale);
        slice.update();
        App2.Instance().dispatchEvent({ type: "render" });
      });
    };
    this.volumeSlices.forEach((slice) => {
      if (slice.tag === "Axial") {
        bind(slice, spatialCore.sliceCoreTransform.axialTransform, spatialCore.viewCoreTransform.axialTransform);
      }
      if (slice.tag === "Sagittal") {
        bind(slice, spatialCore.sliceCoreTransform.sagittalTransform, spatialCore.viewCoreTransform.sagittalTransform);
      }
      if (slice.tag === "Coronal") {
        bind(slice, spatialCore.sliceCoreTransform.coronalTransform, spatialCore.viewCoreTransform.coronalTransform);
      }
    });
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/tools/serviceTools/MPR2SyncVolumeSlicesWithColorProfile.ts
class MPR2SyncVolumeSlicesWithColorProfile extends ViewTool {
  slices = [];
  async activate() {
    await waitForApplicationReady();
    this.slices = VolumeSlice.findAllSlices();
    this.syncSlicesWithColorProfile();
    this.on("colorProfileChanged", this.syncSlicesWithColorProfile);
  }
  syncSlicesWithColorProfile = () => {
    this.slices.forEach((slice) => {
      slice.syncWWWC();
    });
    App2.Instance().dispatchEvent({ type: "render" });
  };
}

// studio/src/products/mpr/tools/MPR2Reset.ts
class MPR2Reset extends ViewTool {
  activate() {
    MPR2AppInstance.addEventListener("reset", this.reset);
  }
  deactivate() {
    MPR2AppInstance.removeEventListener("reset", this.reset);
  }
  reset = () => {
    MPR2Storage_default.resetColorProfileToInitial();
    MPR2AppInstance.dispatchEvent({ type: "colorProfileChanged" });
    const toothToFocusOn = MPR2Storage_default.toothToFocusOn;
    if (!toothToFocusOn) {
      console.log("[MPR2FocusOnTooth] Tooth not found");
      return;
    }
    const position = MPR2Storage_default.getToothPosition(toothToFocusOn.iso, toothToFocusOn.idx);
    if (!position) {
      console.log("[MPR2FocusOnTooth] Tooth not found");
      return;
    }
    console.log("[MPR2FocusOnTooth] Focus on tooth", toothToFocusOn, position);
    SpatialCore.focus(position);
    MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
  };
}

// studio/src/products/mpr/config/mprAppViewToolsAndModes.ts
var mprAppServiceTools = new Set([
  MPR2SyncVolumeSlicesWithColorProfile,
  MPR2Reset,
  MPR2SyncVolumeSlicesToSpatialCore
]);
var mprAppViewTools = tuple(ActiveViewSwitcher, MPR2ContrastBrightness, Screenshot, ListenAndControlCrossLineVisibility);

class MprAppViewMode extends Mode {
  static except = (tools) => new MprAppViewMode({ tools: { toolList: tools, include: false } });
  static only = (tools) => new MprAppViewMode({ tools: { toolList: tools, include: true } });
}
var mprAppViewModes = {
  mainMode: MprAppViewMode.except([MPR2ContrastBrightness]),
  contrastBrightnessMode: MprAppViewMode.only([ActiveViewSwitcher, MPR2ContrastBrightness, Screenshot]),
  panMode: MprAppViewMode.except([MPR2ContrastBrightness]),
  zoomMode: MprAppViewMode.except([MPR2ContrastBrightness]),
  scrollThroughVolumeMode: MprAppViewMode.except([MPR2ContrastBrightness])
};

// studio/src/products/mpr/utils/MPR2Layout.ts
class MPR2Layout {
  axial = document.createElement("div");
  sagittal = document.createElement("div");
  coronal = document.createElement("div");
  mainView = document.createElement("div");
  main = document.createElement("div");
  element = document.createElement("div");
  constructor() {
    this.element.dataset.id = "MPR2Layout";
    this.axial.dataset.id = "axial";
    this.sagittal.dataset.id = "sagittal";
    this.coronal.dataset.id = "coronal";
    this.mainView.dataset.id = "mainView";
    this.main.dataset.id = "main";
    this.element.style.position = "relative";
    this.element.style.width = "100%";
    this.element.style.height = "100%";
    this.mainView.style.position = "absolute";
    this.mainView.style.top = "0";
    this.mainView.style.left = "0";
    this.mainView.style.width = "100%";
    this.mainView.style.height = "100%";
    this.row3();
    this.mainView.appendChild(this.main);
    this.mainView.appendChild(this.axial);
    this.mainView.appendChild(this.sagittal);
    this.mainView.appendChild(this.coronal);
    this.element.appendChild(this.mainView);
  }
  row3 = () => {
    this.coronal.style.position = "absolute";
    this.coronal.style.top = "0";
    this.coronal.style.left = "0";
    this.coronal.style.width = "33.3%";
    this.coronal.style.height = "100%";
    this.sagittal.style.position = "absolute";
    this.sagittal.style.top = "0";
    this.sagittal.style.left = "33.3%";
    this.sagittal.style.width = "33.3%";
    this.sagittal.style.height = "100%";
    this.axial.style.position = "absolute";
    this.axial.style.top = "0";
    this.axial.style.left = "66.6%";
    this.axial.style.width = "33.3%";
    this.axial.style.height = "100%";
    this.main.style.position = "absolute";
    this.main.style.top = "0";
    this.main.style.left = "0";
    this.main.style.width = "0";
    this.main.style.height = "0";
  };
  twoByTwo = () => {
    this.axial.style.position = "absolute";
    this.axial.style.top = "0";
    this.axial.style.left = "0";
    this.axial.style.width = "50%";
    this.axial.style.height = "50%";
    this.sagittal.style.position = "absolute";
    this.sagittal.style.top = "0";
    this.sagittal.style.left = "50%";
    this.sagittal.style.width = "50%";
    this.sagittal.style.height = "50%";
    this.coronal.style.position = "absolute";
    this.coronal.style.top = "50%";
    this.coronal.style.left = "0";
    this.coronal.style.width = "50%";
    this.coronal.style.height = "50%";
    this.main.style.position = "absolute";
    this.main.style.top = "50%";
    this.main.style.left = "50%";
    this.main.style.width = "50%";
    this.main.style.height = "50%";
  };
}

// studio/src/products/shared/startupSystems/SetupCameras.ts
import {Vector3 as Vector320} from "three";
var SetupViewportCameras = () => {
  const axial = App2.Instance().view.getViewport("Axial");
  const sagittal = App2.Instance().view.getViewport("Sagittal");
  const coronal = App2.Instance().view.getViewport("Coronal");
  const pivot = new Vector320(0, 0, 0);
  if (axial) {
    axial.camera.lookAt(new Vector320(0, 0, -1));
    axial.camera.position.copy(pivot);
    axial.camera.zoom = 3;
    axial.camera.updateProjectionMatrix();
  }
  if (sagittal) {
    sagittal.camera.lookAt(new Vector320(-1, 0, 0));
    sagittal.camera.position.copy(pivot);
    sagittal.camera.rotateZ(Math.PI / 2);
    sagittal.camera.zoom = 3;
    sagittal.camera.updateProjectionMatrix();
  }
  if (coronal) {
    coronal.camera.position.copy(pivot);
    coronal.camera.lookAt(new Vector320(0, 1, 0));
    coronal.camera.zoom = 3;
    coronal.camera.updateProjectionMatrix();
  }
  if (axial)
    App2.Instance().dispatchEvent({ type: "transformCamera", viewport: axial });
  if (sagittal)
    App2.Instance().dispatchEvent({ type: "transformCamera", viewport: sagittal });
  if (coronal)
    App2.Instance().dispatchEvent({ type: "transformCamera", viewport: coronal });
};

// studio/src/products/mpr/config/mprStartupsConfig.ts
import {Vector3 as Vector321} from "three";

// studio/src/core/StartupSystem.ts
class StartupSystem {
  async start() {
  }
}

// studio/src/products/shared/startupSystems/FireApplicationReadyEvent.ts
class FireApplicationReadyEvent extends StartupSystem {
  async start() {
    App2.Instance().dispatchEvent({ type: "applicationReady" });
    resolveWaitForApplicationReady();
  }
}

// studio/src/products/mpr/startupSystems/MPR2FocusOnTooth.ts
var MPR2FocusOnTooth = () => {
  const toothToFocusOn = MPR2Storage_default.toothToFocusOn;
  if (!toothToFocusOn) {
    console.log("[MPR2FocusOnTooth] Tooth not found");
    return;
  }
  const position = MPR2Storage_default.getToothPosition(toothToFocusOn.iso, toothToFocusOn.idx);
  if (!position) {
    console.log("[MPR2FocusOnTooth] Tooth not found");
    return;
  }
  SpatialCore.focus(position);
  MPR2AppInstance.dispatchEvent({ type: "syncWithSpatialCore" });
};

// studio/src/products/mpr/startupSystems/MPR2ZoomToFitOnStartup.ts
var MPR2ZoomToFitOnStartup = async () => {
  await waitForApplicationReady();
  const volumeSlices = VolumeSlice.findAllSlices();
  if (volumeSlices.length === 0) {
    console.log("[MPR2ZoomToFitOnStartup] No volume slices found");
    return;
  }
  for (const volumeSlice of volumeSlices) {
    const camera = volumeSlice.linkedCamera;
    const viewHeight = 33;
    const cameraFrustumHeight = camera.top - camera.bottom;
    camera.zoom = cameraFrustumHeight / viewHeight;
    camera.updateProjectionMatrix();
  }
};

// studio/src/products/mpr/entities/MPR2SpatialCore/CameraBodyHelper.ts
import {BoxGeometry, ConeGeometry, CylinderGeometry, EdgesGeometry, LineBasicMaterial as LineBasicMaterial4, LineSegments as LineSegments3} from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";

class CameraBodyHelper extends LineSegments3 {
  constructor(color = "white") {
    super();
    const cone = new ConeGeometry(0.5, 1, 4);
    cone.rotateY(Math.PI / 4);
    cone.scale(1, 0.5, 1);
    cone.translate(0, 0.25, 0);
    const box = new BoxGeometry(1, 1, 1);
    box.translate(0, 1, 0);
    const cylinder = new CylinderGeometry(0.25, 0.25, 0.5, 8);
    const cylinder2 = new CylinderGeometry(0.25, 0.25, 0.5, 8);
    cylinder.rotateZ(Math.PI / 2);
    cylinder2.rotateZ(Math.PI / 2);
    cylinder.translate(0, 0.75, 0.75);
    cylinder2.translate(0, 1.25, 0.75);
    const cameraGeometry = new EdgesGeometry(BufferGeometryUtils.mergeBufferGeometries([cone, box, cylinder, cylinder2]));
    cameraGeometry.rotateX(-Math.PI / 2);
    cameraGeometry.scale(1, 1, -1);
    this.geometry = cameraGeometry;
    this.material = new LineBasicMaterial4({ color });
  }
}

// studio/src/products/mpr/startupSystems/MPR2AddCameraBodyHelperToVolumeSlice.ts
var MPR2AddCameraBodyHelperToVolumeSlice = async () => {
  await waitForApplicationReady();
  const bind = (slice) => {
    if (!slice) {
      return;
    }
    let color = "";
    if (slice.tag === "Axial") {
      color = "blue";
    }
    if (slice.tag === "Sagittal") {
      color = "red";
    }
    if (slice.tag === "Coronal") {
      color = "green";
    }
    const cameraBody = new CameraBodyHelper(color);
    cameraBody.scale.set(20, 20, 20);
    slice.linkedCamera.add(cameraBody);
    App2.Instance().view.scene.add(slice.linkedCamera);
  };
  const axial = VolumeSlice.findSlice("Axial");
  bind(axial);
  const sagittal = VolumeSlice.findSlice("Sagittal");
  bind(sagittal);
  const coronal = VolumeSlice.findSlice("Coronal");
  bind(coronal);
};

// studio/src/products/mpr/config/mprStartupsConfig.ts
var DEBUG_CAMERA_BODY = false;
var mprStartupSystems = [
  () => {
    App2.Instance().view.getLayer("mpr").add(SpatialCore);
  },
  SetupViewportCameras,
  () => {
    App2.Instance().view.getViewport("Axial").dispatchEvent({ type: "updatePivotPoint", point: new Vector321 });
    App2.Instance().view.getViewport("Sagittal").dispatchEvent({ type: "updatePivotPoint", point: new Vector321 });
    App2.Instance().view.getViewport("Coronal").dispatchEvent({ type: "updatePivotPoint", point: new Vector321 });
    VolumeSlice.findAllSlices().forEach((slice) => {
      slice.fitToCamera();
    });
    App2.Instance().view.viewports.forEach((viewport) => {
      viewport.init();
    });
  },
  MPR2FocusOnTooth,
  FireApplicationReadyEvent,
  MPR2ZoomToFitOnStartup,
  () => DEBUG_CAMERA_BODY ?? MPR2AddCameraBodyHelperToVolumeSlice()
];

// studio/src/core/CoreEvents.ts
var coreEventNames = [
  "transformCameraStart",
  "transformCameraEnd",
  "transformCamera",
  "WillReunload",
  "resize",
  "add",
  "delete",
  "addLayer",
  "deleteLayer",
  "ActiveViewportWillChange",
  "ActiveViewportChanged",
  "hover",
  "unhover",
  "select",
  "deselect",
  "render",
  "transform",
  "transformStart",
  "transformEnd",
  "resourceAdded",
  "contextMenuOpen",
  "contextMenuClose",
  "modeChanged",
  "visibility",
  "viewportSchemeChanged",
  "geometryChanged",
  "materialChanged",
  "setRenderingStyle",
  "fatal",
  "makeScreenshot",
  "applicationProgress",
  "applicationReady"
];

// studio/src/products/mpr/config/MprAppEvents.ts
var mprAppEventsNames = [
  ...coreEventNames,
  "reset",
  "applicationReady",
  "hideCrossLines",
  "showCrossLines",
  "addLayer",
  "syncWithSpatialCore",
  "colorProfileChanged"
];

// studio/src/products/mpr/config/mprAppLayerNames.ts
var mprAppLayerNames = [
  "other",
  "mpr"
];

// studio/src/products/mpr/api/MPRLauncher.ts
class MPRLauncher {
  layout = new MPR2Layout;
  app = MPR2AppInstance;
  view;
  constructor() {
    this.view = this.app.view;
  }
  start(request) {
    console.log("MPR Launcher started");
    if (this.app.isInitialized) {
      console.log("[MPRLauncher] start is skipped because app is already initialized.                 Probably the react component was re-rendered if you are in dev mode");
      return;
    }
    if (request.debug?.includeMainViewport) {
      this.layout.twoByTwo();
    }
    this.app.setListeners(mprAppEventsNames);
    this.view.toolController.setTools(mprAppViewTools);
    this.view.toolController.setServiceTools(mprAppServiceTools);
    this.view.toolController.setModes(mprAppViewModes);
    mprAppLayerNames.forEach((name) => this.view.addLayer(name));
    const coronalViewport = this.view.addViewport("Coronal", this.layout.coronal);
    coronalViewport.toolController.setTools(mprAppViewportTools);
    coronalViewport.toolController.setServiceTools(mprAppViewportServiceTools);
    coronalViewport.toolController.setModes(mprViewportModes.Coronal);
    const sagittalViewport = this.view.addViewport("Sagittal", this.layout.sagittal);
    sagittalViewport.toolController.setTools(mprAppViewportTools);
    sagittalViewport.toolController.setServiceTools(mprAppViewportServiceTools);
    sagittalViewport.toolController.setModes(mprViewportModes.Sagittal);
    const axialViewport = this.view.addViewport("Axial", this.layout.axial);
    axialViewport.toolController.setTools(mprAppViewportTools);
    axialViewport.toolController.setServiceTools(mprAppViewportServiceTools);
    axialViewport.toolController.setModes(mprViewportModes.Axial);
    if (request.debug?.includeMainViewport) {
      const mainViewport = App2.Instance().view.addViewport("Main", this.layout.main, { cameraControllerProps: { up: [0, 0, 1] } });
      mainViewport.toolController.setTools(mprAppMainViewportTools);
      mainViewport.toolController.setModes(mprAppMainViewportModes);
      mainViewport.camera.updateMatrixWorld();
      mainViewport.clearColor = 985626;
      mainViewport.alpha = 0;
    }
    this.app.init({ container: this.layout.mainView, rendererProps: { preserveDrawingBuffer: true }, name: "MprApp" });
    this.view.viewports.forEach((viewport) => {
      viewport.init();
    });
    this.view.activateMode("mainMode");
    MPR2Storage_default.put(request.volumes, request.colorProfile, request.toothToFocusOn);
    Texture3DLoader_default.start();
    this.app.startupSystems.push(...mprStartupSystems);
    this.app.runStartups();
  }
}

// studio/src/products/mpr/api/MPR2Api.ts
class MPR2Api {
  launcher = new MPRLauncher;
  app = this.launcher.app;
  eventsToApiBindings = [];
  getElement = () => this.launcher.layout.element;
  applicationReady = slot();
  modeChanged = slot();
  activateMode = slot();
  getCurrentMode = slot();
  makeScreenshot = slot();
  hideCrossLines = slot();
  showCrossLines = slot();
  reset = slot();
  dispose = slot();
  disposeManager;
  constructor() {
    const start = (request) => {
      this.launcher.start(request);
      this.bindEventsToAPI();
    };
    const dispose = () => {
      this.app.dispose();
      this.eventsToApiBindings.forEach((unsubscribe) => unsubscribe());
    };
    this.disposeManager = new DisposeScheduler(start, dispose);
  }
  start(request) {
    this.disposeManager.askForStart(request);
  }
  bindEventsToAPI() {
    this.eventsToApiBindings = [
      this.app.addEventListener("applicationReady", () => {
        this.applicationReady();
      }),
      this.app.addEventListener("modeChanged", (e) => {
        this.modeChanged({ previous: e.previous, mode: e.mode });
      }),
      this.activateMode.on((mode) => {
        this.app.view.activateMode(mode);
        this.app.dispatchEvent({ type: "render" });
      }),
      this.getCurrentMode.on(() => {
        return this.app.view.toolController.getModeName();
      }),
      this.makeScreenshot.on((onBlob) => {
        this.app.dispatchEvent({ type: "makeScreenshot", onBlob });
      }),
      this.hideCrossLines.on(() => {
        this.app.dispatchEvent({ type: "hideCrossLines" });
      }),
      this.showCrossLines.on(() => {
        this.app.dispatchEvent({ type: "showCrossLines" });
      }),
      this.reset.on(() => {
        this.app.dispatchEvent({ type: "reset" });
      }),
      this.dispose.on(() => {
        this.disposeManager.askForDispose();
      })
    ];
  }
}

class DisposeScheduler {
  start;
  dispose;
  DISPOSE_DELAY = 1e4;
  timeout = null;
  currentRequest = null;
  get initialized() {
    return this.currentRequest !== null;
  }
  constructor(start, dispose) {
    this.start = start;
    this.dispose = dispose;
  }
  askForDispose = () => {
    this.cancelDispose();
    this.timeout = window.setTimeout(() => {
      this.dispose();
    }, this.DISPOSE_DELAY);
  };
  askForStart = (request) => {
    if (!this.initialized) {
      this.currentRequest = request;
      this.start(request);
      return;
    }
    if (this.areRequestsEqual(this.currentRequest, request)) {
      console.log("[MPR2] Same request, cancelling dispose");
      this.cancelDispose();
      return;
    }
    this.dispose();
    this.start(request);
    this.currentRequest = request;
  };
  areRequestsEqual = (a, b) => {
    return a?.toothToFocusOn === b?.toothToFocusOn;
  };
  cancelDispose = () => {
    if (this.timeout)
      window.clearTimeout(this.timeout);
    this.timeout = null;
  };
}
export {
  MPR2Api
};
